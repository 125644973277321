export const environment = {
  production: true,
  config: {
    apiKey: "AIzaSyBbmc3_j2te9E4WlkqHYxDPI99sBR6UkzA",
    authDomain: "testixadinscription.firebaseapp.com",
    projectId: "testixadinscription",
    storageBucket: "testixadinscription.appspot.com",
    messagingSenderId: "541714395120",
    appId: "1:541714395120:web:d2284de947476bf3e67735"
  },
  setDateEtape1ForUserUrl: "https://test.inscription.ixad.fr/TestIxadInscription/data/etape1/user/",
  setDateEtape2ForUserUrl: "https://test.inscription.ixad.fr/TestIxadInscription/data/etape2/user/",
  setDateEtape3ForUserUrl: "https://test.inscription.ixad.fr/TestIxadInscription/data/etape3/user/",
  setDateEtape4ForUserUrl: "https://test.inscription.ixad.fr/TestIxadInscription/data/etape4/user/",
  setDateEtape5ForUserUrl: "https://test.inscription.ixad.fr/TestIxadInscription/data/etape5/user/",
  setDateEtape6ForUserUrl: "https://test.inscription.ixad.fr/TestIxadInscription/data/etape6/user/",
  setFinaliseInscription:  "https://test.inscription.ixad.fr/TestIxadInscription/data/finaliseinscription/user/",
  getDateEtape1ForUserUrl: "https://test.inscription.ixad.fr/TestIxadInscription/data/etape1/user/",
  getDateEtape2ForUserUrl: "https://test.inscription.ixad.fr/TestIxadInscription/data/etape2/user/",
  getDateEtape3ForUserUrl: "https://test.inscription.ixad.fr/TestIxadInscription/data/etape3/user/",
  getDateEtape4ForUserUrl: "https://test.inscription.ixad.fr/TestIxadInscription/data/etape4/user/",
  getDateEtape5ForUserUrl: "https://test.inscription.ixad.fr/TestIxadInscription/data/etape5/user/",
  getDateEtape6ForUserUrl: "https://test.inscription.ixad.fr/TestIxadInscription/data/etape6/user/",
  getIsDocteurDroitUrl: "https://test.inscription.ixad.fr/TestIxadInscription/data/docteurDroit/user/",
  getIsInscritUrl: "https://test.inscription.ixad.fr/TestIxadInscription/data/checkInscription/user/",
  getIsPreInscritUrl: "https://test.inscription.ixad.fr/TestIxadInscription/data/checkPreInscription/user/",
  getActiviteExtUrl: "https://test.inscription.ixad.fr/TestIxadInscription/data/checkActiviteExt/user/",
  getPreInscriptionValideIxad: "https://test.inscription.ixad.fr/TestIxadInscription/data/checkUserPreInscriptionValideIxad/user/",

  getLoadParamUrl: "https://test.inscription.ixad.fr/TestIxadInscription/public/data/params/",

  getReferentielAnneeUrl: "https://test.inscription.ixad.fr/TestIxadInscription/data/referentiel/annees/",
  getReferentielBacUrl: "https://test.inscription.ixad.fr/TestIxadInscription/data/referentiel/bac/",
  getReferentielLicenceUrl: "https://test.inscription.ixad.fr/TestIxadInscription/data/referentiel/licence/",
  getReferentielMasterUrl: "https://test.inscription.ixad.fr/TestIxadInscription/data/referentiel/master/",
  getReferentielPaysUrl: "https://test.inscription.ixad.fr/TestIxadInscription/data/referentiel/pays/",
  getReferentielVillesUrl: "https://test.inscription.ixad.fr/TestIxadInscription/data/referentiel/villes/",

  createPayRequest:"https://test.inscription.ixad.fr/TestIxadInscription/data/paiement/createPaiement",
  getCheckPaiemURL:"https://test.inscription.ixad.fr/TestIxadInscription/data/inscription/paiement/check/user/"
  

};
