import { Serializable } from '../serializable';

export class Etape2 extends Serializable {

    
constructor()
{
    super();
    this.idInscription = null;
    this.q1souhait = "";
    this.q1choixEda1 = "";
    this.q1autre1 = "";
    this.q1motif2 = "";
    this.q1autre2 = "";

    this.q2admission = "";
    this.q2autre1 = "";
    this.q2docteurDroitPrecision = "";

    this.q3prepaPrive = "";
    this.q3prepaAnnuellePresentielle = "";
    this.q3prepaAnnuellePresentielleOrganisme = "";
    this.q3prepaAnnuellePresentielleLieu = "";
    this.q3prepaAnnuelleDistance = "";
    this.q3prepaAnnuelleDistanceOrganisme = "";
    this.q3prepaAnnuelleDistanceLieu = "";
    this.q3prepaAnnuelleAutre = "";
    this.q3prepaAnnuelleAutreOrganisme = "";
    this.q3prepaAnnuelleAutreLieu = "";

    this.q4InscriptionIxad = "";
    this.q4premiereInscriptionPrecision = "";


    this.q5inscriptionAutreEtablissement = "";
    this.q5diplomePrecision = "";
    this.q5inscriptionAutreEtablissementAutrePrecision = "";

    this.q5secondChoix = "";
    this.q5secondChoixAutrePrecision = "";
    
    
    this.q6activiteExterieur = "";
    this.q6cocheEngagement = "";
    this.q6nomFichier = "";
    this.q6fichier = "";//TODO

   this.q7langueEtrangereChoisie = "";
   this.q11AnglaisInterrogation = "";
   this.q12master2 = "";
   this.q13Ordinateur = "";
   this.q14IA = "";

    this.q8inscriptionExamenCapa = "";

    this.q8inscriptionExamenCapaPrecision = "";

    this.q9dateThese = "";
    this.q10dateExamen = "";
    this.uid = "";
}

    
idInscription:Number;
q1souhait:string;
q1choixEda1:string;
q1autre1:string;
q1motif2:string;
q1autre2:string;

q2admission:string;
q2autre1:string;
q2docteurDroitPrecision:String;

q3prepaPrive:string;
q3prepaAnnuellePresentielle:string;
q3prepaAnnuellePresentielleOrganisme:string;
q3prepaAnnuellePresentielleLieu:string;
q3prepaAnnuelleDistance:string;
q3prepaAnnuelleDistanceOrganisme:string;
q3prepaAnnuelleDistanceLieu:string;
q3prepaAnnuelleAutre:string;
q3prepaAnnuelleAutreOrganisme:string;
q3prepaAnnuelleAutreLieu:string;

q4InscriptionIxad:string;
q4premiereInscriptionPrecision:string;


q5inscriptionAutreEtablissement:string;
q5diplomePrecision:string;
q5inscriptionAutreEtablissementAutrePrecision:string;
q5secondChoix:string;
q5secondChoixAutrePrecision:string;

q6activiteExterieur:string;
q6cocheEngagement:string;
q6nomFichier:string;
q6fichier; //TODO

q7langueEtrangereChoisie;
q11AnglaisInterrogation;
q12master2;
q13Ordinateur;
q14IA


q8inscriptionExamenCapa:string;
q8inscriptionExamenCapaPrecision:string;

q9dateThese:any;
q10dateExamen:any;
uid:string;


}
