<p style="color:white; padding:20px">
    Certaines de vos données à caractère personnel pourront être transmises au 
Conseil national des barreaux (CNB), pour traitement. <br/>
En particulier, le CNB, responsable de traitement, met en œuvre trois traitements 
différents sur les données vous concernant : <br/>
1. Un traitement portant sur les résultats d’admission au CAPA de l’ensemble 
des élèves avocats, au niveau national en vue d’établir un rapport sur l’insertion 
professionnelle des bénéficiaires de la formation initiale à destination du garde 
des Sceaux, conformément au décret n° 2017-1017 du 10 mai 2017 et à l’arrêté 
du 10 mai 2017.<br/>
Seules les données nécessaires sont traitées par le CNB, c’est pourquoi ce 
traitement porte exclusivement sur les informations suivantes : la mention de 
votre réussite ou de votre échec à l’examen du CAPA, l’année de votre passage 
et vos données d’identité (nom, prénom, date et lieu de naissance).
Ces données sont traitées par le CNB par application de ses obligations légales et 
lui sont directement communiquées par votre école après le passage de votre 
examen.<br/>
Les données des résultats d’admission collectées aux fins d’établir le rapport sur 
l’insertion professionnelle sont conservées un an. Leur version agrégée, rendant 
impossible la réidentification des répondants, est conservée pendant 10 ans en 
archive courante.<br/><br/>
2. Un traitement portant sur les tentatives infructueuses de présentation de 
l’examen du CAPA, exclusivement en vue de lutter contre la fraude à la 
présentation à cet examen par les personnes y ayant échoué plus d’une fois, 
conformément à l’article 71 du décret no 91-1197 du 27 novembre 1991.
Seules les données nécessaires sont traitées par le CNB, c’est pourquoi ce 
traitement porte uniquement sur les données concernant les élèves avocats 
ayant échoué à l’examen et comprend les informations suivantes : année de 
passage, mention de l’échec à l’examen du CAPA, données d’identité (nom, 
prénom, date et lieu de naissance) et adresse de messagerie électronique.
Nous vous précisons que les données vous concernant ne seront traitées suivant 
cette finalité que dans l’hypothèse où vous échoueriez à l’examen du CAPA. Dans 
cette hypothèse, vous serez informé(e) par le CNB, en qualité de responsable de 
traitement, de l’insertion de vos données à caractère personnel dans ce fichier 
national.<br/>
Ce fichier sera transmis, chaque année, au personnel habilité des écoles 
d’avocats, destinataires, avant le démarrage des inscriptions de la promotion 
suivante.<br/>
Ces données sont traitées par le CNB par application de ses obligations légales et 
lui sont directement communiquées par votre école après le passage de votre 
examen. <br/>
Les données collectées dans le cadre du fichier national des personnes n’ayant 
plus le droit de se représenter au CAPA seront conservées pendant une durée de 
60 ans, sauf admission future à l’examen du CAPA. L’insertion de vos données à 
caractère personnel dans ledit fichier national, après le deuxième échec en 
l’absence de délibération dûment motivée du conseil d’administration du CRFPA 
vous autorisant à accomplir un troisième cycle de formation ou après le troisième 
échec, assurera aux CRFPA l’information selon laquelle vous ne pouvez plus vous 
représenter à nouveau au CAPA auprès de quelque CRFPA que ce soit. 
En cas de réussite après un première tentative échouée ou une deuxième 
tentative ayant donné lieu à autorisation du passage d’un troisième cycle, les 
informations vous concernant seront supprimées du fichier dès réception de la 
notification de vos résultats par le CNB. <br/><br/>
3. Un traitement réalisé aux fins de gestion des actions de communication 
du CNB visant à vous adresser des lettres d’information portant sur les actions 
mises en œuvre pour la profession d’avocat par le CNB dans le cadre de ses 
missions de représentation de la profession.<br/>
Seules les données nécessaires sont traitées par le CNB, c’est pourquoi ce 
traitement porte uniquement sur votre adresse email, votre année de promotion 
et vos données d’identité (nom, prénom).<br/>
Ce traitement est mis en œuvre sur la base de l’intérêt légitime du Conseil 
national des barreaux à faire connaître ses actions et actualités aux futurs 
entrants dans la profession. Vous êtes libres de vous désinscrire des 
communications en cliquant figurant sur le lien figurant dans lesdites 
communications.<br/>
L’ensemble de ces données sont destinées au personnel habilité du CNB ainsi 
que, le cas échéant, à ses sous-traitants ou prestataires.<br/>
En application des articles 15 à 21 du RGPD et dans les conditions précisées par 
ceux-ci, vous disposez d’un droit d’opposition et de limitation du traitement ainsi 
qu’un droit d’accès, de rectification, d’effacement et de portabilité de vos 
données.<br/>
En application de l’article 85 de la loi Informatique et Libertés, vous disposez 
également d’un droit de formuler des directives spécifiques et générales 
concernant la conservation, l’effacement et la communication de vos données 
post-mortem. En ce qui concerne les directives générales, elles devront être 
adressées à un tiers de confiance numérique certifié par la Commission nationale 
informatique et libertés.<br/>
Ces droits s’exerceront par courrier accompagné d’une copie d’un titre d’identité 
signée, à l'adresse postale suivante : Conseil national des barreaux, Délégué(e) à 
la protection des données, 180 boulevard Haussmann, 75008 Paris ou par 
courriel à l’adresse : donneespersonnelles@cnb.avocat.fr. Vous disposerez 
également d’un droit de réclamation auprès de l’autorité nationale de protection 
des données.<br/>
    </p>

