import { Serializable } from '../serializable';

export class Etape1 extends Serializable {


    constructor()
    {

        super();

        this.idInscription = null;
        this.civilite = "";
        this.nom = "";
        this.prenom = "";
        this.nomUsage = null;
        this.sexe = "";
        this.dateNaissance = "";
        this.lieuNaissance = "";
        this.departementNaissance = "";
        this.adresse = "";
        this.cpVille = "";
        this.pays = "";
        this.telephone = "";
        this.telephonePays = "";
        this.telephoneFormatted = "";
        this.telephoneValide = null;
        this.mail = "";
        this.situation = "";
        this.autorisationCommunicationMail = null;
        this.autorisationDroitsImage = true;
        this.cocheCnb = false;
        this.uid = "";


    }

    idInscription:Number;
    civilite:string;
    nom:string;
    prenom:string;
    nomUsage:string;
    sexe:string;
    dateNaissance:any;
    lieuNaissance:string;
    departementNaissance:string;
    adresse:string;
    cpVille:string;
    pays:string;
    telephone:string;
    telephonePays:string;
    telephoneFormatted:string;
    telephoneValide:Boolean;
    mail:string;

    situation:string;
    autorisationCommunicationMail:Boolean;
    autorisationDroitsImage:Boolean;
    cocheCnb:Boolean;
    uid:string;


 

}
