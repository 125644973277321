import { Injectable, NgZone } from '@angular/core';
import { Router } from  "@angular/router";
import { AngularFireAuth } from  "@angular/fire/auth";
import {User} from '../../model/user';
import { ParametreService } from '../parametre.service';
import { Formulaire } from 'src/app/model/formulaire';
import * as firebase from 'firebase/app';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  userData: User;
  anneeInscription1 = null;
  anneeInscription2 = null;
  params = [];


  constructor(public parametreService:ParametreService, public  afAuth:  AngularFireAuth, public  router:  Router,  public ngZone: NgZone) {

    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.userData = this.setUserData(user);
        localStorage.setItem('user', JSON.stringify(this.userData));
        //JSON.parse(localStorage.getItem('user'));
      } else {
        localStorage.setItem('user', null);
        //JSON.parse(localStorage.getItem('user'));
        this.router.navigate(['login']);
      }
    });

    this.parametreService.loadParams().subscribe((result:any[]) => {
      this.params = result;
      this.anneeInscription1 = parseInt(this.params[4].valeurParametre);
      this.anneeInscription2 = parseInt(this.params[4].valeurParametre) + 1;
   //   this.autoriseConnexion = this.params[1].valeurParametre;
   //   this.autoriseCreationCompte = this.params[0].valeurParametre;
      
    });


   }

   public getParameterPublic(){
     return this.parametreService.loadParams();
   }

async login(email: string, password: string) {

  return this.afAuth.signInWithEmailAndPassword(email, password)
  .then((result) => {
   
    this.userData = this.setUserData(result.user);

    this.ngZone.run(() => {
    
     this.router.navigate(['formulaire/etape1']);

    });

   
    return "OK";
    
  }).catch((error) => {
 
    return error;
  })
}


async register(email: string, password: string) {
  return this.afAuth.createUserWithEmailAndPassword(email, password)
      .then((result) => {
        this.sendEmailVerification();
        return "OK";
      }).catch((error) => {
      
        return error;
      })
 
}
async sendEmailVerification() {
  return this.afAuth.currentUser.then(u => u.sendEmailVerification())
    .then(() => {
      this.router.navigate(['verify-email']);
    }).catch((error) => {
      
    })
}


async sendPasswordResetEmail(passwordResetEmail: string) {
  return this.afAuth.sendPasswordResetEmail(passwordResetEmail)
  .then(() => {


    return "OK";
  }).catch((error) => {
    return error;
  })
}

async logout(){
  return this.afAuth.signOut().then(() => {
    localStorage.removeItem('user');
    this.router.navigate(['login']);
  })
}

get isLoggedIn(): boolean {
  const  user  =  JSON.parse(localStorage.getItem('user'));
  return (user !== null && user.emailVerified !== false) ? true : false;
}

get uid(): string {
  const  user  =  JSON.parse(localStorage.getItem('user'));
  if(user != null)
  {
    return user.uid;
  }
  else{
    return "";
  }
  
}

setUserData(user: User) {

  const userData: User = {
    uid: user.uid,
    email: user.email,
    displayName: user.displayName,
    emailVerified: user.emailVerified
  }

  if (userData) {
    this.userData = userData;
    localStorage.setItem('user', null);
    localStorage.setItem('user', JSON.stringify(this.userData));
    JSON.parse(localStorage.getItem('user'));
  }
  
  return userData;
}

   
}
