import { Serializable } from '../serializable';

export class Etape4 extends Serializable  {

    constructor()
    {
        super();
        this.idInscription = null;
        this.uid = "";
        this.nomFichierDocPieceIdentite="";
        this.nomFichierDiplomeMaster1Maitrise="";
        this.nomFichierAttestationSoutenanceEcoleDoctorat="";
        this.nomFichierDiplomeDocteurDroit="";
        this.nomFichierJustificatifActiviteExt="";
        this.nomFichierThese="";
        this.nomFichierDiplomeMaster2Maitrise = "";
        this.nomFichierNationalite = "";
        this.nomFichierEngagementActiviteExt = "";
        this.nomFichierAutorisationCumulActiviteExt = "";
       

    }
    idInscription:Number;
    nomFichierDocPieceIdentite:string;
    nomFichierDiplomeMaster1Maitrise:string;
    nomFichierAttestationSoutenanceEcoleDoctorat:string;
    nomFichierDiplomeDocteurDroit:string;
    nomFichierThese:string;
    nomFichierJustificatifActiviteExt:string;
    nomFichierDiplomeMaster2Maitrise:string;
    nomFichierNationalite:string;
    nomFichierEngagementActiviteExt:string;
    nomFichierAutorisationCumulActiviteExt:string;

    uid:string;

}
