<div class="container contact">
    <div class="row">
      <div class="col-md-3 customMD3">
        <div class="contact-info">
          <a routerLink="/login"><img src="/assets/img/logo-ixad.png" class="img-fluid" alt="image"/></a>
          <h2 style="color:white" class="anneeStyle">INSCRIPTION {{ authService.anneeInscription1 }} / {{ authService.anneeInscription2 }}</h2>
          <br/><br/><br/><i style="color:white">Pour toute question ou problème technique lors du remplissage du formulaire, merci d'envoyer un mail à <a href="mailto:inscription@ixad.fr">inscription@ixad.fr</a></i>
          <h4></h4>
        </div>
      </div>
      <div class="col-md-9">
        
        <h4>Création d'un compte</h4><br/>
        <p>Saisissez les informations ci-dessous pour vous créer un compte</p><br/>
        
        <div class="contact-form">


          <div class="form-row ">
            <div class="form-group col-md-6 ">
              <label for="inputEmail4">Email : </label>
              <input type="text" class="form-control" [(ngModel)]="email1User" placeholder="Email">
            </div>
          </div>
          <div class="form-row ">
            <div class="form-group col-md-6 ">
              <label for="inputEmail4">Confirmation email : </label>
              <input type="text" class="form-control" [(ngModel)]="email2User" placeholder="Email">
            </div>
          </div>
          
          <div class="form-row">
  
            <div class="form-group col-md-6">
              <label for="inputPassword4">Password :</label>
              <input type="password" class="form-control" [(ngModel)]="passwordUser" placeholder="password">
            </div>
           
           
          </div>

          <div class="form-row">
  
            <div class="form-group col-md-6">
              <b><i><span style="color:red">{{errorMessage}}</span></i></b>
            </div>
           
           
          </div>
        
         
          <br/>
          <div class="form-row">
            <div class="form-group col-md-10 text-center">
              <button type="button" class="btn btn-primary" (click)="register()">Valider</button>
            </div>
           
            
          </div>
          
          
        </div>
      </div>
    </div>
  </div>