import { Component, OnInit } from '@angular/core';
import { FormulaireService } from 'src/app/services/formulaire/formulaire.service';
import { ReferentielService } from 'src/app/services/referentiel.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router } from '@angular/router';
import { Formulaire } from 'src/app/model/formulaire';
import { Etape3 } from 'src/app/model/formulaire/etape3';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Retour } from 'src/app/model/retour';
import { Etape2 } from 'src/app/model/formulaire/etape2';

@Component({
  selector: 'app-etape3',
  templateUrl: './etape3.component.html',
  styleUrls: ['./etape3.component.css']
})
export class Etape3Component implements OnInit {

  public isPreInscrit:boolean = false;

  public loadedService1 = false;


  public loading2 = false;

  public etape3 : Etape3 = new Etape3();
  public etape2 : Etape2 = new Etape2();

  public etape3form:FormGroup;

  public hasPreInscriptionValide:boolean=false;

  public isDocteurDroit:boolean = false;

  public isInscrit:boolean = false;

  getYears:any;
  constructor(fb: FormBuilder, public referentielService:ReferentielService, public formulaireService: FormulaireService, public authService: AuthService, public router: Router) {

    this.etape3form = fb.group({
      
      'q1BacSerie': [null, Validators.required],
      'q1BacAnnee': [null, Validators.required],
      'q1BacAutre': [null, Validators.required],

      'q2Annee3LicenceSpecialisation': [null, Validators.required],
      'q2Annee3LicenceAnnee': [null, Validators.required], 
      'q2Annee3LicenceAutre': [null, Validators.required],

      'q3master1specialisation': [null, Validators.required],
      'q3master1Annee': [null, Validators.required],
      'q3master1Autre': [null, Validators.required],

      'q4master2specialisation': [],
      'q4master2Annee': [],
      'q4master2Autre': [],

      'q5autreDiplome': [],
      'q5autreAnnee': [],
      'q5autreSpecialisation': []

    });


   }

  ngOnInit(): void {

    this.loadedService1 = false;


    if(this.authService.isLoggedIn) {
          const user  =  JSON.parse(localStorage.getItem('user'));
          this.formulaireService.defineInscriptionValideIxad(user.uid).subscribe((data :any)=>{
              this.hasPreInscriptionValide = data.hasPreInscriptionValideIxad;  
              if(this.hasPreInscriptionValide)
                this.router.navigate(['formulaire/etape6']);
              else
                this.loadDataEtape2();

           
          },
          error => {
          });
    }
    

  }

  public loadDataEtape2()
  {
    const user  =  JSON.parse(localStorage.getItem('user'));
    this.formulaireService.loadEtape2(user.uid).subscribe((data:any)=>{
      
      if(data.etape2 != null)
      {
        Formulaire.convertJsonToObject(this.formulaireService.formulaire ,data);
        this.etape2 = Object.assign(this.etape2, this.formulaireService.formulaire.etape2);
        if(this.etape2.q1souhait == 'option3' ||  this.etape2.q2admission == 'Auditeur_libre_etranger'){
          this.router.navigate(['formulaire/etape2']);
        }
        else{
          this.defineIsPreInscrit(user);
        }
      }
      else{
          this.etape2.uid =  user.uid;
      }
    },
    error => {
    });
  }

  

  public defineIsPreInscrit(user){
    this.formulaireService.defineIsPreInscrit(user.uid).subscribe((data :any)=>{

      this.isPreInscrit = data.isPreInscrit;   
      if(this.isPreInscrit)
      {
        //public loadedService1 = true;

        this.isPreInscrit = true;
        this.loadDataEtape3();
        //this.router.navigate(['formulaire/prevalid']);
      }
      else{
        this.loadDataEtape3();
      }
    },
    error => {

    });
  }

  public loadDataEtape3()
  {
    const user  =  JSON.parse(localStorage.getItem('user'));
    this.formulaireService.loadEtape3(user.uid).subscribe((data :any)=>{
      if(data.etape3 != null)
      {
        Formulaire.convertJsonToObject(this.formulaireService.formulaire ,data);
        this.etape3 = Object.assign(this.etape3, this.formulaireService.formulaire.etape3);
      }
      else{
          this.etape3.uid =  user.uid;
      }

      this.loadedService1 = true;
    },
    error => {
    
    });

    this.formulaireService.defineIsDocteurDroit(user.uid).subscribe((data :any)=>{
      
      this.isDocteurDroit = data.isDocteurDroit;  
      if(this.isDocteurDroit || (this.etape3.q4master2Annee != "" || this.etape3.q4master2Autre  != "" || this.etape3.q4master2specialisation != ""))
      {
        this.etape3form.get('q4master2Annee').setValidators([Validators.required]);
        this.etape3form.get('q4master2Autre').setValidators([Validators.required]);
        this.etape3form.get('q4master2specialisation').setValidators([Validators.required]);

        this.etape3form.get('q4master2Annee').enable({ emitEvent: false });
        this.etape3form.get('q4master2Autre').enable({ emitEvent: false });
        this.etape3form.get('q4master2specialisation').enable({ emitEvent: false });

        if(this.etape3.q4master2specialisation != 'Autre')
        {
          this.etape3.q4master2Autre = "";
          this.etape3form.get('q4master2Autre').disable({ emitEvent: false });
        }
        else{
          this.etape3form.get('q4master2Autre').enable({ emitEvent: false });
        }
      }
      else{
        this.etape3form.get('q4master2Annee').clearValidators();
        this.etape3form.get('q4master2Autre').clearValidators();
        this.etape3form.get('q4master2specialisation').clearValidators();
        this.etape3form.get('q4master2Annee').updateValueAndValidity();
        this.etape3form.get('q4master2Autre').updateValueAndValidity();
        this.etape3form.get('q4master2specialisation').updateValueAndValidity();
      }

    },
    error => {
    
    });
  }

  markFormTouched(group: FormGroup | FormArray) {
    
    Object.keys(group.controls).forEach((key: string) => {
      const control = group.controls[key];
      if (control instanceof FormGroup || control instanceof FormArray) { 
        control.markAsTouched(); 
        this.markFormTouched(control); 
      }
      else { control.markAsTouched(); };
    });
  };

  public next(){
    
    this.loading2 = true;
    //this.rdgControlForm();
    //this.markFormTouched(this.etape3form);

    if(!this.isPreInscrit)
    {
     // if (this.etape3form.valid) {

        Object.assign(this.formulaireService.formulaire.etape3, this.etape3);
        this.formulaireService.setDateEtape3(this.formulaireService.formulaire.etape3).subscribe((result:any) => {
          if(result.retour)
          {
            this.loading2 = false;
            this.router.navigate(['formulaire/etape4']);
          }
          else{
            this.loading2 = false;
            alert("Une erreur s'est produite. Veuillez recommencer")
          }
        },
        err => console.log('HTTP Error', err)
        );
     // } else {
     //   this.loading2 = false;
     //   alert("Erreur dans le formulaire, vérifier les champs")
     // }
  }
  else
  {
    this.loading2 = false;
    this.router.navigate(['formulaire/etape4']);
  }

  }

  public previous(){
    Object.assign(this.formulaireService.formulaire.etape3, this.etape3);
  
    this.router.navigate(['formulaire/etape2']);
  
  }



  public rdgControlForm()
  {
    if(this.etape3.q1BacSerie != 'Autre')
    {
      this.etape3.q1BacAutre = "";
      this.etape3form.get('q1BacAutre').disable({ emitEvent: false });
    }
    else{
      this.etape3form.get('q1BacAutre').enable({ emitEvent: false });
    }

    if(this.etape3.q2Annee3LicenceSpecialisation != 'Autre')
    {
      this.etape3.q2Annee3LicenceAutre = "";
      this.etape3form.get('q2Annee3LicenceAutre').disable({ emitEvent: false });
    }
    else{
      this.etape3form.get('q2Annee3LicenceAutre').enable({ emitEvent: false });
    }

    if(this.etape3.q3master1specialisation != 'Autre')
    {
      this.etape3.q3master1Autre = "";
      this.etape3form.get('q3master1Autre').disable({ emitEvent: false });
    }
    else{
      this.etape3form.get('q3master1Autre').enable({ emitEvent: false });
    }

    if(this.isDocteurDroit || (this.etape3.q4master2Annee != "" || this.etape3.q4master2Autre  != "" || this.etape3.q4master2specialisation != ""))
    {
      this.etape3form.get('q4master2Annee').setValidators([Validators.required]);
      this.etape3form.get('q4master2Autre').setValidators([Validators.required]);
      this.etape3form.get('q4master2specialisation').setValidators([Validators.required]);

      this.etape3form.get('q4master2Annee').enable({ emitEvent: false });
      this.etape3form.get('q4master2Autre').enable({ emitEvent: false });
      this.etape3form.get('q4master2specialisation').enable({ emitEvent: false });

      if(this.etape3.q4master2specialisation != 'Autre')
      {
        this.etape3.q4master2Autre = "";
        this.etape3form.get('q4master2Autre').disable({ emitEvent: false });
      }
      else{
        this.etape3form.get('q4master2Autre').enable({ emitEvent: false });
      }
    }
    else{
      this.etape3form.get('q4master2Annee').clearValidators();
      this.etape3form.get('q4master2Autre').clearValidators();
      this.etape3form.get('q4master2specialisation').clearValidators();
      this.etape3form.get('q4master2Annee').updateValueAndValidity();
      this.etape3form.get('q4master2Autre').updateValueAndValidity();
      this.etape3form.get('q4master2specialisation').updateValueAndValidity();
    }

    if(this.etape3.q5autreAnnee !="" || this.etape3.q5autreDiplome!="" || this.etape3.q5autreSpecialisation  != "")
    {
      this.etape3form.get('q5autreDiplome').setValidators([Validators.required]);
      this.etape3form.get('q5autreAnnee').setValidators([Validators.required]);
      this.etape3form.get('q5autreSpecialisation').setValidators([Validators.required]);
      
      this.etape3form.get('q5autreDiplome').enable({ emitEvent: false });
      this.etape3form.get('q5autreAnnee').enable({ emitEvent: false });
      this.etape3form.get('q5autreSpecialisation').enable({ emitEvent: false });

    }
    else{

      this.etape3form.get('q5autreDiplome').clearValidators();
      this.etape3form.get('q5autreAnnee').clearValidators();
      this.etape3form.get('q5autreSpecialisation').clearValidators();
      this.etape3form.get('q5autreDiplome').updateValueAndValidity();
      this.etape3form.get('q5autreAnnee').updateValueAndValidity();
      this.etape3form.get('q5autreSpecialisation').updateValueAndValidity();
     
    }





  }

}
