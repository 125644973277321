import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  email1User : string;
  errorMessage:string;
  displayValidate:boolean = true;
  constructor(public authService:  AuthService) { }

  ngOnInit(): void {
  }

  confirm()
  {
    const firebaseErrors = {
      'auth/user-not-found': 'Utilisateur non trouvé',
      'auth/email-already-exists': 'Adresse email déjà enregistrée',
      'auth/invalid-email':'Adresse email invalide',
      'auth/invalid-email-verified':'Email non vérifié, vérifiez vos emails',
      'auth/invalid-password':'Password non valide',
      'auth/too-many-requests':'Trop de tentatives d\'authentification répétées. Veuillez attendre quelques minutes',
      'auth/wrong-password':'Password non valide',
      'OK':'Vous allez recevoir un email pour réinitialiser votre mot de passe. Pensez à vérifier votre boite mail et vos spams'
    }; 

    this.authService.sendPasswordResetEmail(this.email1User).then(result =>{
      
      if(result != "OK"){
        this.errorMessage = firebaseErrors[result.code];

      }
      else{
        this.displayValidate = false;
        this.errorMessage = firebaseErrors[result];
        
      }
    
    });
  }

}
