
<div >
    <div class="container contact">
      <div class="row">
        <div class="col-md-3 customMD3">
          <div class="contact-info">
            <span class="pi pi-sign-out" style="color:white"></span><a class="linkDisconnect" href="#" (click)="authService.logout()">Se déconnecter</a>
            <a routerLink="/formulaire/etape1"><img src="/assets/img/logo-ixad.png" class="img-fluid" alt="image"/></a>
            <h2 style="color:white" class="anneeStyle">INSCRIPTION {{ authService.anneeInscription1 }} / {{ authService.anneeInscription2 }}</h2>
            <br/><br/><br/><i style="color:white">Pour toute question ou problème technique lors du remplissage du formulaire, merci d'envoyer un mail à <a href="mailto:inscription@ixad.fr">inscription@ixad.fr</a></i>
            <h4></h4>
          </div>
        </div>
        <div class="col-md-9">
          <div *ngIf="isPreInscrit">
          <h4>Confirmation</h4><br/><br/><br/>
          <div class="text-center">
            <img src="/assets/img/check.png" style="width:15%" alt="image"/>
          </div><br/><br/><br/>
          <p class="text-center" *ngIf="loadedService1 && etape2 != null && etape2.q2admission == 'Auditeur_libre_etranger'"><b>Votre demande est envoyée. <br/>IXAD reviendra vers vous par e-mail.</b></p>
          <p class="text-center" *ngIf="loadedService1 && etape2 != null && etape2.q2admission != 'Auditeur_libre_etranger'"><b>Votre pré-inscription est envoyée. <br/>IXAD reviendra vers vous par e-mail.</b></p>
          <br/><br/><br/><br/>
        </div>
          <div class="form-row">

      
            <div class="form-group col-md-12 text-center" *ngIf="isPreInscrit">
              <input type="button" class="btn btn-primary" (click)="close_window();"   value="Terminer">
              
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>