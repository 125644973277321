
<div  [formGroup]="etape2form">
  <div class="container contact">
    <div class="row">
      <div class="col-md-3 customMD3">
        <div class="contact-info">
          <span class="pi pi-sign-out" style="color:white"></span><a class="linkDisconnect" href="#" (click)="authService.logout()">Se déconnecter</a>
          <a routerLink="/formulaire/etape1"><img src="/assets/img/logo-ixad.png" class="img-fluid" alt="image"/></a>
          <h2 style="color:white" class="anneeStyle">INSCRIPTION {{ authService.anneeInscription1 }} / {{ authService.anneeInscription2 }}</h2>
          <br/><br/><br/><i style="color:white">Pour toute question ou problème technique lors du remplissage du formulaire, merci d'envoyer un mail à <a href="mailto:inscription@ixad.fr">inscription@ixad.fr</a></i>
          <h4></h4>
        </div>
      </div>
      <div class="col-md-9" *ngIf="!loadedService1"><img src="/assets/img/loader-orange.gif" style="width:100px"/></div>
      <div class="col-md-9" *ngIf="loadedService1 && loadedService1">
        

        <h4 *ngIf="etape2 != null && (etape2.q1souhait == 'option3' || etape2.q2admission == 'Auditeur_libre_etranger')">Etape 2 sur 2 - informations complémentaires</h4>
        <h4 *ngIf="etape2 != null && (etape2.q1souhait != 'option3' && etape2.q2admission != 'Auditeur_libre_etranger')">Etape 2 sur 5 - informations complémentaires</h4>
        
        <br/>
        <!-- Question 1 -->
        <p>Répondez au questions ci-dessous.</p><br/>
        <div class="contact-form">
          <div class="form-row">
            <div class="form-group col-md-10">
              <label for="exampleFormControlSelect1">Votre souhait :</label><span style="color:red"> *</span><span class="text-danger" *ngIf="etape2form.controls['q1souhait'].hasError('required') && (etape2form.controls['q1souhait'].dirty || etape2form.controls['q1souhait'].touched)">&nbsp;* Champ obligatoire</span>
              <div class="form-check">
                <input class="form-check-input" type="radio" id="exampleRadios2" (change)="rdgControlForm()"  formControlName="q1souhait"  name="q1souhait" [(ngModel)]="etape2.q1souhait" value="option1">
                <label class="form-check-label" for="exampleRadios2">
                  Souhaite intégrer l’EDA Nord-Ouest, IXAD pour la prochaine rentrée
                </label>
              </div>
              <div class="form-check" style="margin-top:10px">
                <input class="form-check-input" type="radio" id="exampleRadios4" (change)="rdgControlForm()" formControlName="q1souhait" name="q1souhait" [(ngModel)]="etape2.q1souhait" value="option3">
                <label class="form-check-label" for="exampleRadios4">
                  Souhaite reporter son inscription à l’EDA Nord-Ouest
                </label>
              </div>
                <div class="form-row text-center" style="margin-top:10px" *ngIf="etape2.q1souhait== 'option3'">
               <!-- <label class="col-sm-2 col-form-label">Motif :</label> -->
                <input type="text" style="margin-bottom:5px;" class="form-control col-sm-10" id="inputEmail4" formControlName="q1motif2" name="q1motif2" [(ngModel)]="etape2.q1motif2" placeholder="Précisez la raison"  *ngIf="displayMotif2"> <span style="color:red;">&nbsp; *</span>
                <span class="text-danger" *ngIf="etape2.q1souhait == 'option3' && etape2form.controls['q1motif2'].hasError('required') && (etape2form.controls['q1motif2'].dirty || etape2form.controls['q1motif2'].touched)">* Champ obligatoire</span>  
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" id="exampleRadios3" (change)="rdgControlForm()" formControlName="q1souhait" name="q1souhait" [(ngModel)]="etape2.q1souhait"  value="option2">
                <label class="form-check-label" for="exampleRadios3">
                   Souhaite intégrer l’EDA Nord-Ouest, IXAD à titre provisoire <br/>(Vous êtes concerné si vous effectuez une inscription en parallèle dans un autre établissement)<br/>
                </label>
              </div>
              
              <br/><b>Statut de l'Élève Avocat</b><br/>
              <i>
              Attention, les Élèves inscrits à IXAD n'ont pas le statut étudiant mais le statut d'Élève Avocat, et ce, jusqu'à la publication des résultats d'admission du CAPA.
              <br/>Le statut de l'Élève Avocat est défini aux<a target="_blank"  href="https://www.legifrance.gouv.fr/loda/article_lc/LEGIARTI000006922729"> articles 62 et suivants du décret n°91-1197 du 27 novembre 1991.</a>
              <br/>Aucune disposition du code de l'éduction ne s'applique aux Élèves Avocats dans le cadre de leur formation. N'étant pas des étudiants, les Élèves Avocats ne sont pas assujettis à la CVEC (contribution de la vie étudiante et campus).
              <br/>La CAF ou les autres administrations n'assimilent pas forcément le statut d'Élève Avocat au statut étudiant. Les Élèves Avocats ne peuvent pas bénéficier du RSA, de la Prime d'activité ou des APL.
              <br/>Enfin, le CAPA n'est pas un diplôme universitaire, mais le certificat d'aptitude à exercer la profession d'avocat.</i>

            </div>
          </div>
          <!-- Question 5 -->
          <hr *ngIf="etape2.q1souhait == 'option2'"/>
          <!--<div class="form-row" *ngIf="etape2.q1souhait == 'option2'">
            <div class="form-group col-md-10">
              <label for="exampleFormControlSelect1">Etes-vous inscrit dans une autre EDA ?<span style="color:red"> *</span></label>
              <br/><div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" formControlName="q5inscriptionAutreEtablissement" name="q5inscriptionAutreEtablissement"  [(ngModel)]="etape2.q5inscriptionAutreEtablissement" value="Oui" (change)="rdgControlForm()">
                <label class="form-check-label" for="inlineRadio1">Oui</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" formControlName="q5inscriptionAutreEtablissement" name="q5inscriptionAutreEtablissement"  [(ngModel)]="etape2.q5inscriptionAutreEtablissement" value="Non" (change)="rdgControlForm()">
                <label class="form-check-label" for="inlineRadio2">Non</label>
              </div>
              <span class="text-danger" *ngIf="etape2form.controls['q5inscriptionAutreEtablissement'].hasError('required') && (etape2form.controls['q5inscriptionAutreEtablissement'].dirty || etape2form.controls['q5inscriptionAutreEtablissement'].touched)">&nbsp;&nbsp;* Champ obligatoire</span>
            </div>
          </div>-->
          <div class="form-row" *ngIf="etape2.q5inscriptionAutreEtablissement == 'Oui' && etape2.q1souhait == 'option2'">
            <label for="inputEmail4" class="col-sm-3 col-form-label">1er choix :<span style="color:red"> *</span> </label> 
            <select class="form-control col-sm-5" id="exampleFormControlSelect1" [(ngModel)]="etape2.q5diplomePrecision" formControlName="q5diplomePrecision" (change)="rdgControlForm()">
              <option value="">--- Choisir ---</option>
              <option value="EDA NORD OUEST (IXAD)">EDA NORD OUEST (IXAD)</option>
              <option value="EDA PARIS (EFB)">EDA PARIS (EFB)</option>
              <option value="EDA VERSAILLES (HEDAC)">EDA VERSAILLES (HEDAC)</option>
              <option value="EDA GRAND EST (ERAGE)">EDA GRAND EST (ERAGE)</option>
              <option value="EDA RHONE- ALPES (EDARA)">EDA RHONE- ALPES (EDARA)</option>
              <option value="EDA SUD-EST (CFBSE)">EDA SUD-EST (CFBSE)</option>
              <option value="EDA CENTRE SUD (EFACS)">EDA CENTRE SUD (EFACS)</option>
              <option value="EDA SUD-OUEST PYRENEES">EDA SUD-OUEST PYRENEES</option>
              <option value="EDA ALIENOR">EDA ALIENOR</option>
              <option value="EDA CENTRE OUEST (ECOA)">EDA CENTRE OUEST (ECOA)</option>
              <option value="EDA GRAND OUEST (EDAGO)">EDA GRAND OUEST (EDAGO)</option>
              <option value="autre">Autre </option> 
            </select>
           <!-- <input type="text" class="form-control col-sm-5" id="inputEmail4" placeholder="Diplôme..." formControlName="q5diplomePrecision" name="q5diplomePrecision"  [(ngModel)]="etape2.q5diplomePrecision" >-->
            <span class="text-danger" *ngIf="etape2.q5inscriptionAutreEtablissement == 'Oui' && (etape2form.controls['q5diplomePrecision'].hasError('required') && (etape2form.controls['q5diplomePrecision'].dirty || etape2form.controls['q5diplomePrecision'].touched))">&nbsp;&nbsp;* Champ obligatoire</span> 
            <br/><br/>
            <input *ngIf="etape2.q5diplomePrecision == 'autre'" type="text" style="margin-bottom:5px;" class="form-control col-sm-11" id="inputEmail4" formControlName="q5inscriptionAutreEtablissementAutrePrecision" name="q5inscriptionAutreEtablissementAutrePrecision" [(ngModel)]="etape2.q5inscriptionAutreEtablissementAutrePrecision" placeholder="Précisez" >
            <span class="text-danger" *ngIf="etape2.q5diplomePrecision == 'autre' && etape2.q5inscriptionAutreEtablissement == 'Oui' && (etape2form.controls['q5inscriptionAutreEtablissementAutrePrecision'].hasError('required') && (etape2form.controls['q5inscriptionAutreEtablissementAutrePrecision'].dirty || etape2form.controls['q5inscriptionAutreEtablissementAutrePrecision'].touched))">&nbsp;&nbsp;* Champ obligatoire</span> 
          </div>

          <div class="form-row" *ngIf="etape2.q5inscriptionAutreEtablissement == 'Oui' && etape2.q1souhait == 'option2'">
            <label for="inputEmail4" class="col-sm-3 col-form-label">2nd choix :<span style="color:red"> *</span> </label> 
            <select class="form-control col-sm-5" id="exampleFormControlSelect1" [(ngModel)]="etape2.q5secondChoix" formControlName="q5secondChoix" (change)="rdgControlForm()">
              <option value="">--- Choisir ---</option>
              <option value="EDA NORD OUEST (IXAD)">EDA NORD OUEST (IXAD)</option>
              <option value="EDA PARIS (EFB)">EDA PARIS (EFB)</option>
              <option value="EDA VERSAILLES (HEDAC)">EDA VERSAILLES (HEDAC)</option>
              <option value="EDA GRAND EST (ERAGE)">EDA GRAND EST (ERAGE)</option>
              <option value="EDA RHONE- ALPES (EDARA)">EDA RHONE- ALPES (EDARA)</option>
              <option value="EDA SUD-EST (CFBSE)">EDA SUD-EST (CFBSE)</option>
              <option value="EDA CENTRE SUD (EFACS)">EDA CENTRE SUD (EFACS)</option>
              <option value="EDA SUD-OUEST PYRENEES">EDA SUD-OUEST PYRENEES</option>
              <option value="EDA ALIENOR">EDA ALIENOR</option>
              <option value="EDA CENTRE OUEST (ECOA)">EDA CENTRE OUEST (ECOA)</option>
              <option value="EDA GRAND OUEST (EDAGO)">EDA GRAND OUEST (EDAGO)</option>
              <option value="pas_second_choix">Pas de second choix</option>
              <option value="autre">Autre </option> 
            </select>
           <!-- <input type="text" class="form-control col-sm-5" id="inputEmail4" placeholder="Diplôme..." formControlName="q5diplomePrecision" name="q5diplomePrecision"  [(ngModel)]="etape2.q5diplomePrecision" >-->
            <span class="text-danger" *ngIf="etape2.q5inscriptionAutreEtablissement == 'Oui' && (etape2form.controls['q5secondChoix'].hasError('required') && (etape2form.controls['q5secondChoix'].dirty || etape2form.controls['q5secondChoix'].touched))">&nbsp;&nbsp;* Champ obligatoire</span> 
            <br/><br/>
            <input *ngIf="etape2.q5secondChoix == 'autre'" type="text" style="margin-bottom:5px;" class="form-control col-sm-11" id="inputEmail4" formControlName="q5secondChoixAutrePrecision" name="q5secondChoixAutrePrecision" [(ngModel)]="etape2.q5secondChoixAutrePrecision" placeholder="Précisez" >
            <span class="text-danger" *ngIf="etape2.q5secondChoix == 'autre' && etape2.q5inscriptionAutreEtablissement == 'Oui' && (etape2form.controls['q5secondChoixAutrePrecision'].hasError('required') && (etape2form.controls['q5secondChoixAutrePrecision'].dirty || etape2form.controls['q5secondChoixAutrePrecision'].touched))">&nbsp;&nbsp;* Champ obligatoire</span> 
          </div>
          
          
           <!-- Question 2 -->
          <hr/>
          <div class="form-row">
            <div class="form-group col-md-10">
              <label for="exampleFormControlSelect1">Provenance de l'admission :</label><span style="color:red"> *</span><span class="text-danger" *ngIf="etape2form.controls['q2admission'].hasError('required') && (etape2form.controls['q2admission'].dirty || etape2form.controls['q2admission'].touched)">&nbsp;* Champ obligatoire</span>
              <div class="form-check">
                <input class="form-check-input" type="radio" formControlName="q2admission" name="q2admission" (change)="rdgControlForm()" [(ngModel)]="etape2.q2admission" value="Lille" (change)="rdgControlForm()">
                <label class="form-check-label" for="exampleRadios2">
                  IEJ Lille 
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" formControlName="q2admission"  name="q2admission" [(ngModel)]="etape2.q2admission"  value="Amiens-Picardie" (change)="rdgControlForm()">
                <label class="form-check-label" for="exampleRadios3">
                  IEJ Amiens-Picardie
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" formControlName="q2admission" name="q2admission" [(ngModel)]="etape2.q2admission"  value="Rouen" (change)="rdgControlForm()">
                <label class="form-check-label" for="exampleRadios4">
                  IEJ Rouen
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" formControlName="q2admission" name="q2admission" [(ngModel)]="etape2.q2admission"  value="Valenciennes" (change)="rdgControlForm()">
                <label class="form-check-label" for="exampleRadios5">
                  IEJ Valenciennes
                </label>
              </div>           
              <div class="form-check">
                <input class="form-check-input" type="radio" formControlName="q2admission" name="q2admission" [(ngModel)]="etape2.q2admission"  value="Docteur_Droit_Organise" (change)="rdgControlForm()">
                <label class="form-check-label" for="exampleRadios7">
                  En qualité de Docteur en Droit 
                </label>

              </div>
              <div class="form-row" *ngIf="etape2.q2admission == 'Docteur_Droit_Organise'">
                <label for="inputEmail4" class="col-sm-7 col-form-label " style="padding-left:15px">Organisé par l’Ecole Doctorale de :<span style="color:red"> *</span></label>
                <input  type="text" style="margin-bottom:5px;" class="form-control col-sm-5" id="inputEmail4" formControlName="q2docteurDroitPrecision" name="q2docteurDroitPrecision" [(ngModel)]="etape2.q2docteurDroitPrecision" placeholder="Précisez"  > 
                
              </div>
            
              
              <div class="form-check">
                <input class="form-check-input" type="radio" formControlName="q2admission" name="q2admission" [(ngModel)]="etape2.q2admission"  value="Auditeur_libre_etranger" (change)="rdgControlForm()">
                <label class="form-check-label" for="exampleRadios8 " >
                  En qualité d’auditeur libre étranger
                </label>
              </div>
              <div class="form-check" style="">
                <input class="form-check-input" style="top:3px" type="radio" formControlName="q2admission" name="q2admission" [(ngModel)]="etape2.q2admission"  value="Autre" (change)="rdgControlForm()">
                <label class="form-check-label" style="padding-top:3px" for="exampleRadios6">Autre </label>
                <div class="form-check-inline" style="margin-left:10px">
                  <select class="form-control" id="exampleFormControlSelect1"  name="etape2.q2autre1" formControlName="q2autre1"  [(ngModel)]="etape2.q2autre1" *ngIf="etape2.q2admission == 'Autre'">
                    <option value="">--- Précisez ---</option>
                    <option>Aix-Marseille</option>
                    <option>Amiens-Picardie</option>
                    <option>Angers</option>
                    <option>Avignon et des pays de Vaucluse</option>
                    <option>Besançon-Franche-Comté</option>
                    <option>Bordeaux</option>
                    <option>Brest-Bretagne occidentale</option>
                    <option>Caen</option>
                    <option>Cergy-Pontoise</option>
                    <option>Chambéry-Savoie Mont Blanc</option>
                    <option>Clermont-Ferrand-Auvergne</option>
                    <option>Corse</option>
                    <option>Dijon-Bourgogne</option>
                    <option>Evry-Val d'Essonne</option>
                    <option>Grenoble-Alpes</option>
                    <option>Guadeloupe-Antilles</option>
                    <option>La Réunion</option>
                    <option>La Rochelle</option>
                    <option>Le Mans</option>
                    <option>Lille</option>
                    <option>Limoges</option>
                    <option>Lyon III</option>
                    <option>Martinique-Antilles</option>
                    <option>Metz-Lorraine </option>
                    <option>Montpellier</option>
                    <option>Nancy-Lorraine</option>
                    <option>Nantes</option>
                    <option>Nice-Cote d'Azur</option>
                    <option>Orléans</option>
                    <option>Paris Cité</option>
                    <option>Paris 1 (+ Nouméa, Papeete)</option>
                    <option>Paris 10 (Nanterre)</option>
                    <option>Paris 11 (Saclay-Sceaux)</option>
                    <option>Paris 13 (Sorbonne Paris Nord, Villetaneuse)</option>
                    <option>Paris 2</option>
                    <option>Paris 8 (Vincennes-Saint-Denis)</option>
                    <option>Paris-Est Créteil-Paris 12 (UPEC)</option>
                    <option>Pau et des pays de l'Adour</option>
                    <option>Perpignan</option>
                    <option>Poitiers</option>
                    <option>Reims-Champagne-Ardennes</option>
                    <option>Rennes I</option>
                    <option>Rouen</option>
                    <option>Saint-Etienne - Université Jean Monnet</option>
                    <option>Strasbourg</option>
                    <option>Toulon</option>
                    <option>Toulouse I</option>
                    <option>Tours</option>
                    <option>Valenciennes</option>
                    <option>Versaille</option>
                  </select> 
                  <span *ngIf="etape2.q2admission == 'Autre'" style="color:red; margin-left:5px"> *</span>




               <!-- <input *ngIf="etape2.q2admission == 'Autre'" type="text" style="height:2em;" class="form-control"  id="inputEmail4" formControlName="q2autre1" name="etape2.q2autre1" [(ngModel)]="etape2.q2autre1" placeholder="Précisez">-->
              </div>
              <span class="text-danger" *ngIf="etape2.q2admission == 'Autre' && etape2form.controls['q2autre1'].hasError('required') && (etape2form.controls['q2autre1'].dirty || etape2form.controls['q2autre1'].touched)">Champ obligatoire</span>  
              </div>

              <br/>
              <p *ngIf="formulaireService.docteurDroit ">
                <i>
                  Toute personne justifiant d'un titre de Docteur en droit délivré
                  par une faculté de droit française (article 12-1 de la loi n° 71-1130 du 31 décembre 1971) sont
                  dispensées de l'examen d'entrée mais doivent suivre tout le cursus des trois périodes de formation à
                  l'Ecole avant de présenter le CAPA. Pour bénéficier de cette dérogation, l'élève doit impérativement
                  justifier de son titre de docteur en droit au plus tard, au 31 décembre de l'année qui précède la date
                  de rentrée. Aucune dérogation ne peut être accordée.
                <br/><br/>
                
                  Les dispositions de l’article 12-1 de la loi du 31/12/1971
                  dispensant les docteurs en droit de l’examen d’accès au CRFPA ne sont pas applicables aux étudiants
                  titulaires d’un doctorat obtenu à l’étranger.
                </i>

              </p>
              <p *ngIf="etape2.q2admission == 'Auditeur_libre_etranger'">
                <i>
                  Merci d’adresser votre dossier au Président d’IXAD, conformément à l’article 1 de l’arrêté du 10 février 1992 fixant les modalités d’admission des étudiants étrangers dans un centre régional de formation professionnelle d’avocats en qualité d’auditeurs libres.<br/>
                  La date butoir du dépôt de dossier est fixée au 06 décembre 2024.<br/>
                  Les enseignements sont dispensés du lundi 16 décembre 2024 au lundi 07 juillet 2025.
                </i>

              </p>
            </div>
          </div>

          <hr *ngIf="formulaireService.docteurDroit  && etape2.q2admission != 'Auditeur_libre_etranger'"/>
          <div class="form-row" id="test"  *ngIf="formulaireService.docteurDroit  && etape2.q2admission != 'Auditeur_libre_etranger'">
            <div class="form-group col-md-12">
              <label for="inputEmail4">Date de soutenance de la thèse<span style="color:red"> *</span> : </label><br/>
              
              <p-calendar [yearNavigator]="true" [monthNavigator]="true" yearRange="1900:2030" (onShow)="calendarTheseDeployTrue()" (onClose)="calendarTheseDeployFalse()" [(ngModel)]="etape2.q9dateThese" formControlName="q9dateThese" inputStyleClass="form-control" dateFormat="dd/mm/yy" [locale]="fr" >
              </p-calendar>
              
              <span class="text-danger" *ngIf="etape2form.controls['q9dateThese'].hasError('required') && !calendarTheseDeploy && (etape2form.controls['q9dateThese'].dirty || etape2form.controls['q9dateThese'].touched)">* Champ obligatoire</span>
            </div>
          </div>

          <hr *ngIf="(!formulaireService.docteurDroit && etape2.q2admission != 'Auditeur_libre_etranger') || (etape2.q2admission == 'Auditeur_libre_etranger' && etape2.q1souhait == 'option3') "/>
          <div class="form-row" id="test"  *ngIf="!formulaireService.docteurDroit && (etape2.q2admission != 'Auditeur_libre_etranger' || etape2.q1souhait == 'option3') " >
            <div class="form-group col-md-12">
              <label for="inputEmail4">Date d'obtention de l'examen d'entrée<span style="color:red"> *</span> : </label><br/>
              
              <p-calendar [yearNavigator]="true" [monthNavigator]="true" yearRange="1900:2030" (onShow)="calendarExamenDeployTrue()" (onClose)="calendarExamenDeployFalse()" [(ngModel)]="etape2.q10dateExamen" formControlName="q10dateExamen" inputStyleClass="form-control" dateFormat="dd/mm/yy" [locale]="fr" >
              </p-calendar><span class="text-danger" *ngIf="etape2form.controls['q10dateExamen'].hasError('required') && !calendarExamenDeploy && (etape2form.controls['q10dateExamen'].dirty || etape2form.controls['q10dateExamen'].touched)">* Champ obligatoire</span>
              <br/> <br/>
              
              
              <p > <small><i><b>Pour les candidats en attente des résultats d’admission, indiquer la date du 24/10/2024</b></i></small> </p>
            </div>
          </div>

          <!-- Question 3 -->
          <hr *ngIf="!formulaireService.docteurDroit && etape2.q1souhait != 'option3' && etape2.q2admission != 'Auditeur_libre_etranger'"/>
          <div class="form-row" *ngIf="!formulaireService.docteurDroit && etape2.q1souhait != 'option3' && etape2.q2admission != 'Auditeur_libre_etranger'">
            <div class="form-group col-md-10">
              <label for="exampleFormControlSelect1">Avez-vous suivi, hormis l’IEJ, une préparation spécifique à l’examen d’entrée, type « prépa privée » ?<span style="color:red"> *</span></label>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" formControlName="q3prepaPrive" name="q3prepaPrive" [(ngModel)]="etape2.q3prepaPrive"  value="Oui" (change)="rdgControlForm()">
                <label class="form-check-label" for="inlineRadio1">Oui</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" formControlName="q3prepaPrive" name="q3prepaPrive" [(ngModel)]="etape2.q3prepaPrive"  value="Non" (change)="rdgControlForm()">
                <label class="form-check-label" for="inlineRadio2">Non</label>
              </div>
              <span class="text-danger" *ngIf="this.etape2.q2admission != 'Docteur_Droit_Organise' && etape2form.controls['q3prepaPrive'].hasError('required') && (etape2form.controls['q3prepaPrive'].dirty || etape2form.controls['q3prepaPrive'].touched)">* Champ obligatoire</span>  
            </div>
          </div>
          <div class="form-row" id="blocprec1" *ngIf="showPrepaPriveOui && !formulaireService.docteurDroit && etape2.q1souhait != 'option3' && etape2.q2admission != 'Auditeur_libre_etranger'"> 
            <div class="form-group col-md-10">
              <label for="exampleFormControlSelect1">Si oui, précisez :</label>
              <span class="text-danger" *ngIf="(etape2.q3prepaPrive == 'Oui' && !etape2.q3prepaAnnuellePresentielle && !etape2.q3prepaAnnuelleDistance && !etape2.q3prepaAnnuelleAutre) && ((etape2form.controls['q3prepaAnnuellePresentielle'].dirty || etape2form.controls['q3prepaAnnuellePresentielle'].touched) || (etape2form.controls['q3prepaAnnuelleDistance'].dirty || etape2form.controls['q3prepaAnnuelleDistance'].touched) ||  (etape2form.controls['q3prepaAnnuelleAutre'].dirty || etape2form.controls['q3prepaAnnuelleAutre'].touched))">&nbsp;* Champ obligatoire</span>  
              <br/>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" formControlName="q3prepaAnnuellePresentielle" name="q3prepaAnnuellePresentielle" [(ngModel)]="etape2.q3prepaAnnuellePresentielle" (change)="rdgControlForm()" >
                <label class="form-check-label" for="exampleRadios2">
                  Préparation annuelle en présentielle
                </label>
              </div><br/>
              <div class="form-row">
                <div class="form-group col-md-5">
                  <input type="text" class="form-control" id="inputEmail4" formControlName="q3prepaAnnuellePresentielleOrganisme" name="q3prepaAnnuellePresentielleOrganisme" [(ngModel)]="etape2.q3prepaAnnuellePresentielleOrganisme" placeholder="Organisme">
                  <span class="text-danger" *ngIf="etape2.q3prepaAnnuellePresentielle && etape2form.controls['q3prepaAnnuellePresentielleOrganisme'].hasError('required') && (etape2form.controls['q3prepaAnnuellePresentielleOrganisme'].dirty || etape2form.controls['q3prepaAnnuellePresentielleOrganisme'].touched)">* Champ obligatoire</span>  
                </div>
                <div class="form-group col-md-5">
                  <input type="text" class="form-control" id="inputPassword4" formControlName="q3prepaAnnuellePresentielleLieu" name="q3prepaAnnuellePresentielleLieu" [(ngModel)]="etape2.q3prepaAnnuellePresentielleLieu"  placeholder="Lieu" > 
                  <span class="text-danger" *ngIf="etape2.q3prepaAnnuellePresentielle && etape2form.controls['q3prepaAnnuellePresentielleLieu'].hasError('required') && (etape2form.controls['q3prepaAnnuellePresentielleLieu'].dirty || etape2form.controls['q3prepaAnnuellePresentielleLieu'].touched)">* Champ obligatoire</span>  
                </div>
               
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" formControlName="q3prepaAnnuelleDistance" name="q3prepaAnnuelleDistance" [(ngModel)]="etape2.q3prepaAnnuelleDistance" (change)="rdgControlForm()">
                <label class="form-check-label" for="exampleRadios3">
                  Préparation annuelle à distance
                </label>
              </div><br/>
              <div class="form-row">
                <div class="form-group col-md-5">
                  <input type="text" class="form-control" id="inputEmail4" formControlName="q3prepaAnnuelleDistanceOrganisme" name="q3prepaAnnuelleDistanceOrganisme" [(ngModel)]="etape2.q3prepaAnnuelleDistanceOrganisme" placeholder="Organisme">
                  <span class="text-danger" *ngIf="etape2.q3prepaAnnuelleDistance && etape2form.controls['q3prepaAnnuelleDistanceOrganisme'].hasError('required') && (etape2form.controls['q3prepaAnnuelleDistanceOrganisme'].dirty || etape2form.controls['q3prepaAnnuelleDistanceOrganisme'].touched)">* Champ obligatoire</span>  
                </div>
                <div class="form-group col-md-5">
                  <input type="text" class="form-control" id="inputPassword4" formControlName="q3prepaAnnuelleDistanceLieu" name="etape2.q3prepaAnnuelleDistanceLieu" [(ngModel)]="etape2.q3prepaAnnuelleDistanceLieu" placeholder="Lieu">
                  <span class="text-danger" *ngIf="etape2.q3prepaAnnuelleDistance && etape2form.controls['q3prepaAnnuelleDistanceLieu'].hasError('required') && (etape2form.controls['q3prepaAnnuelleDistanceLieu'].dirty || etape2form.controls['q3prepaAnnuelleDistanceLieu'].touched)">* Champ obligatoire</span>  
                </div>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" formControlName="q3prepaAnnuelleAutre" name="q3prepaAnnuelleAutre" [(ngModel)]="etape2.q3prepaAnnuelleAutre" (change)="rdgControlForm()">
                <label class="form-check-label" for="exampleRadios4">
                  Autre
                </label>
              </div><br/>
              <div class="form-row">
                <div class="form-group col-md-5">
                  <input type="text" class="form-control" id="inputEmail4" formControlName="q3prepaAnnuelleAutreOrganisme" name="q3prepaAnnuelleAutreOrganisme" [(ngModel)]="etape2.q3prepaAnnuelleAutreOrganisme" placeholder="Organisme">
                  <span class="text-danger" *ngIf="etape2.q3prepaAnnuelleAutre && etape2form.controls['q3prepaAnnuelleAutreOrganisme'].hasError('required') && (etape2form.controls['q3prepaAnnuelleAutreOrganisme'].dirty || etape2form.controls['q3prepaAnnuelleAutreOrganisme'].touched)">* Champ obligatoire</span>  
                </div>
                <div class="form-group col-md-5">
                  <input type="text" class="form-control" id="inputPassword4" formControlName="q3prepaAnnuelleAutreLieu" name="q3prepaAnnuelleAutreLieu" [(ngModel)]="etape2.q3prepaAnnuelleAutreLieu" placeholder="Lieu">
                  <span class="text-danger" *ngIf="etape2.q3prepaAnnuelleAutre && etape2form.controls['q3prepaAnnuelleAutreLieu'].hasError('required') && (etape2form.controls['q3prepaAnnuelleAutreLieu'].dirty || etape2form.controls['q3prepaAnnuelleAutreLieu'].touched)">* Champ obligatoire</span>  
                </div>
              </div>
            </div>
          </div>
          

          <!-- Question 4 !formulaireService.docteurDroit  -->
          <hr *ngIf="etape2.q1souhait != 'option3' && etape2.q2admission != 'Auditeur_libre_etranger'" />
          <div class="form-row" *ngIf="etape2.q1souhait != 'option3' && etape2.q2admission != 'Auditeur_libre_etranger'">
            <div class="form-group col-md-10">
              <label for="exampleFormControlSelect1">Cette inscription à l’EDA Nord-Ouest est :<span style="color:red"> *</span></label> <span class="text-danger" *ngIf="etape2form.controls['q4InscriptionIxad'].hasError('required') && (etape2form.controls['q4InscriptionIxad'].dirty || etape2form.controls['q4InscriptionIxad'].touched)">&nbsp;&nbsp;* Champ obligatoire</span> 

              <div class="form-check">
                <input class="form-check-input" type="radio"  name="q4InscriptionIxad" formControlName="q4InscriptionIxad" [(ngModel)]="etape2.q4InscriptionIxad"  value="premiere" (change)="rdgControlForm()" >
                <label class="form-check-label" for="exampleRadios2">
                  La première
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="q4InscriptionIxad" formControlName="q4InscriptionIxad" [(ngModel)]="etape2.q4InscriptionIxad"  value="deuxieme" (change)="rdgControlForm()">
                <label class="form-check-label" for="exampleRadios3">
                  La deuxième
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="q4InscriptionIxad" formControlName="q4InscriptionIxad" [(ngModel)]="etape2.q4InscriptionIxad"  value="troisieme" (change)="rdgControlForm()">
                <label class="form-check-label" for="exampleRadios4">
                  La troisième
                </label>
              </div>
            </div>
          </div>

          <!-- question 8-->
          <div class="form-row" *ngIf="etape2.q1souhait != 'option3' && etape2.q2admission != 'Auditeur_libre_etranger'">
            <div class="form-group col-md-10">
              <label for="exampleFormControlSelect1">Avez vous déjà passé l'examen du CAPA ?<span style="color:red"> *</span></label> <span class="text-danger" *ngIf="etape2form.controls['q8inscriptionExamenCapa'].hasError('required') && (etape2form.controls['q8inscriptionExamenCapa'].dirty || etape2form.controls['q8inscriptionExamenCapa'].touched)">&nbsp;&nbsp;* Champ obligatoire</span> 

              <div class="form-check">
                <input class="form-check-input" type="radio"  name="q8inscriptionExamenCapa" formControlName="q8inscriptionExamenCapa" [(ngModel)]="etape2.q8inscriptionExamenCapa"  value="premiere" (change)="rdgControlForm()" >
                <label class="form-check-label" for="exampleRadios2">
                  Non 
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="q8inscriptionExamenCapa" formControlName="q8inscriptionExamenCapa" [(ngModel)]="etape2.q8inscriptionExamenCapa"  value="deuxieme" (change)="rdgControlForm()">
                <label class="form-check-label" for="exampleRadios3">
                  Oui, je suis redoublant
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="q8inscriptionExamenCapa" formControlName="q8inscriptionExamenCapa" [(ngModel)]="etape2.q8inscriptionExamenCapa"  value="troisieme" (change)="rdgControlForm()">
                <label class="form-check-label" for="exampleRadios4">
                  Oui, je suis triplant
                </label>
              </div>
            </div>
          </div>

          <div class="form-row" *ngIf="etape2.q1souhait != 'option3' && etape2.q2admission != 'Auditeur_libre_etranger' && (etape2.q8inscriptionExamenCapa == 'deuxieme' || etape2.q8inscriptionExamenCapa == 'troisieme')">
            <div class="form-group col-md-5">
            <label for="exampleFormControlSelect1" *ngIf="etape2.q8inscriptionExamenCapa != 'troisieme'">Précisez dans quelle école d'avocat : </label><span style="color:red"></span>
            <label for="exampleFormControlSelect1"*ngIf="etape2.q8inscriptionExamenCapa == 'troisieme'">Précisez la dernière école d’avocat fréquentée : </label><span style="color:red"></span>
            </div>
            <div class="form-group col-md-5">
            <select class="form-control" id="exampleFormControlSelect1"  name="etape2.q8inscriptionExamenCapaPrecision" formControlName="q8inscriptionExamenCapaPrecision"  [(ngModel)]="etape2.q8inscriptionExamenCapaPrecision">
              <option value="">--- Choisir ---</option>
              <option value="EDA NORD OUEST (IXAD)">EDA NORD OUEST IXAD</option>
              <option value="EDA PARIS (EFB)">EDA PARIS (EFB)</option>
              <option value="EDA VERSAILLES (HEDAC)">EDA VERSAILLES (HEDAC)</option>
              <option value="EDA GRAND EST (ERAGE)">EDA GRAND EST (ERAGE)</option>
              <option value="EDA RHONE- ALPES (EDARA)">EDA RHONE- ALPES (EDARA)</option>
              <option value="EDA SUD-EST (CFBSE)">EDA SUD-EST (CFBSE)</option>
              <option value="EDA CENTRE SUD (EFACS)">EDA CENTRE SUD (EFACS)</option>
              <option value="EDA SUD-OUEST PYRENEES">EDA SUD-OUEST PYRENEES</option>
              <option value="EDA ALIENOR">EDA ALIENOR</option>
              <option value="EDA CENTRE OUEST (ECOA)">EDA CENTRE OUEST (ECOA)</option>
              <option value="EDA GRAND OUEST (EDAGO)">EDA GRAND OUEST (EDAGO)</option>
            </select>
            <span class="text-danger" *ngIf="etape2.q8inscriptionExamenCapa != 'premiere' && etape2form.controls['q8inscriptionExamenCapaPrecision'].hasError('required') && (etape2form.controls['q8inscriptionExamenCapaPrecision'].dirty || etape2form.controls['q8inscriptionExamenCapaPrecision'].touched)">&nbsp;&nbsp;* Champ obligatoire</span> 
            </div>
           
          </div>


          <hr *ngIf="etape2.q1souhait != 'option3' && etape2.q2admission != 'Auditeur_libre_etranger'"/>
          <!-- Question 6 -->

          <div class="form-row" *ngIf="etape2.q1souhait != 'option3' && etape2.q2admission != 'Auditeur_libre_etranger'">
            <div class="form-group col-md-12">
              <label for="exampleFormControlSelect1">Avez-vous une activité extérieure (emploi, TD, cours…) ?</label><span style="color:red"> *</span><br/>
             
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" formControlName="q6activiteExterieur" name="q6activiteExterieur" [(ngModel)]="etape2.q6activiteExterieur" value="Oui" (change)="rdgControlForm(); etape2.q6cocheEngagement = 'true'">
                <label class="form-check-label" for="inlineRadio1">Oui</label>
              </div>
              <div class="form-check form-check-inline" style="margin-bottom:15px">
                <input class="form-check-input" type="radio" formControlName="q6activiteExterieur" name="q6activiteExterieur" [(ngModel)]="etape2.q6activiteExterieur" value="Non" (change)="rdgControlForm()">
                <label class="form-check-label" for="inlineRadio2">Non</label>
              </div>
          
              <span class="text-danger" *ngIf="etape2form.controls['q6activiteExterieur'].hasError('required') && (etape2form.controls['q6activiteExterieur'].dirty || etape2form.controls['q6activiteExterieur'].touched)">&nbsp;&nbsp;*  obligatoire</span> 
              <span *ngIf="etape2.q6activiteExterieur == 'Oui'"><br/><b>Document d'engagement à nous renvoyer complété : <a target="_blank" href="/assets/pdfs/etape2/Engagement_Activite_Exterieure.pdf">à télécharger ici</a></b><br/></span>
              <div *ngIf="etape2.q6activiteExterieur == 'Oui'" class="form-check form-check-inline" style="margin-top:10px">
                <input class="form-check-input" type="checkbox" formControlName="q6cocheEngagement" name="q6cocheEngagement" [(ngModel)]="etape2.q6cocheEngagement" (change)="rdgControlForm()" value="true">
                <label class="form-check-label" style="margin-left:10px;" for="inlineRadio2">Je m’engage à suivre la formation dans son intégralité et aménagerai l’emploi du temps de mes activités extérieures en fonction de celui de l’école.<br/>
                </label>
              </div>
              
         
             

            </div>
          </div>    
          <hr *ngIf="etape2.q1souhait != 'option3' && etape2.q2admission != 'Auditeur_libre_etranger'" />

          <!-- Question 7 
          <div class="form-row" *ngIf="etape2.q1souhait != 'option3' && etape2.q2admission != 'Auditeur_libre_etranger'" >
            <div class="form-group col-md-10">
              <label for="exampleFormControlSelect1">Langue étrangère choisie pour la formation et le CAPA :<span style="color:red"> *</span></label><br/>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" formControlName="q7langueEtrangereChoisie" name="q7langueEtrangereChoisie" [(ngModel)]="etape2.q7langueEtrangereChoisie" value="Anglais">
                <label class="form-check-label" for="inlineRadio1">Anglais</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" formControlName="q7langueEtrangereChoisie" name="q7langueEtrangereChoisie" [(ngModel)]="etape2.q7langueEtrangereChoisie" value="Allemand">
                <label class="form-check-label" for="inlineRadio2">Allemand</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" formControlName="q7langueEtrangereChoisie" name="q7langueEtrangereChoisie"  [(ngModel)]="etape2.q7langueEtrangereChoisie" value="Espagnol">
                <label class="form-check-label" for="inlineRadio2">Espagnol</label>
              </div>
              <span class="text-danger" *ngIf=" etape2form.controls['q7langueEtrangereChoisie'].hasError('required') && (etape2form.controls['q7langueEtrangereChoisie'].dirty || etape2form.controls['q7langueEtrangereChoisie'].touched)">&nbsp;&nbsp;* Champ obligatoire</span> 

            </div>
          </div>   -->

           <!-- Question 11 --> 
          <div class="form-row" *ngIf="etape2.q1souhait != 'option3' && etape2.q2admission != 'Auditeur_libre_etranger'" >
            <div class="form-group col-md-10">
              <label for="exampleFormControlSelect1">L'examen du CAPA ne comporte plus d'interrogation orale en langue vivante étrangère.<br/> IXAD propose de manière facultative un enseignement et une interrogation orale pour le CAPA en anglais.<br/><br/>Êtes-vous intéréssé-e ?<span style="color:red"> *</span></label><br/>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" formControlName="q11AnglaisInterrogation" name="q11AnglaisInterrogation" [(ngModel)]="etape2.q11AnglaisInterrogation" value="Oui">
                <label class="form-check-label" for="inlineRadio1">Oui</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" formControlName="q11AnglaisInterrogation" name="q11AnglaisInterrogation" [(ngModel)]="etape2.q11AnglaisInterrogation" value="Non">
                <label class="form-check-label" for="inlineRadio2">Non</label>
              </div>
              <span class="text-danger" *ngIf=" etape2form.controls['q11AnglaisInterrogation'].hasError('required') && (etape2form.controls['q11AnglaisInterrogation'].dirty || etape2form.controls['q11AnglaisInterrogation'].touched)">&nbsp;&nbsp;* Champ obligatoire</span> 
            </div>
          </div> 

          <hr *ngIf="etape2.q1souhait != 'option3' && etape2.q2admission != 'Auditeur_libre_etranger'" />
           <!-- Question 12 -->
          <div class="form-row" *ngIf="etape2.q1souhait != 'option3' && etape2.q2admission != 'Auditeur_libre_etranger'" >
            <div class="form-group col-md-10">
              <label for="exampleFormControlSelect1">Attention, tous les Élèves Avocats doivent avoir obtenu un Master 2 pour accéder à la profession d'Avocat. <br/><br/>Avez-vous un Master 2 en Droit ?<span style="color:red"> *</span></label><br/>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" formControlName="q12master2" name="q12master2" [(ngModel)]="etape2.q12master2" value="Oui">
                <label class="form-check-label" for="inlineRadio1">Oui</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" formControlName="q12master2" name="q12master2" [(ngModel)]="etape2.q12master2" value="Non">
                <label class="form-check-label" for="inlineRadio2">Non</label>
              </div>
              <span class="text-danger" *ngIf=" etape2form.controls['q12master2'].hasError('required') && (etape2form.controls['q12master2'].dirty || etape2form.controls['q12master2'].touched)">&nbsp;&nbsp;* Champ obligatoire</span> 
              <br/><br/>Si non, votre PPI devra être votre Master 2.<br/> Merci de bien vouloir adresser un courriel à ixadecole@ixad.fr afin de soliciter un rendez-vous avec Madame Karine LOSFELD, Directrice d'IXAD.
            </div>
          </div>   

          <hr *ngIf="etape2.q1souhait != 'option3' && etape2.q2admission != 'Auditeur_libre_etranger'" />
           <!-- Question 13 -->
          <div class="form-row" *ngIf="etape2.q1souhait != 'option3' && etape2.q2admission != 'Auditeur_libre_etranger'" >
            <div class="form-group col-md-10">
              <label for="exampleFormControlSelect1">Diposez-vous d'un ordinateur portable ?<span style="color:red"> *</span></label><br/>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" formControlName="q13Ordinateur" name="q13Ordinateur" [(ngModel)]="etape2.q13Ordinateur" value="Oui">
                <label class="form-check-label" for="inlineRadio1">Oui</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" formControlName="q13Ordinateur" name="q13Ordinateur" [(ngModel)]="etape2.q13Ordinateur" value="Non">
                <label class="form-check-label" for="inlineRadio2">Non</label>
              </div>
              <span class="text-danger" *ngIf=" etape2form.controls['q13Ordinateur'].hasError('required') && (etape2form.controls['q13Ordinateur'].dirty || etape2form.controls['q13Ordinateur'].touched)">&nbsp;&nbsp;* Champ obligatoire</span> 
            </div>
          </div> 

          <hr *ngIf="etape2.q1souhait != 'option3' && etape2.q2admission != 'Auditeur_libre_etranger'" />
           <!-- Question 14 -->
          <div class="form-row" *ngIf="etape2.q1souhait != 'option3' && etape2.q2admission != 'Auditeur_libre_etranger'" >
            <div class="form-group col-md-10">
              <label for="exampleFormControlSelect1">À quelle fréquence utilisez-vous l'Intelligence Artificielle ?<span style="color:red"> *</span></label><br/>
              <input type="text" style="margin-bottom:5px;" class="form-control col-sm-11" id="" formControlName="q14IA" name="q14IA" [(ngModel)]="etape2.q14IA">
              <span class="text-danger" *ngIf="(etape2form.controls['q14IA'].hasError('required') && (etape2form.controls['q14IA'].dirty || etape2form.controls['q14IA'].touched))">&nbsp;&nbsp;* Champ obligatoire</span> 
            </div>
          </div> 

          <h6 style="color:red;" *ngIf="isPreInscrit"><br/><br/>Votre pré-inscription est envoyée, vos modifications ne seront pas enregistrées</h6>

          <br/><br/>
          <div class="form-row">
            <div class="form-group col-md-6 text-right"> 
              <img src="/assets/img/loader-orange.gif" style="width:40px;" *ngIf="loading2" />
              <input type="button" class="btn btn-primary" (click)="previous()" value="Précédent">
            </div>
            <div class="form-group col-md-6 text-left">
              <input  type="button" class="btn btn-primary"  (click)="next()" value="Suivant">
            </div>
          </div>
        
         
        </div>
      </div>
    </div>
  </div>
</div>