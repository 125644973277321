import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../services/auth/auth.service';
import { ParametreService } from '../services/parametre.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    public authService: AuthService,
    public router: Router
  ){ }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    
    
      const response = this.authService.getParameterPublic().pipe(map((result:any)=> {

        let autoriseConnexion = result[1].valeurParametre;
     
  
        if(autoriseConnexion == 'true' && this.authService.isLoggedIn)
        {
          return true;
        }
        else{
          this.router.navigate(['/login'])
          return false;
        }
      }));
      return response;    
  }
  
}
