import { Serializable } from './serializable';

export class InformationPaiementRequest extends Serializable  {

    constructor()
    {
        super();
        this.vads_action_mode = "";
        this.vads_ctx_mode = "";
        this.vads_currency = "";
        this.vads_cust_country = "FR";
        this.vads_page_action = "";
        this.vads_payment_config = "";
        this.vads_site_id = "";
        this.vads_version = "";
        this.vads_shop_name = "";
        this.vads_shop_url = "";
        this.vads_amount = "";
        this.signature = "";
        this.vads_trans_date = "";
        this.vads_trans_id = "";
        this.vads_order_id = "";
        this.vads_cust_email = "";
        this.vads_cust_id = "";
        this.vads_cust_last_name = "";
        this.vads_cust_first_name = "";
        this.vads_url_check = "";
        this.vads_url_return = "";
        this.vads_url_error = "";
        this.vads_url_refused = "";
        this.vads_url_success = "";
        this.vads_url_cancel = "";
        this.vads_redirect_error_message = "Vous allez être redirigé vers IXAD Inscription";
        this.vads_redirect_error_timeout = "5";
        this.vads_redirect_success_message = "Vous allez être redirigé vers IXAD Inscription";
        this.vads_redirect_success_timeout = "5";
        this.vads_return_mode = "GET";
    }

    vads_action_mode : string;
    vads_ctx_mode : string;
    vads_currency : string;
    vads_cust_country : string;
    vads_page_action : string;
    vads_payment_config : string;
    vads_site_id : string;
    vads_version : string;
    vads_shop_name : string;
    vads_shop_url : string;
    vads_amount : string;
    signature : string;
    vads_trans_date : string;
    vads_trans_id : string;
    vads_order_id : string;
    vads_cust_email : string;
    vads_cust_id : string;
    vads_cust_last_name : string;
    vads_cust_first_name : string;
    vads_url_return : string;
    vads_url_error : string;
    vads_url_refused : string;
    vads_url_success : string;
    vads_url_cancel : string;
    vads_url_check : string;

    vads_redirect_error_message : string;
    vads_redirect_error_timeout : string;
    vads_redirect_success_message : string;
    vads_redirect_success_timeout : string;
    vads_return_mode : string;

}
