import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Formulaire } from 'src/app/model/formulaire';
import { Etape2 } from 'src/app/model/formulaire/etape2';
import { AuthService } from 'src/app/services/auth/auth.service';
import { FormulaireService } from 'src/app/services/formulaire/formulaire.service';

@Component({
  selector: 'app-prevalid',
  templateUrl: './prevalid.component.html',
  styleUrls: ['./prevalid.component.css']
})
export class PrevalidComponent implements OnInit {

  public isPreInscrit:boolean = false;

  public loadedService1 = false;
  
  public etape2 : Etape2 = new Etape2();

  constructor(public formulaireService: FormulaireService, public authService: AuthService, public router: Router) { }

  ngOnInit(): void {
    if(this.authService.isLoggedIn) {
      const user  =  JSON.parse(localStorage.getItem('user'));
      this.formulaireService.defineIsPreInscrit(user.uid).subscribe((data :any)=>{
            
        this.isPreInscrit = data.isPreInscrit;  
        if(!this.isPreInscrit)
        {
          this.router.navigate(['formulaire/etape5']);
        }
         
      },
      error => {
      
      });
      
      this.loadDataEtape2();
    }

    


  }

  public loadDataEtape2()
  {
    const user  =  JSON.parse(localStorage.getItem('user'));
    this.formulaireService.loadEtape2(user.uid).subscribe((data:any)=>{
      
      if(data.etape2 != null)
      {
        Formulaire.convertJsonToObject(this.formulaireService.formulaire ,data);
        this.etape2 = Object.assign(this.etape2, this.formulaireService.formulaire.etape2);
      }
      else{
      
          this.etape2.uid =  user.uid;
        
      }
      this.loadedService1 = true;
    
      

    },
    error => {
     
    });
  }
  
  public close_window() {
    this.router.navigate(['/login']);
  }

}
