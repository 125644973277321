import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  email1User:string;
  email2User:string;
  passwordUser:string;
  errorMessage:string;
  constructor(public  authService:  AuthService) { }

  ngOnInit(): void {
  }

  register(){

    const firebaseErrors = {
      'auth/user-not-found': 'Utilisateur non trouvé',
      'auth/email-already-exists': 'Adresse email déjà enregistrée',
      'auth/email-already-in-use': 'Adresse email déjà enregistrée',
      'auth/invalid-email':'Adresse email invalide',
      'auth/invalid-email-verified':'Email non vérifié, vérifiez vos emails',
      'auth/invalid-password':'Password non valide',
      'auth/too-many-requests':'Trop de tentatives d\'authentification répétées. Veuillez attendre quelques minutes',
      'auth/wrong-password':'Password non valide',
      'auth/argument-error':'Renseignez les champs',
      'auth/weak-password':'Le mot de passe doit contenir au moins 6 caractères',
      'OK':''
    }; 

    this.authService.register(this.email1User, this.passwordUser).then(result =>{
      if(result != "OK"){
        this.errorMessage = firebaseErrors[result.code];

      }
      else{
        this.errorMessage = firebaseErrors[result];
      }
    
    });
    

  }

}
