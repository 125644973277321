<div [formGroup]="etape4form">
<div class="container contact">
    <div class="row">
      <div class="col-md-3 customMD3">
        <div class="contact-info">
          <span class="pi pi-sign-out" style="color:white"></span><a class="linkDisconnect" href="#" (click)="authService.logout()">Se déconnecter</a>
          <a routerLink="/formulaire/etape1"><img src="/assets/img/logo-ixad.png" class="img-fluid" alt="image"/></a>
          <h2 style="color:white" class="anneeStyle">INSCRIPTION {{ authService.anneeInscription1 }} / {{ authService.anneeInscription2 }}</h2>
          <br/><br/><br/><i style="color:white">Pour toute question ou problème technique lors du remplissage du formulaire, merci d'envoyer un mail à <a href="mailto:inscription@ixad.fr">inscription@ixad.fr</a></i>
          <h4></h4>
        </div>
      </div>
      <div class="col-md-9" *ngIf="!loadedService1 || !loadedService2 || !loadedService4"><img src="/assets/img/loader-orange.gif" style="width:100px"/></div>
      <div class="col-md-9" *ngIf="loadedService1 && loadedService2">
        
        <h4>Etape 4 sur 5 - vos documents</h4><br/><br/>
        <div class="contact-form">

          <div class="form-group">
            <p><b><i>Les documents demandés doivent être déposés au format PDF ou WORD.</i>
              <br/>
              
              <br/>Documents à prévoir pour compléter votre dossier :</b>
              <span *ngIf="nomUsageNotPresent"><i style="color:red"><br/><b>Attention, votre nom d'usage est manquant, veuillez le compléter pour envoyer vos documents</b></i></span>
            </p>
              
             
            <label for="exampleFormControlFile1">Photocopie de la pièce d'identité ou titre de séjour <i style="color:red"> *   </i> <br/><i>La Carte Nationale d'Identité doit être en Recto/Verso et les pièces en cours de validité.</i></label> 
            
            <input type="file" #childFichierDocPieceIdentite  accept=".doc, .docx, .pdf" class="form-control-file" id="exampleFormControlFile1" formControlName="nomFichierDocPieceIdentite"  name="etape4.nomFichierDocPieceIdentite" (change)="onFileChangeCarteId($event)" placeholder="Fichier..." observeFiles >
            Fichier  envoyé : {{etape4.nomFichierDocPieceIdentite}} <img *ngIf="etape4.nomFichierDocPieceIdentite != ''" src="/assets/img/check.png" class="img-fluid" style="width:3%" alt="image"/>
            <span class="text-danger" *ngIf="etape4.nomFichierDocPieceIdentite == '' && etape4form.controls['nomFichierDocPieceIdentite'].hasError('required') && (etape4form.controls['nomFichierDocPieceIdentite'].dirty || etape4form.controls['nomFichierDocPieceIdentite'].touched)">&nbsp;&nbsp;* Champ obligatoire</span> 
          </div>



          <div class="form-group">
            <label for="exampleFormControlFile2">Photocopie du diplôme de Master 1 ou celui de maîtrise</label> <i style="color:red"> *   </i>
            <input type="file" #childFichierDiplomeMaster1Maitrise class="form-control-file" id="exampleFormControlFile2" formControlName="nomFichierDiplomeMaster1Maitrise" name="etape4.fichierDiplomeMaster1Maitrise" (change)="onFileChangeDocMaster($event)" >
            Fichier envoyé : {{etape4.nomFichierDiplomeMaster1Maitrise}} <img *ngIf="etape4.nomFichierDiplomeMaster1Maitrise != ''" src="/assets/img/check.png" class="img-fluid" style="width:3%" alt="image"/>
            <span class="text-danger" *ngIf="etape4.nomFichierDiplomeMaster1Maitrise == '' && etape4form.controls['nomFichierDiplomeMaster1Maitrise'].hasError('required') && (etape4form.controls['nomFichierDiplomeMaster1Maitrise'].dirty || etape4form.controls['nomFichierDiplomeMaster1Maitrise'].touched)">&nbsp;&nbsp;* Champ obligatoire</span> 
          </div>

          <div class="form-group" *ngIf="isDocteurDroit || etape2.q12master2 == 'Oui'">
            <label for="exampleFormControlFile3">Photocopie du diplôme de Master 2 </label> <i style="color:red"> *   </i>
            <input type="file" #childFichierDiplomeMaster2Maitrise class="form-control-file" id="exampleFormControlFile4" formControlName="nomFichierDiplomeMaster2Maitrise" name="etape4.fichierDiplomeMaster2Maitrise" (change)="onFileChangeDocMaster2($event)" >
            Fichier envoyé : {{etape4.nomFichierDiplomeMaster2Maitrise}} <img *ngIf="etape4.nomFichierDiplomeMaster2Maitrise != ''" src="/assets/img/check.png" class="img-fluid" style="width:3%" alt="image"/>
            <span class="text-danger" *ngIf="etape4.nomFichierDiplomeMaster2Maitrise == '' && etape4form.controls['nomFichierDiplomeMaster2Maitrise'].hasError('required') && (etape4form.controls['nomFichierDiplomeMaster2Maitrise'].dirty || etape4form.controls['nomFichierDiplomeMaster2Maitrise'].touched)">&nbsp;&nbsp;* Champ obligatoire</span> 
          </div>

          <div class="form-group" *ngIf="isDocteurDroit">
            <label for="exampleFormControlFile5">1 exemplaire de la thèse </label>  <i style="color:red" > *   </i>
            <input type="file" #childFichierThese class="form-control-file" id="exampleFormControlFile6" formControlName="nomFichierThese" name="etape4.fichierThese" (change)="onFileChangeDocThese($event)" >
            Fichier envoyé : {{etape4.nomFichierThese}}  <img *ngIf="etape4.nomFichierThese != ''" src="/assets/img/check.png" class="img-fluid" style="width:3%" alt="image"/>
            <span class="text-danger" *ngIf="etape4.nomFichierThese == '' && etape4form.controls['nomFichierThese'].hasError('required') && (etape4form.controls['nomFichierThese'].dirty || etape4form.controls['nomFichierThese'].touched)">&nbsp;&nbsp;* Champ obligatoire</span> 
          </div>

          <div class="form-group" *ngIf="hasActiviteExt">
            <p><b></b></p>
            <label for="exampleFormControlFile3">Document d'engagement - activité extérieure</label>  <i style="color:red"> *   </i> - <a target="_blank" href="/assets/pdfs/etape2/Engagement_Activite_Exterieure.pdf">à télécharger ici</a>
           
            <input type="file" #childFichierEngagementActiviteExt class="form-control-file" id="exampleFormControlFile7" formControlName="nomFichierEngagementActiviteExt" name="etape4.fichierEngagementActiviteExt" (change)="onFileChangeDocEngagementActiviteExt($event)" >
            Fichier envoyé : {{etape4.nomFichierEngagementActiviteExt}} <img *ngIf="etape4.nomFichierEngagementActiviteExt != ''  && etape4.nomFichierEngagementActiviteExt != null"  src="/assets/img/check.png" class="img-fluid" style="width:3%" alt="image"/>
            <span class="text-danger" *ngIf="etape4.nomFichierEngagementActiviteExt == '' && etape4form.controls['nomFichierEngagementActiviteExt'].hasError('required') && (etape4form.controls['nomFichierEngagementActiviteExt'].dirty || etape4form.controls['nomFichierEngagementActiviteExt'].touched)">&nbsp;&nbsp;* Champ obligatoire</span> 
          </div>

          <!--
          <div class="form-group" *ngIf="hasActiviteExt">
            <p><b></b></p>
            <label for="exampleFormControlFile3">Autorisation de cumul </label>  <i style="color:red"> *   </i>
              
            <input type="file" #childFichierAutorisationCumulActiviteExt class="form-control-file" id="exampleFormControlFile8" formControlName="nomFichierAutorisationCumulActiviteExt" name="etape4.fichierAutorisationCumulActiviteExt" (change)="onFileChangeDocAutorisationCumulActiviteExt($event)" >
            Fichier envoyé : {{etape4.nomFichierAutorisationCumulActiviteExt}} <img *ngIf="etape4.nomFichierAutorisationCumulActiviteExt != '' && etape4.nomFichierAutorisationCumulActiviteExt != null" src="/assets/img/check.png" class="img-fluid" style="width:3%" alt="image"/>
            <span class="text-danger" *ngIf="etape4.nomFichierAutorisationCumulActiviteExt == '' && etape4form.controls['nomFichierAutorisationCumulActiviteExt'].hasError('required') && (etape4form.controls['nomFichierAutorisationCumulActiviteExt'].dirty || etape4form.controls['nomFichierAutorisationCumulActiviteExt'].touched)">&nbsp;&nbsp;* Champ obligatoire</span> 
          </div>
          -->
          <div class="form-group" *ngIf="lieuNaissance == 'Hors du territoire national'">
            <label for="exampleFormControlFile4">Document de condition de nationalité </label>  <i style="color:red"> *   </i>
            <input type="file" #childFichierNationalite class="form-control-file" id="exampleFormControlFile5" formControlName="nomFichierNationalite" name="etape4.fichierDiplomeDocteurDroit" (change)="onFileChangeDocNationalite($event)" >
            Fichier envoyé : {{etape4.nomFichierNationalite}} <img *ngIf="etape4.nomFichierNationalite != ''" src="/assets/img/check.png" class="img-fluid" style="width:3%" alt="image"/>
            <span class="text-danger" *ngIf="etape4.nomFichierNationalite == '' && etape4form.controls['nomFichierNationalite'].hasError('required') && (etape4form.controls['nomFichierNationalite'].dirty || etape4form.controls['nomFichierNationalite'].touched)">&nbsp;&nbsp;* Champ obligatoire</span> 
          </div>

          <div class="form-group" *ngIf="isDocteurDroit">
            <label for="exampleFormControlFile3">Attestation de soutenance de l’Ecole Doctorale </label> <i style="color:red" *ngIf="etape4.nomFichierDiplomeDocteurDroit == '' || etape4.nomFichierAttestationSoutenanceEcoleDoctorat != ''"> *   </i>
            <input type="file" #childFichierAttestationSoutenanceEcoleDoctorat class="form-control-file" id="exampleFormControlFile4" formControlName="nomFichierAttestationSoutenanceEcoleDoctorat" name="etape4.fichierAttestationSoutenanceEcoleDoctorat" (change)="onFileChangeDocAttestation($event)" >
            Fichier envoyé : {{etape4.nomFichierAttestationSoutenanceEcoleDoctorat}} <img *ngIf="etape4.nomFichierAttestationSoutenanceEcoleDoctorat != ''" src="/assets/img/check.png" class="img-fluid" style="width:3%" alt="image"/>
            <span class="text-danger" *ngIf="etape4.nomFichierAttestationSoutenanceEcoleDoctorat == '' && etape4form.controls['nomFichierAttestationSoutenanceEcoleDoctorat'].hasError('required') && (etape4form.controls['nomFichierAttestationSoutenanceEcoleDoctorat'].dirty || etape4form.controls['nomFichierAttestationSoutenanceEcoleDoctorat'].touched)">&nbsp;&nbsp;* Champ obligatoire</span> 
            <br/><br/><span style="padding-left:20px"><b><i>Et/ou</i></b></span><br/>
          </div>
          

          <div class="form-group" *ngIf="isDocteurDroit">
            <label for="exampleFormControlFile4">Photocopie du diplôme de Docteur en Droit </label>  <i style="color:red" *ngIf="etape4.nomFichierDiplomeDocteurDroit != '' || etape4.nomFichierAttestationSoutenanceEcoleDoctorat == ''"> *   </i>
            <input type="file" #childFichierDiplomeDocteurDroit class="form-control-file" id="exampleFormControlFile5" formControlName="nomFichierDiplomeDocteurDroit" name="etape4.fichierDiplomeDocteurDroit" (change)="onFileChangeDocDiplome($event)" >
            Fichier envoyé : {{etape4.nomFichierDiplomeDocteurDroit}} <img *ngIf="etape4.nomFichierDiplomeDocteurDroit != ''" src="/assets/img/check.png" class="img-fluid" style="width:3%" alt="image"/>
            <span class="text-danger" *ngIf="etape4.nomFichierDiplomeDocteurDroit == '' && etape4form.controls['nomFichierDiplomeDocteurDroit'].hasError('required') && (etape4form.controls['nomFichierDiplomeDocteurDroit'].dirty || etape4form.controls['nomFichierDiplomeDocteurDroit'].touched)">&nbsp;&nbsp;* Champ obligatoire</span> 
          </div>

  
          <h6 style="color:red;" *ngIf="isPreInscrit">Votre pré-inscription est envoyée, vos modifications ne seront pas enregistrées</h6>
          
          <br/><br/>
          <div class="form-row">
            <div class="form-group col-md-6 text-right">
              <img src="/assets/img/loader-orange.gif" style="width:40px;" *ngIf="loading2" />
              <input type="button" class="btn btn-primary" (click)="previous()" value="Précédent">
            </div>
            <div class="form-group col-md-6 text-left">
              <input type="button" class="btn btn-primary" (click)="next()"   value="Suivant">
              
            </div>
          </div>

          
        </div>
      </div>
    </div>
  </div>
  </div>