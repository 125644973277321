import { Etape1 } from './formulaire/etape1';
import { Etape2 } from './formulaire/etape2';
import { Etape3 } from './formulaire/etape3';
import { Etape4 } from './formulaire/etape4';
import { Serializable } from './serializable';
import { Etape4Component } from '../pages/form-inscription/etape4/etape4.component';
import { Etape6 } from './formulaire/etape6';



export class Formulaire extends Serializable {

    constructor(){
        super();
        this.etape1 = new Etape1();
      
        this.etape2 = new Etape2();
        this.etape3 = new Etape3();
        this.etape4 = new Etape4();
        this.etape6 = new Etape6();
    }

    etape1:Etape1;

    etape2:Etape2;

    etape3:Etape3;

    etape4:Etape4;

    etape6:Etape6;

  
}
