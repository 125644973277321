<div class="container contact">
    <div class="row">
      <div class="col-md-3 customMD3">
        <div class="contact-info">
          <a routerLink="/login"><img src="/assets/img/logo-ixad.png" class="img-fluid" alt="image"/></a>
          <h2 style="color:white" class="anneeStyle">INSCRIPTION {{ authService.anneeInscription1 }} / {{ authService.anneeInscription2 }}</h2>
          <br/><br/><br/><i style="color:white">Pour toute question ou problème technique lors du remplissage du formulaire, merci d'envoyer un mail à <a href="mailto:inscription@ixad.fr">inscription@ixad.fr</a></i>
          <h4></h4>
        </div>
      </div>
      <div class="col-md-9">
        
        <h4>Validation de votre compte</h4><br/>
        
        
        <div class="contact-form">


          <div class="form-row ">
            <div class="form-group col-md-12 ">
              

              <p>
                <br/>
                Vous allez recevoir un email dans les 5 prochaines minutes pour finaliser la création de votre compte.<br/>
                <br/><b>Important : </b>pensez à vérifier vos spams si vous ne recevez pas l'email de validation. <br/><br/>
  
                <b>Attention</b>, des problèmes ont été rencontrés avec certaines adresses (comme SFR.FR et FREE.FR). <br/>
                Il est possible que vous ne receviez pas l’email de confirmation. <br/>
                <br/>- Si vous êtes dans ce cas, faites une demande de réinitialisation de mot de passe via le bouton : "mot de passe oublié" sur la page d’accueil. <br/>
                - Si après cette tentative, cela ne fonctionne pas, nous vous conseillons de recommencer la création de votre compte en utilisant une autre adresse email. (GMAIL, HOTMAIL etc.)<br/><br/>
                En cas de problème malgré le changement d’adresse email, ou pour tout autre problème, vous pouvez contacter le support à l’adresse : <a href="mailto:inscription@ixad.fr">inscription@ixad.fr</a>
                <br/><br/>
                <i>Nous nous excusons pour la gêne occasionnée et vous remercions par avance de votre compréhension.</i><br/><br/>
              </p>
            </div>
            <button routerLink="/login" type="button" class="btn btn-primary" >Retourner à l'accueil</button> 
          </div> <br/><br/> 
        
          
          
        </div>
      </div>
    </div>
  </div>