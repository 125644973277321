
<div  [formGroup]="etape6form">
    <div class="container contact">
      <div class="row">
        <div class="col-md-3 customMD3">
          <div class="contact-info">
            <span class="pi pi-sign-out" style="color:white"></span><a class="linkDisconnect" href="#" (click)="authService.logout()">Se déconnecter</a>
            <a routerLink="/formulaire/etape1"><img src="/assets/img/logo-ixad.png" class="img-fluid" alt="image"/></a>
            <h2 style="color:white" class="anneeStyle">INSCRIPTION {{ authService.anneeInscription1 }} / {{ authService.anneeInscription2 }}</h2>
            <br/><br/><br/><i style="color:white">Pour toute question ou problème technique lors du remplissage du formulaire, merci d'envoyer un mail à <a href="mailto:inscription@ixad.fr">inscription@ixad.fr</a></i>
            <h4></h4>
          </div>
        </div>
        <div class="col-md-9" *ngIf="!loadedService1 || !loadedService2 || !loadedService3"><img src="/assets/img/loader-orange.gif" style="width:100px"/></div>
        <div class="col-md-9" *ngIf="loadedService2 && !hasPreInscriptionValide">Votre pré-inscription n'est pas validée par IXAD, veuillez contacter l'organisme.</div>
        <div class="col-md-9" *ngIf="loadedService1 && loadedService2 && loadedService3 && hasPreInscriptionValide">
          
          <h4>Finaliser votre inscription</h4><br/>
          <p>Complétez les champs ci-dessous pour finaliser votre inscription.</p><br/>
           



            <div class="form-row">
              <div class="form-group col-md-12">
                <label for="exampleFormControlSelect1">Règlements intérieurs <span style="color:red"> *</span></label><br/>
               
                <div class="form-check form-check-inline" style="margin-top:10px">
                  <input class="form-check-input" type="checkbox"    formControlName="q2cocheEngagement" name="q2cocheEngagement" [(ngModel)]="etape6.q2cocheEngagement" (change)="rdgControlForm()" value="true">
                  <label class="form-check-label" style="margin-left:10px;" for="inlineRadio2">Je m'engage à respecter les règlements intérieurs</label>
                </div>
                <span class="text-danger" *ngIf="!etape6.q2cocheEngagement && (etape6form.controls['q2cocheEngagement'].dirty || etape6form.controls['q2cocheEngagement'].touched)">&nbsp;&nbsp;* Champ obligatoire</span> 
                <br/><br/> Règlement intérieur IXAD à <a target="_blank" href="/assets/pdfs/etape6/RI_2025.pdf">télécharger ici</a> <br/>
                Règlement intérieur du centre de formation à <a target="_blank" href="/assets/pdfs/etape6/RI_centre_de_formation.pdf">télécharger ici</a> <br/>
              </div>
            </div>    
            <hr/>
            <!--
            <div class="form-row">
              <div class="form-group col-md-12">
                <label for="exampleFormControlSelect1">Règlement intérieur V2 à <a target="_blank" href="/assets/pdfs/etape6/RIV2.pdf">télécharger ici</a><span style="color:red"> *</span></label><br/>
               
                <div class="form-check form-check-inline" style="margin-top:10px">
                  <input class="form-check-input" type="checkbox"    formControlName="q8cocheReglementV2" name="q8cocheReglementV2" [(ngModel)]="etape6.q8cocheReglementV2" (change)="rdgControlForm()" value="true">
                  <label class="form-check-label" style="margin-left:10px;" for="inlineRadio2">Je m'engage à respecter le règlement intérieur V2</label>
                </div>
                <span class="text-danger" *ngIf="!etape6.q8cocheReglementV2 && (etape6form.controls['q8cocheReglementV2'].dirty || etape6form.controls['q8cocheReglementV2'].touched)">&nbsp;&nbsp;* Champ obligatoire</span> 
            
                
              </div>
            </div>    
            <hr/>-->
<!--
            <div class="form-row">
              <div class="form-group col-md-12">
                <label for="exampleFormControlSelect1">Charte de confidentialité à <a target="_blank" href="/assets/pdfs/etape6/CHARTE-CONFIDENTIALITE.pdf">télécharger ici</a><span style="color:red"> *</span></label><br/>
               
                <div class="form-check form-check-inline" style="margin-top:10px">
                  <input class="form-check-input" type="checkbox"    formControlName="q9charteConfidentialite" name="q9charteConfidentialite" [(ngModel)]="etape6.q9charteConfidentialite" (change)="rdgControlForm()" value="true">
                  <label class="form-check-label" style="margin-left:10px;" for="inlineRadio2">Je m'engage à respecter la charte de confidentialité</label>
                </div>
                <span class="text-danger" *ngIf="!etape6.q9charteConfidentialite && (etape6form.controls['q9charteConfidentialite'].dirty || etape6form.controls['q9charteConfidentialite'].touched)">&nbsp;&nbsp;* Champ obligatoire</span> 
               
              
              </div>
            </div>    
            <hr/>
          -->
            <div class="form-row">
              <div class="form-group col-md-12">
                <label for="exampleFormControlSelect1">Charte informatique à <a target="_blank" href="/assets/pdfs/etape6/CHARTE-INFORMATIQUE-ELEVES.pdf">télécharger ici</a><span style="color:red"> *</span></label><br/>
               
                <div class="form-check form-check-inline" style="margin-top:10px">
                  <input class="form-check-input" type="checkbox"  formControlName="q3cocheCharteInformatique" name="q3cocheCharteInformatique" [(ngModel)]="etape6.q3cocheCharteInformatique" (change)="rdgControlForm()" value="true">
                  <label class="form-check-label" style="margin-left:10px;" for="inlineRadio2">J'atteste avoir pris connaissance de la charte informatique et m'engage à la respecter</label>
                </div>
                <span class="text-danger" *ngIf="!etape6.q3cocheCharteInformatique && (etape6form.controls['q3cocheCharteInformatique'].dirty || etape6form.controls['q3cocheCharteInformatique'].touched)">&nbsp;&nbsp;* Champ obligatoire</span> 
               
              </div>
            </div>    
            <hr/>

            <div class="form-row">
              <div class="form-group col-md-12">
                <label for="exampleFormControlSelect1">Charte de courtoisie numérique à <a target="_blank" href="/assets/pdfs/etape6/CHARTE-COURTOISIE-NUMERIQUE.pdf">télécharger ici</a><span style="color:red"> *</span></label><br/>
               
                <div class="form-check form-check-inline" style="margin-top:10px">
                  <input class="form-check-input" type="checkbox"  formControlName="q6CocheCharteCourtoisie" name="q6CocheCharteCourtoisie" [(ngModel)]="etape6.q6CocheCharteCourtoisie" (change)="rdgControlForm()" value="true">
                  <label class="form-check-label" style="margin-left:10px;" for="inlineRadio2">Je m'engage à respecter la charte de courtoisie numérique (Cours en distanciel)</label>
                </div>
                <span class="text-danger" *ngIf="!etape6.q6CocheCharteCourtoisie && (etape6form.controls['q6CocheCharteCourtoisie'].dirty || etape6form.controls['q6CocheCharteCourtoisie'].touched)">&nbsp;&nbsp;* Champ obligatoire</span> 
               
              </div>
            </div>    
            <hr/>

            <div class="form-row">
              <div class="form-group col-md-12">
                <label for="exampleFormControlSelect1">Politique de protection des données personnelles à <a target="_blank" href="/assets/pdfs/etape4/ixad_politique_ext.pdf">télécharger ici</a> <span style="color:red"> *</span></label><br/>
               
                <div class="form-check form-check-inline" style="margin-top:10px">
                  <input class="form-check-input" type="checkbox"  formControlName="q5protectionDonnees" name="q5protectionDonnees" [(ngModel)]="etape6.q5protectionDonnees" (change)="rdgControlForm()" value="true">
                  <label class="form-check-label" style="margin-left:10px;" for="inlineRadio2">J'atteste avoir pris connaissance de la politique de protection des données personnelles</label>
                </div>
                <span class="text-danger" *ngIf="!etape6.q5protectionDonnees && (etape6form.controls['q5protectionDonnees'].dirty || etape6form.controls['q5protectionDonnees'].touched)">&nbsp;&nbsp;* Champ obligatoire</span> 
               
              </div>
            </div>    
            <hr/>

            <div class="form-row">
              <div class="form-group col-md-12">
                <label for="exampleFormControlSelect1">Charte éthique IXAD à <a target="_blank" href="/assets/pdfs/etape6/charte_ethique_ixad.pdf">télécharger ici</a> <span style="color:red"> *</span></label><br/>
               
                <div class="form-check form-check-inline" style="margin-top:10px">
                  <input class="form-check-input" type="checkbox"  formControlName="q11CocheCharteIxad" name="q11CocheCharteIxad" [(ngModel)]="etape6.q11CocheCharteIxad" (change)="rdgControlForm()" value="true">
                  <label class="form-check-label" style="margin-left:10px;" for="inlineRadio2">J'atteste avoir pris connaissance de la charte éthique et m'engage à la respecter.</label>
                </div>
                <span class="text-danger" *ngIf="!etape6.q11CocheCharteIxad && (etape6form.controls['q11CocheCharteIxad'].dirty || etape6form.controls['q11CocheCharteIxad'].touched)">&nbsp;&nbsp;* Champ obligatoire</span> 
               
              </div>
            </div>    
            <hr/>

            <div class="form-row">
              <div class="form-group col-md-12">
                <label for="exampleFormControlSelect1">Attestation sur l'honneur<span style="color:red"> *</span></label><br/>
               
                <div class="form-check form-check-inline" style="margin-top:10px">
                  <input class="form-check-input" type="checkbox"    formControlName="q10condamnation" name="q10condamnation" [(ngModel)]="etape6.q10condamnation" (change)="rdgControlForm()" value="true">
                  <label class="form-check-label" style="margin-left:10px;" for="inlineRadio2">J'atteste sur l'honneur n'être sous le coup d'aucune condamnation ni incompatibilité s'opposant à l'accès de la profession d'avocat</label>
                </div>
                <span class="text-danger" *ngIf="!etape6.q10condamnation && (etape6form.controls['q10condamnation'].dirty || etape6form.controls['q10condamnation'].touched)">&nbsp;&nbsp;* Champ obligatoire</span> 
              </div>
            </div>    
            <hr/>

            
            <div class="form-row">
              <div class="form-group col-md-12">
                <label for="exampleFormControlSelect1">Lettre d'information <span style="color:red"> *</span></label><br/>
               
                <div class="form-check form-check-inline" style="margin-top:10px">
                  <input class="form-check-input" type="checkbox"  formControlName="q7cocheCnb" name="q7cocheCnb" [(ngModel)]="etape6.q7cocheCnb" (change)="rdgControlForm()" value="true">
                  <label class="form-check-label" style="margin-left:10px;" for="inlineRadio2">J'accepte de communiquer mon adresse e-mail au CNB afin de recevoir ses lettres d’information.</label>
                </div>
                <span class="text-danger" *ngIf="!etape6.q7cocheCnb && (etape6form.controls['q7cocheCnb'].dirty || etape6form.controls['q7cocheCnb'].touched)">&nbsp;&nbsp;* Champ obligatoire</span> 
               
              </div>
            </div>    
            <hr/>
            
    

            <hr/>

            <div class="form-row">
              <div class="form-group col-md-12">
                <label for="exampleFormControlSelect1">Règlement des frais de scolarité</label><br/>
                <p>Les droits de scolarité fixés par le Conseil d'Administration d'IXAD s'élèvent <b>à 1825 € (mille huit cent vingt cinq euros).</b>  
                  <br/><i>[Arrêté du 19 juillet 2017] : Prestation hors champs d'application de la TVA, rendue dans l'exercice d’une mission d'intérêt général.</i>
                </p>
              </div>
            </div>

             <!-- Question 2 -->
             <div class="form-row">
              <div class="form-group col-md-10">
                <label for="exampleFormControlSelect1">Les modalités de paiement doivent être réceptionnées par IXAD au moment de votre entretien d’inscription.<span style="color:red"> *</span></label><span class="text-danger" *ngIf="etape6form.controls['q1ChoixPaiement'].hasError('required') && (etape6form.controls['q1ChoixPaiement'].dirty || etape6form.controls['q1ChoixPaiement'].touched)">&nbsp;* Champ obligatoire</span>
               
                <div class="form-check">
                  <input class="form-check-input" type="radio" formControlName="q1ChoixPaiement"  name="q1ChoixPaiement" [(ngModel)]="etape6.q1ChoixPaiement"  value="paiement_comptant" (change)="applyMandatory()">
                  <label class="form-check-label" for="exampleRadios3">
                    Paiement comptant par chèque
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" formControlName="q1ChoixPaiement"  name="q1ChoixPaiement" [(ngModel)]="etape6.q1ChoixPaiement"  value="paiement_echelonne" (change)="applyMandatory()">
                  <label class="form-check-label" for="exampleRadios3">
                    Paiement échelonné par chèque
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" formControlName="q1ChoixPaiement"  name="q1ChoixPaiement" [(ngModel)]="etape6.q1ChoixPaiement"  value="paiement_aide" (change)="applyMandatory()">
                  <label class="form-check-label" for="exampleRadios3">
                    Sollicitation d'une aide sur critères sociaux
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" formControlName="q1ChoixPaiement"  name="q1ChoixPaiement" [(ngModel)]="etape6.q1ChoixPaiement"  value="paiement_cb" (change)="applyMandatory()">
                  <label class="form-check-label" for="exampleRadios3">
                    Paiement en ligne par carte bancaire
                  </label>
                  <br/><span *ngIf="etape6.q1ChoixPaiement == 'paiement_cb'" > <i>* Le paiement en ligne sera à réaliser à la prochaine étape lors de la finalisation de votre inscription</i> </span>

                  


                </div>

               

              </div>
            </div>

                   
            

<!--
            <div class="form-row" *ngIf="etape6.q1ChoixPaiement == 'paiement_cb'">
              <div class="form-group col-md-12">
                <hr/>
                <div>
                  
                </div>
              </div>
            </div>
          -->
            <div class="form-row" *ngIf="etape6.q1ChoixPaiement == 'paiement_comptant'">
              <div class="form-group col-md-12">
                <hr/>
                <div>
                  <p>
                    Etablir le chèque <b>(de 1825€)</b> à l'ordre d'<b>IXAD Nord-Ouest</b> <br/><br/>
                    Indiquer vos nom et prénom au dos du chèque <br/>
                  </p>

                    Télécharger / Imprimer / Remplir <a target="_blank" href="/assets/pdfs/etape6/Inscrip_Frais_échelonnés_2025.pdf">ce document</a> qui sera à remettre au secrétariat accompagné de votre (ou de vos) chèques le jour de votre entretien d'inscription.
                </div>
              </div>
            </div>
          
            <div class="form-row" *ngIf="etape6.q1ChoixPaiement == 'paiement_echelonne'">
              <div class="form-group col-md-12">
                <hr/>
                <div>
                  <p>
                    Etablir 3 chèques à l'ordre d'<b>IXAD Nord-Ouest</b><br/>
                  </p>
                    <ul>
                    <li>Le premier d'un montant de 625 €</li>
                    <li>Les deux suivants d'un montant de 600 €</li>
                    </ul>
                    Indiquer vos nom et prénom au dos de chaque chèque<br/>
                   
                    <br/>
                    Télécharger / Imprimer / Remplir <a target="_blank" href="/assets/pdfs/etape6/Inscrip_Frais_échelonnés_2025.pdf">ce document</a> qui sera à remettre au secrétariat accompagné de votre (ou de vos) chèques le jour de votre entretien d'inscription.a
                </div>
              </div>
            </div>

            <div class="form-row" *ngIf="etape6.q1ChoixPaiement == 'paiement_aide'">
              <div class="form-group col-md-12">
                <hr/>
                <div>
                  <p>
                  Etablir 2 chèques à l'ordre d'<b>IXAD Nord-Ouest</b><br/>
                  </p>
                  <ul>
                    <li>Le premier d'un montant de 1225 €<span style="color:red">*</span></li>
                    <li>Le deuxième d'un montant de 600 €</li>
                    </ul>
                    Indiquer vos nom et prénom au dos de chaque chèque<br/>
                   
                    <br/>
                    Télécharger / Imprimer / Remplir <a target="_blank" href="/assets/pdfs/etape6/Inscrip_Frais_échelonnés_2025.pdf">ce document</a> qui sera à remettre au secrétariat accompagné de votre (ou de vos) chèques le jour de votre entretien d'inscription.
                    <br/><br/>
                    <span style="color:red">Le dossier complet est à déposer au secrétariat d’IXAD au plus tard le vendredi 10 janvier 2025 avec les documents suivants : </span>
                    <ul>
                      <li>le document « Dossier de demande d’aide financière » à <a target="_blank" href="/assets/pdfs/etape6/Dossier_Demande_aide_SCS_2025-2026.pdf">télécharger ici</a> </li>
                      <li>le document « Annexe aide financière » à <a target="_blank" href="/assets/pdfs/etape6/Annexe_à_compléter_obligatoirement_2025-2026_formulaire.pdf">télécharger ici</a> </li>
                      <li>le document « Notice d’aide financière » à <a target="_blank" href="/assets/pdfs/etape6/Notice_eleves_Demande_aide_SCS_2025-2026.pdf">télécharger ici</a></li>
                    </ul>
                    <i><span style="color:red">*</span>Si vous ne donnez pas suite à votre demande d’aide financière, le premier chèque de 1225 € sera encaissé immédiatement.</i>


                </div>
              </div>
            </div>
              

           

             <!-- Question 6 -->
            <hr/>
             <div class="form-row">
              <div class="form-group col-md-12">
                <p><b>Documents à envoyer pour finaliser votre inscription</b><br/>
                <i>Les documents demandés doivent être déposés au format PDF ou WORD.</i></p>
              </div>
             </div>
  
             <!--
             <div class="form-group">
              <label for="exampleFormControlFile2">Fiche d'engagement sur l'honneur à <a target="_blank" href="/assets/pdfs/etape6/Inscrip_Engagement_sur_honneur_2022.pdf">télécharger ici</a></label> <i style="color:red" *ngIf="etape6.nomFichierFicheEngagementHonneur == ''"> *   </i>
              <br/>
              <input type="file" #childFichierFicheEngagementHonneur class="form-control-file" id="exampleFormControlFile2" formControlName="nomFichierFicheEngagementHonneur"  name="etape6.nomFichierFicheEngagementHonneur" (change)="onFileChangeFichierFicheEngagementHonneur($event)" >
              Fichier envoyé : {{etape6.nomFichierFicheEngagementHonneur}} <img *ngIf="etape6.nomFichierFicheEngagementHonneur != ''" src="/assets/img/check.png" class="img-fluid" style="width:3%" alt="image"/>
              <span class="text-danger" *ngIf="etape6.nomFichierFicheEngagementHonneur == '' && etape6form.controls['nomFichierFicheEngagementHonneur'].hasError('required') && (etape6form.controls['nomFichierFicheEngagementHonneur'].dirty || etape6form.controls['nomFichierFicheEngagementHonneur'].touched)">&nbsp;&nbsp;* Champ obligatoire</span> 
            </div>
            -->
           
            <div class="form-group">
              <label for="exampleFormControlFile2">Document France Travail à <a target="_blank" href="/assets/pdfs/etape6/doc_ft_caf.pdf">télécharger ici</a> (en cas de demande auprès de l'organisme)</label>
              <input type="file" #childFichierFranceTravail class="form-control-file" id="exampleFormControlFile2" formControlName="nomFichierFranceTravail" name="etape6.nomFichierFranceTravail" (change)="onFileChangeFichierFranceTravail($event)" >
              Fichier envoyé : {{etape6.nomFichierFranceTravail}} <img *ngIf="etape6.nomFichierFranceTravail != ''" src="/assets/img/check.png" class="img-fluid" style="width:3%" alt="image"/>
            </div>

            <div class="form-group" *ngIf="!this.isDocteurDroit">
              <label for="exampleFormControlFile2">Photocopie de l'attestation de réussite à l'éxamen d'entrée</label> <i style="color:red" *ngIf="etape6.nomFichierReleveNotesExamen == '' || etape6.nomFichierAttestationReussiteExamen != ''"> *   </i>
              <input type="file" #childFichierAttestationReussiteExamen class="form-control-file" id="exampleFormControlFile2" formControlName="nomFichierAttestationReussiteExamen" name="etape6.nomFichierAttestationReussiteExamen" (change)="onFileChangeFichierAttestationReussiteExamen($event)" >
              Fichier envoyé : {{etape6.nomFichierAttestationReussiteExamen}} <img *ngIf="etape6.nomFichierAttestationReussiteExamen != ''" src="/assets/img/check.png" class="img-fluid" style="width:3%" alt="image"/>
              <span class="text-danger" *ngIf="etape6.nomFichierAttestationReussiteExamen == '' && etape6form.controls['nomFichierAttestationReussiteExamen'].hasError('required') && (etape6form.controls['nomFichierAttestationReussiteExamen'].dirty || etape6form.controls['nomFichierAttestationReussiteExamen'].touched)">&nbsp;&nbsp;* Champ obligatoire</span> 
           
              <br/><br/><span style="padding-left:20px"><b><i>Et/ou</i></b></span><br/>
            </div>
            
            <div class="form-group"  *ngIf="!this.isDocteurDroit">
              <label for="exampleFormControlFile2">Relevé de notes à l'examen d'entrée</label> <i style="color:red" *ngIf="etape6.nomFichierAttestationReussiteExamen == '' || etape6.nomFichierReleveNotesExamen != ''"> *   </i>
              <input type="file" #childFichierReleveNotesExamen class="form-control-file" id="exampleFormControlFile2" formControlName="nomFichierReleveNotesExamen" name="etape6.nomFichierReleveNotesExamen" (change)="onFileChangeFichierReleveNotesExamen($event)" >
              Fichier envoyé : {{etape6.nomFichierReleveNotesExamen}} <img *ngIf="etape6.nomFichierReleveNotesExamen != ''" src="/assets/img/check.png" class="img-fluid" style="width:3%" alt="image"/>
              <span class="text-danger" *ngIf="etape6.nomFichierReleveNotesExamen == '' && etape6form.controls['nomFichierReleveNotesExamen'].hasError('required') && (etape6form.controls['nomFichierReleveNotesExamen'].dirty || etape6form.controls['nomFichierReleveNotesExamen'].touched)">&nbsp;&nbsp;* Champ obligatoire</span> 
            </div>
            <hr/>
            <div class="form-group">
              <label for="exampleFormControlFile2">Attestation de votre assurance de Responsabilité Civile (à demander à votre assureur)</label> <i style="color:red" *ngIf="etape6.nomFichierAttestationAssurance == ''"> *   </i>
              <input type="file" #childFichierAttestationAssurance class="form-control-file" id="exampleFormControlFile2" formControlName="nomFichierAttestationAssurance" name="etape6.nomFichierAttestationAssurance" (change)="onFileChangeFichierAttestationAssurance($event)" >
              Fichier envoyé : {{etape6.nomFichierAttestationAssurance}} <img *ngIf="etape6.nomFichierAttestationAssurance != ''" src="/assets/img/check.png" class="img-fluid" style="width:3%" alt="image"/>
              <span class="text-danger" *ngIf="etape6.nomFichierAttestationAssurance == '' && etape6form.controls['nomFichierAttestationAssurance'].hasError('required') && (etape6form.controls['nomFichierAttestationAssurance'].dirty || etape6form.controls['nomFichierAttestationAssurance'].touched)">&nbsp;&nbsp;* Champ obligatoire</span> 
            </div>
            
            <hr/>
            <div class="form-group">
              <label for="exampleFormControlFile2">CV</label> <i style="color:red" *ngIf="etape6.nomFichierCV == ''"> * </i>
              <input type="file" #childFichierCV class="form-control-file" id="exampleFormControlFile2" formControlName="nomFichierCV" name="etape6.nomFichierCV" (change)="onFileChangeFichierCV($event)" >
              Fichier envoyé : {{etape6.nomFichierCV}} <img *ngIf="etape6.nomFichierCV != ''" src="/assets/img/check.png" class="img-fluid" style="width:3%" alt="image"/>
              <span class="text-danger" *ngIf="etape6.nomFichierCV == '' && etape6form.controls['nomFichierCV'].hasError('required') && (etape6form.controls['nomFichierCV'].dirty || etape6form.controls['nomFichierCV'].touched)">&nbsp;&nbsp;* Champ obligatoire</span> 
            </div>
            <hr/>
            <div class="form-group">
              <label for="exampleFormControlFile2">Droit à l'image à <a target="_blank" href="/assets/pdfs/etape1/Inscript_Droit_à_Image.pdf">télécharger ici</a></label> <i style="color:red" *ngIf="etape6.nomFichierDroitImage == ''"> *   </i>
              <br/>
              <input type="file" #childFichierDroitImage class="form-control-file" id="exampleFormControlFile2" formControlName="nomFichierDroitImage" name="etape6.nomFichierDroitImage" (change)="onFileChangeFichierDroitImage($event)" >
              Fichier envoyé : {{etape6.nomFichierDroitImage}} <img *ngIf="etape6.nomFichierDroitImage != ''" src="/assets/img/check.png" class="img-fluid" style="width:3%" alt="image"/>
              <span class="text-danger" *ngIf="etape6.nomFichierDroitImage == '' && etape6form.controls['nomFichierDroitImage'].hasError('required') && (etape6form.controls['nomFichierDroitImage'].dirty || etape6form.controls['nomFichierDroitImage'].touched)">&nbsp;&nbsp;* Champ obligatoire</span> 
            </div>
            <hr/>
            <div class="form-group">
              <label for="exampleFormControlFile2">Charte de confidentialité Bases de données à <a target="_blank" href="/assets/pdfs/etape6/CHARTE-CONFIDENTIALITE.pdf">télécharger ici</a></label> <i style="color:red" *ngIf="etape6.nomFichierCharteConfidentialite == ''"> *   </i>
              <br/>
              <input type="file" #childFichierCharteConfidentialite class="form-control-file" id="exampleFormControlFile2" formControlName="nomFichierCharteConfidentialite" name="etape6.nomFichierCharteConfidentialite" (change)="onFileChangeFichierCharteConfidentialite($event)" >
              Fichier envoyé : {{etape6.nomFichierCharteConfidentialite}} <img *ngIf="etape6.nomFichierCharteConfidentialite != ''" src="/assets/img/check.png" class="img-fluid" style="width:3%" alt="image"/>
              <span class="text-danger" *ngIf="etape6.nomFichierCharteConfidentialite == '' && etape6form.controls['nomFichierCharteConfidentialite'].hasError('required') && (etape6form.controls['nomFichierCharteConfidentialite'].dirty || etape6form.controls['nomFichierCharteConfidentialite'].touched)">&nbsp;&nbsp;* Champ obligatoire</span> 
            </div>

            <hr *ngIf="etape6.q1ChoixPaiement == 'paiement_aide'"/>

            <div class="form-group"  *ngIf="etape6.q1ChoixPaiement == 'paiement_aide'">
              <label for="exampleFormControlFile2">Relevé d'Identité Bancaire (RIB)</label> <i style="color:red" *ngIf="etape6.nomFichierRIB == '' || etape6.nomFichierRIB != ''"> *   </i>
              <input type="file" #childFichierRIB class="form-control-file" id="exampleFormControlFile2" formControlName="nomFichierRIB" name="etape6.nomFichierRIB" (change)="onFileChangeFichierRIB($event)" >
              Fichier envoyé : {{etape6.nomFichierRIB}} <img *ngIf="etape6.nomFichierRIB != ''" src="/assets/img/check.png" class="img-fluid" style="width:3%" alt="image"/>
              <span class="text-danger" *ngIf="etape6.nomFichierRIB == '' && etape6form.controls['nomFichierRIB'].hasError('required') && (etape6form.controls['nomFichierRIB'].dirty || etape6form.controls['nomFichierRIB'].touched)">&nbsp;&nbsp;* Champ obligatoire</span> 
            </div>
            <hr/>
         
  
      
            <br/><h6 style="color:red;" *ngIf="isInscrit">VOTRE PRE-INSCRIPTION A BIEN ETE PRISE EN COMPTE, IXAD REVIENDRA VERS VOUS PAR EMAIL.</h6>


            <br/><br/>
            <div class="form-row" *ngIf="!isInscrit">
              <div class="form-group col-md-6 text-right">
                <img src="/assets/img/loader-orange.gif" style="width:40px;" *ngIf="loading2" />
              </div>
              <div class="form-group col-md-6 text-left">
                
                <input type="button" class="btn btn-primary" (click)="next()"   value="Suivant">
                
              </div>
            </div>
                        
           
        </div>
      </div>
    </div>
  </div>