import { Injectable, NgZone } from '@angular/core';
import { Router } from  "@angular/router";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Formulaire } from '../../model/formulaire';
import { throwError } from 'rxjs';
import { Etape1 } from 'src/app/model/formulaire/etape1';
import { Retour } from 'src/app/model/retour';
import { Etape2 } from 'src/app/model/formulaire/etape2';
import { Etape3 } from 'src/app/model/formulaire/etape3';
import { Etape4 } from 'src/app/model/formulaire/etape4';
import { flatMap } from 'rxjs/operators';

import { Observable, } from 'rxjs';
import 'rxjs/add/observable/fromPromise';
import { map } from 'rxjs/operators';
import 'rxjs/add/operator/mergeMap'
import * as firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { Etape6 } from 'src/app/model/formulaire/etape6';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FormulaireService {


  public docteurDroit:boolean=false;

  public formulaire:Formulaire = new Formulaire();

  constructor(private httpClient: HttpClient, public  afAuth:  AngularFireAuth, public  router:  Router,  public ngZone: NgZone) { 
  
  }


  getToken() : Promise<string>{
    return new Promise((resolve, reject) => {
      this.afAuth.onAuthStateChanged(function(user) {
      if (user) {
        user.getIdToken().then(function(data) {
          resolve(data);
        }).catch(reject);
      } else {
        reject('Error while executing onAuthStateChanged');
      }
      });
    });
  }
  
  
  loadEtape1(uid:string){

    return Observable.fromPromise(this.getToken()).mergeMap(token => {
     
      const httpOptions = {
        headers: new HttpHeaders({
          'X-Firebase-Auth':  token
        })
      };
     
      return this.httpClient.get(environment.getDateEtape1ForUserUrl+uid, httpOptions);
    });

  }



  setDateEtape1(etape1: Etape1){

    return Observable.fromPromise(this.getToken()).mergeMap(token => {
     
      const httpOptions = {
        headers: new HttpHeaders({
          'X-Firebase-Auth':  token
        })
      };
      const user  =  JSON.parse(localStorage.getItem('user'));
      return this.httpClient.post<Etape1>(environment.setDateEtape1ForUserUrl+user.uid, etape1, httpOptions);
    });

  }


  loadEtape2(uid:string)
  {
    return Observable.fromPromise(this.getToken()).mergeMap(token => {
     
      const httpOptions = {
        headers: new HttpHeaders({
          'X-Firebase-Auth':  token
        })
      };
     
      return this.httpClient.get(environment.getDateEtape2ForUserUrl+uid, httpOptions);
    });
    
  }

  setDateEtape2(etape2: Etape2){

    return Observable.fromPromise(this.getToken()).mergeMap(token => {
     
      const httpOptions = {
        headers: new HttpHeaders({
          'X-Firebase-Auth':  token
        })
      };
      const user  =  JSON.parse(localStorage.getItem('user'));
      return this.httpClient.post<Etape2>(environment.setDateEtape2ForUserUrl+user.uid, etape2, httpOptions);
    });

  }



  loadEtape3(uid:string)
  {
    return Observable.fromPromise(this.getToken()).mergeMap(token => {
     
      const httpOptions = {
        headers: new HttpHeaders({
          'X-Firebase-Auth':  token
        })
      };
     
      return this.httpClient.get(environment.getDateEtape3ForUserUrl+uid, httpOptions);
    });
  }

  setDateEtape3(etape3: Etape3){
    return Observable.fromPromise(this.getToken()).mergeMap(token => {
     
      const httpOptions = {
        headers: new HttpHeaders({
          'X-Firebase-Auth':  token
        })
      };
      const user  =  JSON.parse(localStorage.getItem('user'));
      return this.httpClient.post<Etape3>(environment.setDateEtape3ForUserUrl+user.uid, etape3, httpOptions);
    });

  }

  loadEtape4(uid:string)
  {
    return Observable.fromPromise(this.getToken()).mergeMap(token => {
     
      const httpOptions = {
        headers: new HttpHeaders({
          'X-Firebase-Auth':  token
        })
      };
     
      return this.httpClient.get(environment.getDateEtape4ForUserUrl+uid, httpOptions);
    });
  }

  setDataEtape4(etape4:Etape4, files:File[]){

    return Observable.fromPromise(this.getToken()).mergeMap(token => {
     
      const httpOptions = {
        headers: new HttpHeaders({
          'X-Firebase-Auth':  token
        })
      };

      const formData = new FormData();

    
      formData.append('fichierDocPieceIdentite', files[0]);
      formData.append('fichierDiplomeDocteurDroit', files[1]);
      formData.append('fichierAttestationSoutenanceEcoleDoctorat', files[2]);
      formData.append('fichierThese', files[3]);
      formData.append('fichierDiplomeMaster1Maitrise', files[4]);
      formData.append('fichierDiplomeMaster2Maitrise', files[5]);
      formData.append('fichierNationalite', files[6]);
      //formData.append('fichierAutorisationCumulActiviteExt', files[7]);
      formData.append('fichierEngagementActiviteExt', files[7]);
     
     
      
      
      
     
      formData.append("etape4", new Blob([JSON.stringify(etape4)], {
        type: "application/json"
      }));
      const user  =  JSON.parse(localStorage.getItem('user'));
      return this.httpClient.post(environment.setDateEtape4ForUserUrl+user.uid, formData, httpOptions);
    });

  }

  setDataEtape5(){
    return Observable.fromPromise(this.getToken()).mergeMap(token => {
     
      const httpOptions = {
        headers: new HttpHeaders({
          'X-Firebase-Auth':  token
        })
      };
      const user  =  JSON.parse(localStorage.getItem('user'));
      return this.httpClient.post(environment.setDateEtape5ForUserUrl+user.uid, "", httpOptions);
      
    });
  }

  loadEtape6(uid:string)
  {
    return Observable.fromPromise(this.getToken()).mergeMap(token => {
     
      const httpOptions = {
        headers: new HttpHeaders({
          'X-Firebase-Auth':  token
        })
      };
     
      return this.httpClient.get(environment.getDateEtape6ForUserUrl+uid, httpOptions);
    });
  }

  setDataEtape6(etape6:Etape6, files:File[]){

    return Observable.fromPromise(this.getToken()).mergeMap(token => {
     
      const httpOptions = {
        headers: new HttpHeaders({
          'X-Firebase-Auth':  token
        })
      };

      const formData = new FormData();

    
      //formData.append('fichierFicheEngagementHonneur', files[0]);
      formData.append('fichierAttestationReussiteExamen', files[0]);
      formData.append('fichierReleveNotesExamen', files[1]);
      formData.append('fichierAttestationAssurance', files[2]);
      formData.append('fichierCV', files[3]);
      formData.append('fichierDroitImage', files[4]);
      formData.append('fichierRIB', files[5]);
      formData.append('fichierCharteConfidentialite', files[6]);
      formData.append('fichierFranceTravail', files[7]);
     
      
   
      formData.append("etape6", new Blob([JSON.stringify(etape6)], {
        type: "application/json"
      }));;
      const user  =  JSON.parse(localStorage.getItem('user'));
      return this.httpClient.post(environment.setDateEtape6ForUserUrl+user.uid, formData, httpOptions);
    });
  }

  finaliseInscription(){
    return Observable.fromPromise(this.getToken()).mergeMap(token => {
     
      const httpOptions = {
        headers: new HttpHeaders({
          'X-Firebase-Auth':  token
        })
      };
      const user  =  JSON.parse(localStorage.getItem('user'));
      return this.httpClient.post(environment.setFinaliseInscription+user.uid, "", httpOptions);
      
    });
  }

  defineIsDocteurDroit(uid:string)
  {
    return Observable.fromPromise(this.getToken()).mergeMap(token => {
     
      const httpOptions = {
        headers: new HttpHeaders({
          'X-Firebase-Auth':  token
        })
      };
     
      return this.httpClient.get(environment.getIsDocteurDroitUrl+uid, httpOptions);
    });
    
  }

  defineIsPreInscrit(uid:string)
  {
    
    return Observable.fromPromise(this.getToken()).mergeMap(token => {
     
      const httpOptions = {
        headers: new HttpHeaders({
          'X-Firebase-Auth':  token
        })
      };
     
      return this.httpClient.get(environment.getIsPreInscritUrl+uid, httpOptions);
    });
    
  }
  defineIsInscrit(uid:string)
  {
    
    return Observable.fromPromise(this.getToken()).mergeMap(token => {
     
      const httpOptions = {
        headers: new HttpHeaders({
          'X-Firebase-Auth':  token
        })
      };
     
      return this.httpClient.get(environment.getIsInscritUrl+uid, httpOptions);
    });
    
  }

  defineHasActiviteExt(uid:string)
  {
    return Observable.fromPromise(this.getToken()).mergeMap(token => {
     
      const httpOptions = {
        headers: new HttpHeaders({
          'X-Firebase-Auth':  token
        })
      };
     
      return this.httpClient.get(environment.getActiviteExtUrl+uid, httpOptions);
    });
   
  }

  defineInscriptionValideIxad(uid:string)
  {
    return Observable.fromPromise(this.getToken()).mergeMap(token => {
     
      const httpOptions = {
        headers: new HttpHeaders({
          'X-Firebase-Auth':  token
        })
      };
     
      return this.httpClient.get(environment.getPreInscriptionValideIxad+uid, httpOptions);
    });
   
  }

  checkPaiem(uid: any) {
    return Observable.fromPromise(this.getToken()).mergeMap(token => {
     
      const httpOptions = {
        headers: new HttpHeaders({
          'X-Firebase-Auth':  token
        })
      };
     
      return this.httpClient.get(environment.getCheckPaiemURL+uid, httpOptions);
    });
  }


}
