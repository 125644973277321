<div class="container contact" [formGroup]="etape3form">
    <div class="row">
      <div class="col-md-3 customMD3">
        <div class="contact-info">
          <span class="pi pi-sign-out" style="color:white"></span><a class="linkDisconnect" href="#" (click)="authService.logout()">Se déconnecter</a>
          <a routerLink="/formulaire/etape1"><img src="/assets/img/logo-ixad.png" class="img-fluid" alt="image"/></a>
          <h2 style="color:white" class="anneeStyle">INSCRIPTION {{ authService.anneeInscription1 }} / {{ authService.anneeInscription2 }}</h2>
          <br/><br/><br/><i style="color:white">Pour toute question ou problème technique lors du remplissage du formulaire, merci d'envoyer un mail à <a href="mailto:inscription@ixad.fr">inscription@ixad.fr</a></i>
          <h4></h4>
        </div>
      </div>
      <div class="col-md-9" *ngIf="!loadedService1"><img src="/assets/img/loader-orange.gif" style="width:100px"/></div>
      <div class="col-md-9" *ngIf="loadedService1">
        
        <h4>Etape 3 sur 5 - votre parcours</h4><br/>
        <p>Complétez votre parcours ci-dessous.</p><br/>
        <div class="contact-form">


          <h5>Baccalauréat<span style="color:red"> *</span></h5>
          <div class="form-row" style="margin-top:20px"> 
            <div class="form-group col-md-3 my-auto">
              <label for="sel1" >Série : </label> 
            </div>
            <div class="form-group col-md-4 my-auto">
              <select class="form-control align-middle" id="rotit" formControlName="q1BacSerie" [(ngModel)]="etape3.q1BacSerie" (change)="rdgControlForm()">
                <option value="">-- Choisir --</option>
                <option *ngFor="let bacOption of referentielService.bacFiliere" [ngValue]="bacOption">{{bacOption}}</option>
              </select>
            </div>
            <div class="form-group col-md-5 my-auto">
              <span class="text-danger" *ngIf="etape3form.controls['q1BacSerie'].hasError('required') && (etape3form.controls['q1BacSerie'].dirty || etape3form.controls['q1BacSerie'].touched)" >* Champ obligatoire</span>  
            </div>
          </div>
          <div class="form-row" style="margin-top:20px" *ngIf="etape3.q1BacSerie == 'Autre'"> 
            <div class="form-group col-md-3 my-auto" >
              <label for="sel1">Précisez : </label>      
            </div>
            <div class="form-group col-md-4 my-auto">
              <input type="text" class="form-control" id="inputEmail4"  placeholder="Autre" formControlName="q1BacAutre" [(ngModel)]="etape3.q1BacAutre">
            </div>
            <div class="form-group col-md-5 my-auto">
              <span class="text-danger" *ngIf="etape3form.controls['q1BacAutre'].hasError('required') && (etape3form.controls['q1BacAutre'].dirty || etape3form.controls['q1BacAutre'].touched)" >* Champ obligatoire</span>  
            </div>
          </div>
          <div class="form-row" style="margin-top:20px"> 
            <div class="form-group col-md-3 my-auto">
              <label for="sel1">Année : </label>         
            </div>
            <div class="form-group col-md-4 my-auto">
              <select class="form-control" id="rotit" formControlName="q1BacAnnee" [(ngModel)]="etape3.q1BacAnnee">
                <option value="">-- Choisir --</option>
                <option *ngFor="let year of referentielService.annees" [ngValue]="year">{{year}}</option>
              </select>
            </div>
            <div class="form-group col-md-5 my-auto">
              <span class="text-danger" *ngIf="etape3form.controls['q1BacAnnee'].hasError('required') && (etape3form.controls['q1BacAnnee'].dirty || etape3form.controls['q1BacAnnee'].touched)">* Champ obligatoire</span>  
            </div>
          </div>
         
         <hr/>



         <h5>3ème année de licence<span style="color:red"> *</span></h5><br/>
         <div class="form-row" style="margin-top:20px"> 
          <div class="form-group col-md-3 my-auto" >
            <label for="sel1">Filière : </label>
          </div>
          <div class="form-group col-md-4 my-auto">
            <select class="form-control" id="rotit" formControlName="q2Annee3LicenceSpecialisation" [(ngModel)]="etape3.q2Annee3LicenceSpecialisation" (change)="rdgControlForm()">
              <option value="">-- Choisir --</option>
              <option *ngFor="let licenceOption of referentielService.licenceFiliere" [ngValue]="licenceOption">{{licenceOption}}</option>
            </select>
          </div>
          <div class="form-group col-md-5 my-auto">
            <span class="text-danger" *ngIf="etape3form.controls['q2Annee3LicenceSpecialisation'].hasError('required') && (etape3form.controls['q2Annee3LicenceSpecialisation'].dirty || etape3form.controls['q2Annee3LicenceSpecialisation'].touched)" >* Champ obligatoire</span>  
          </div>
        </div>
        <div class="form-row" style="margin-top:20px"  *ngIf="etape3.q2Annee3LicenceSpecialisation == 'Autre'"> 
          <div class="form-group col-md-3 my-auto" >
            <label for="sel1">Précisez : </label>      
          </div>
          <div class="form-group col-md-4 my-auto">
            <input type="text" class="form-control" id="inputEmail4"  placeholder="Autre" formControlName="q2Annee3LicenceAutre" [(ngModel)]="etape3.q2Annee3LicenceAutre"> 
          </div>
          <div class="form-group col-md-5 my-auto">
            <span class="text-danger" *ngIf="etape3form.controls['q2Annee3LicenceAutre'].hasError('required') && (etape3form.controls['q2Annee3LicenceAutre'].dirty || etape3form.controls['q2Annee3LicenceAutre'].touched)" >* Champ obligatoire</span>  
          </div>
        </div>
        <div class="form-row" style="margin-top:20px"> 
          <div class="form-group col-md-3 my-auto" >
            <label for="sel1">Année : </label>
          </div>
          <div class="form-group col-md-4 my-auto">
            <select class="form-control" id="rotit" formControlName="q2Annee3LicenceAnnee" [(ngModel)]="etape3.q2Annee3LicenceAnnee">
              <option value="">-- Choisir --</option>
              <option *ngFor="let year of referentielService.annees" [ngValue]="year">{{year}}</option>
            </select>
          </div>
          <div class="form-group col-md-5 my-auto">
            <span class="text-danger" *ngIf="etape3form.controls['q2Annee3LicenceAnnee'].hasError('required') && (etape3form.controls['q2Annee3LicenceAnnee'].dirty || etape3form.controls['q2Annee3LicenceAnnee'].touched)" >* Champ obligatoire</span>  
          </div>
        </div>
        
        <hr/>



            <h5> Master 1<span style="color:red"> *</span></h5><br/> 
            <div class="form-row" style="margin-top:20px"> 
              <div class="form-group col-md-3 my-auto" >
                <label for="sel1">Filière : </label>   
              </div>
              <div class="form-group col-md-4 my-auto">
                <select class="form-control" id="rotit" formControlName="q3master1specialisation" [(ngModel)]="etape3.q3master1specialisation" (change)="rdgControlForm()">
                  <option value="">-- Choisir --</option>
                  <option *ngFor="let masterOption of referentielService.masterFiliere" [ngValue]="masterOption">{{masterOption}}</option>
              </select>
              </div>
              <div class="form-group col-md-5 my-auto">
                <span class="text-danger" *ngIf="etape3form.controls['q3master1specialisation'].hasError('required') && (etape3form.controls['q3master1specialisation'].dirty || etape3form.controls['q3master1specialisation'].touched)" >* Champ obligatoire</span>  
              </div>
            </div>
            <div class="form-row" style="margin-top:20px" *ngIf="etape3.q3master1specialisation == 'Autre'"> 
              <div class="form-group col-md-3 my-auto" >
                <label for="sel1">Précisez : </label>      
              </div>
              <div class="form-group col-md-4 my-auto">
                <input type="text" class="form-control" id="inputEmail4"  placeholder="Autre" formControlName="q3master1Autre" [(ngModel)]="etape3.q3master1Autre">
              </div>
              <div class="form-group col-md-5 my-auto">
                <span class="text-danger" *ngIf="etape3form.controls['q3master1Autre'].hasError('required') && (etape3form.controls['q3master1Autre'].dirty || etape3form.controls['q3master1Autre'].touched)">* Champ obligatoire</span>  
              </div>
            </div>
            <div class="form-row" style="margin-top:20px"> 
              <div class="form-group col-md-3 my-auto" >
                <label for="sel1">Année : </label>
              </div>
              <div class="form-group col-md-4 my-auto">
                <select class="form-control" id="rotit" formControlName="q3master1Annee" [(ngModel)]="etape3.q3master1Annee">
                  <option value="">-- Choisir --</option>
                  <option *ngFor="let year of referentielService.annees" [ngValue]="year">{{year}}</option>
                </select>
              </div>
              <div class="form-group col-md-5 my-auto">
                <span class="text-danger" *ngIf="etape3form.controls['q3master1Annee'].hasError('required') && (etape3form.controls['q3master1Annee'].dirty || etape3form.controls['q3master1Annee'].touched)" >* Champ obligatoire</span>  
              </div>
            </div>
           
            <hr/>


              <h5> Master 2 <span  *ngIf="isDocteurDroit || (etape3.q4master2Annee || etape3.q4master2specialisation || etape3.q4master2Autre)" style="color:red"> *</span></h5><br/> 

              <div class="form-row" style="margin-top:20px"> 
                <div class="form-group col-md-3 my-auto" >
                  <label for="sel1">Filière : </label>   
                </div>
                <div class="form-group col-md-4 my-auto">
                  <select class="form-control" id="rotit" formControlName="q4master2specialisation" [(ngModel)]="etape3.q4master2specialisation" (change)="rdgControlForm()">
                    <option value="">-- Choisir --</option>
                    <option *ngFor="let masterOption of referentielService.masterFiliere" [ngValue]="masterOption">{{masterOption}}</option>
              </select>
                </div>
                <div class="form-group col-md-5 my-auto">
                  <span class="text-danger" *ngIf="etape3form.controls['q4master2specialisation'].hasError('required') && (etape3form.controls['q4master2specialisation'].dirty || etape3form.controls['q4master2specialisation'].touched)" >* Champ obligatoire</span>  
                </div>
              </div>
              <div class="form-row" style="margin-top:20px" *ngIf="etape3.q4master2specialisation == 'Autre'">
                <div class="form-group col-md-3 my-auto" >
                  <label for="sel1">Précisez : </label>      
                </div>
                <div class="form-group col-md-4 my-auto">
                  <input type="text" class="form-control" id="inputEmail4"  placeholder="Autre" formControlName="q4master2Autre" [(ngModel)]="etape3.q4master2Autre">
                </div>
                <div class="form-group col-md-5 my-auto">
                  <span class="text-danger" *ngIf="etape3form.controls['q4master2Autre'].hasError('required') && (etape3form.controls['q4master2Autre'].dirty || etape3form.controls['q4master2Autre'].touched)">* Champ obligatoire</span>  
                </div>
              </div>
              <div class="form-row" style="margin-top:20px"> 
                <div class="form-group col-md-3 my-auto" >
                  <label for="sel1">Année : </label>
                </div>
                <div class="form-group col-md-4 my-auto">
                  <select class="form-control" id="rotit" formControlName="q4master2Annee" [(ngModel)]="etape3.q4master2Annee">
                    <option value="">-- Choisir --</option>
                    <option *ngFor="let year of referentielService.annees" [ngValue]="year">{{year}}</option>
                  </select>
                </div>
                <div class="form-group col-md-5 my-auto">
                  <span class="text-danger" *ngIf="etape3form.controls['q4master2Annee'].hasError('required') && (etape3form.controls['q4master2Annee'].dirty || etape3form.controls['q4master2Annee'].touched)">* Champ obligatoire</span>  
                </div>
              </div>
   
              <hr/>

                  <h5> Autre </h5><br/>  


                  <div class="form-row" style="margin-top:20px"> 
                    <div class="form-group col-md-3 my-auto" >
                      <label for="inputEmail4">Diplôme : </label> 
                    </div>
                    <div class="form-group col-md-4 my-auto">
                      <input type="text" class="form-control" id="inputEmail4" placeholder="Diplôme" formControlName="q5autreDiplome" [(ngModel)]="etape3.q5autreDiplome" (change)="rdgControlForm()">
                    </div>
                    <div class="form-group col-md-5 my-auto">
                      <span class="text-danger" *ngIf="etape3form.controls['q5autreDiplome'].hasError('required') && (etape3form.controls['q5autreDiplome'].dirty || etape3form.controls['q5autreDiplome'].touched)" >* Champ obligatoire</span> 
                    </div>
                  </div>
                  <div class="form-row" style="margin-top:20px"> 
                    <div class="form-group col-md-3 my-auto" >
                      <label for="exampleFormControlSelect1">Année :</label>   
                    </div>
                    <div class="form-group col-md-4 my-auto">
                      <select class="form-control" id="exampleFormControlSelect1" formControlName="q5autreAnnee" [(ngModel)]="etape3.q5autreAnnee" (change)="rdgControlForm()">
                        <option value="">-- Choisir --</option>
                        <option *ngFor="let year of referentielService.annees" [ngValue]="year">{{year}}</option>
                      </select>
                    </div>
                    <div class="form-group col-md-5 my-auto">
                      <span class="text-danger" *ngIf="etape3form.controls['q5autreAnnee'].hasError('required') && (etape3form.controls['q5autreAnnee'].dirty || etape3form.controls['q5autreAnnee'].touched)" >* Champ obligatoire</span>
                    </div>
                  </div>
                  <div class="form-row" style="margin-top:20px"> 
                    <div class="form-group col-md-3 my-auto" >
                      <label for="inputEmail4">Filière : </label>  
                    </div>
                    <div class="form-group col-md-4 my-auto">
                      <input type="text" class="form-control" id="inputEmail4" placeholder="Filière" formControlName="q5autreSpecialisation" [(ngModel)]="etape3.q5autreSpecialisation" (change)="rdgControlForm()">
                    </div>
                    <div class="form-group col-md-5 my-auto">
                      <span class="text-danger" *ngIf="etape3form.controls['q5autreSpecialisation'].hasError('required') && (etape3form.controls['q5autreSpecialisation'].dirty || etape3form.controls['q5autreSpecialisation'].touched)" >* Champ obligatoire</span>
                    </div>
                  </div>

                  <h6 style="color:red;" *ngIf="isPreInscrit"><br/><br/>Votre pré-inscription est envoyée, vos modifications ne seront pas enregistrées</h6>

                  <br/><br/>
                  <div class="form-row">
                    <div class="form-group col-md-6 text-right">
                      <img src="/assets/img/loader-orange.gif" style="width:40px;" *ngIf="loading2" />
                      <input type="button" class="btn btn-primary" (click)="previous()" value="Précédent">
                    </div>
                    <div class="form-group col-md-6 text-left">
                      <input  type="button" class="btn btn-primary"  (click)="next()" value="Suivant">
                    </div>
                  </div>
        </div>
      </div>
    </div>
  </div>
  