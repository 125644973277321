import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { VerifyEmailComponent } from './pages/verify-email/verify-email.component';
import { AppComponent } from './app.component';
import { Etape1Component } from './pages/form-inscription/etape1/etape1.component';
import { Etape2Component } from './pages/form-inscription/etape2/etape2.component';
import { Etape3Component } from './pages/form-inscription/etape3/etape3.component';
import { Etape4Component } from './pages/form-inscription/etape4/etape4.component';
import { Etape5Component } from './pages/form-inscription/etape5/etape5.component';
import {AuthGuard} from './guard/auth.guard';
import {ClosedInscriptionGuard} from './guard/closed-inscription.guard';
import { Etape6Component } from './pages/form-inscription/etape6/etape6.component';
import { PrevalidComponent } from './pages/form-inscription/prevalid/prevalid.component';
import { ValidComponent } from './pages/form-inscription/valid/valid.component';
import { RgpdComponent } from './pages/form-inscription/rgpd/rgpd.component';
import { CnbComponent } from './pages/form-inscription/cnb/cnb.component';
import { CnbInscriptionComponent } from './pages/form-inscription/cnb-inscription/cnb-inscription.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path:  'login',component:  LoginComponent },
  { path:  'register', component:  RegisterComponent, canActivate: [ClosedInscriptionGuard] },
  { path:  'forgot-password', component:  ForgotPasswordComponent},
  { path:  'verify-email', component:  VerifyEmailComponent, canActivate: []},
  { path: 'formulaire', canActivate: [AuthGuard],
    component: AppComponent, // this is the component with the <router-outlet> in the template
    children: [
      {
        path: '', redirectTo: '/formulaire/etape1', pathMatch: 'full', canActivate: [AuthGuard]
      },
      {
        path: 'etape1', // child route path
        component: Etape1Component , canActivate: [AuthGuard] // child route component that the router renders
      },
      {
        path: 'etape2',
        component: Etape2Component , canActivate: [AuthGuard]
      },
      {
        path: 'etape3',
        component: Etape3Component , canActivate: [AuthGuard]
      },
      {
        path: 'etape4',
        component: Etape4Component , canActivate: [AuthGuard]
      },
      {
        path: 'etape5',
        component: Etape5Component , canActivate: [AuthGuard]
      },
      {
        path: 'etape6',
        component: Etape6Component , canActivate: [AuthGuard]
      },
      {
        path: 'prevalid',
        component: PrevalidComponent , canActivate: [AuthGuard]
      },
      {
        path: 'valid',
        component: ValidComponent , canActivate: [AuthGuard]
      },
      {
        path: 'rgpd',
        component: RgpdComponent , canActivate: [AuthGuard]
      },
      {
        path: 'noticeCnb',
        component: CnbComponent , canActivate: [AuthGuard]
      },
      {
        path: 'noticeCnbInscription',
        component: CnbInscriptionComponent , canActivate: [AuthGuard]
      }

      
    ] },
    { path: '**', redirectTo: 'login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
