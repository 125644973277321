<p style="color:white; padding:20px">
Les informations recueillies par l’IXAD en qualité de responsable de traitement
font l’objet d’un traitement aux fins de la gestion des inscriptions à l’École des
Avocats.<br/>
Ce traitement est fondé sur l’exécution d’une mission d’intérêt public. <br/> 
Vos données seront conservées pendant la durée nécessaire à la réalisation de la
finalité, en conformité avec les textes législatifs et règlementaires applicables. <br/> 
Ces informations sont destinées aux services habilités de l’IXAD et pourront être
communiquées au Conseil national des barreaux.<br/>
Conformément au Règlement (UE) 2016/679 du Parlement européen et du Conseil
du 27 avril 2016, relatif à la protection des personnes physiques à l'égard du
traitement des données à caractère personnel et à la libre circulation de ces
données (RGPD) et à la loi no 78-17 du 6 janvier 1978 relative à l'informatique, aux
fichiers et aux libertés (Loi « Informatique et Libertés ») modifiée, vous disposez
des droits d’accès, de rectification, d’effacement et de portabilité (lorsqu’il
s’applique) à l’égard des données vous concernant, ainsi que de limitation et
d’opposition pour motifs légitimes à leur traitement. <br/>
Vous pouvez les exercer en contactant le Délégué à la Protection des Données à l’adresse dpo@ixad.fr ou à
l’adresse postale : 1 place Déliot, 59000 – Lille. <br/>
Vous disposez également du droit de déposer, à tout moment, une réclamation auprès de la CNIL (Commission
Nationale de l’Informatique et des Libertés).<br/><br/>
<a target="_blank"  href="https://www.ixad.fr/protection-des-donnees-personnelles">En savoir plus sur la protection des données personnelles</a>
</p>