import { Serializable } from '../serializable';

export class Etape3 extends Serializable {

    constructor()
    {
        super();
        this.idInscription = null;
        this.q1BacSerie = "";
        this.q1BacAnnee = "";
        this.q1BacAutre = "";
    
        this.q2Annee3LicenceSpecialisation = "";
        this.q2Annee3LicenceAnnee = "";
        this.q2Annee3LicenceAutre = "";
    
        this.q3master1specialisation = "";
        this.q3master1Annee = "";
        this.q3master1Autre = "";
    
        this.q4master2specialisation = "";
        this.q4master2Annee = "";
        this.q4master2Autre = "";
    
        this.q5autreDiplome = "";
        this.q5autreAnnee = "";
        this.q5autreSpecialisation = "";
        this.uid = "";

    }
    idInscription:Number;
    q1BacSerie:string;
    q1BacAnnee:string;
    q1BacAutre:string;

    q2Annee3LicenceSpecialisation:string;
    q2Annee3LicenceAnnee:string;
    q2Annee3LicenceAutre:string;

    q3master1specialisation:string;
    q3master1Annee:string;
    q3master1Autre:string;

    q4master2specialisation:string;
    q4master2Annee:string;
    q4master2Autre:string;

    q5autreDiplome:string;
    q5autreAnnee:string;
    q5autreSpecialisation:string;
    
    uid:string;
}
