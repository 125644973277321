
import { NgModule } from '@angular/core';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {CalendarModule} from 'primeng/calendar';
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { VerifyEmailComponent } from './pages/verify-email/verify-email.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Etape1Component } from './pages/form-inscription/etape1/etape1.component';
import { Etape2Component } from './pages/form-inscription/etape2/etape2.component';
import { Etape3Component } from './pages/form-inscription/etape3/etape3.component';
import { Etape4Component } from './pages/form-inscription/etape4/etape4.component';
import { Etape5Component } from './pages/form-inscription/etape5/etape5.component'; 
import { HttpClientModule } from '@angular/common/http';
import { FormulaireService } from './services/formulaire/formulaire.service';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ButtonModule } from 'primeng/button';
import { DatePipe } from '@angular/common';
import {AccordionModule} from 'primeng/accordion';
import { TextMaskModule } from 'angular2-text-mask';
import { ScrollingModule } from '@angular/cdk/scrolling';
import {DropdownModule} from 'primeng/dropdown';
import { Etape6Component } from './pages/form-inscription/etape6/etape6.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { PrevalidComponent } from './pages/form-inscription/prevalid/prevalid.component';
import { ValidComponent } from './pages/form-inscription/valid/valid.component';
import { RgpdComponent } from './pages/form-inscription/rgpd/rgpd.component';
import { CnbComponent } from './pages/form-inscription/cnb/cnb.component';
import { CnbInscriptionComponent } from './pages/form-inscription/cnb-inscription/cnb-inscription.component';

import { environment } from '../environments/environment';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    VerifyEmailComponent,
    Etape1Component,
    Etape2Component,
    Etape3Component,
    Etape4Component,
    Etape5Component,
    Etape6Component,
    PrevalidComponent,
    ValidComponent,
    RgpdComponent,
    CnbComponent,
    CnbInscriptionComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.config),
    AngularFireAuthModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    CalendarModule,
    ButtonModule,
    AccordionModule,
    TextMaskModule,
    ScrollingModule,
    DropdownModule,
    NgxIntlTelInputModule
  ],
  providers: [FormulaireService, DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
