import { ViewChild } from '@angular/core';
import { ElementRef } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router, UrlSerializer } from '@angular/router';
import { Formulaire } from 'src/app/model/formulaire';
import { Etape6 } from 'src/app/model/formulaire/etape6';
import { AuthService } from 'src/app/services/auth/auth.service';
import { FileUtils } from 'src/app/utils/FileUtils';
import { FormulaireService } from 'src/app/services/formulaire/formulaire.service';
import { PaiementService } from 'src/app/services/formulaire/paiement.service';
import { Retour } from 'src/app/model/retour';
import { Etape1 } from 'src/app/model/formulaire/etape1';
import { InformationPaiementRequest } from 'src/app/model/InformationPaiementRequest';
import { Etape2 } from 'src/app/model/formulaire/etape2';


@Component({
  selector: 'app-etape6',
  templateUrl: './etape6.component.html',
  styleUrls: ['./etape6.component.css']
})
export class Etape6Component implements OnInit {


  public informationPaiementRequest : InformationPaiementRequest = new InformationPaiementRequest();

  public etape6form:FormGroup;
  public etape6 : Etape6 = new Etape6();
 
  public loadedService1 = false;
  public loadedService2 = false;
  public loadedService3 = false;
  public loading2 = false;
  public isInscrit:boolean = false;
  public hasPreInscriptionValide:boolean=false;
  public etape2 : Etape2 = new Etape2();

  public transacResult:string = null;

  //@ViewChild('childFichierFicheEngagementHonneur')
  //childFichierFicheEngagementHonneur: ElementRef;
  @ViewChild('childFichierAttestationReussiteExamen')
  childFichierAttestationReussiteExamen: ElementRef;
  @ViewChild('childFichierReleveNotesExamen')
  childFichierReleveNotesExamen: ElementRef;
  @ViewChild('childFichierAttestationAssurance')
  childFichierAttestationAssurance: ElementRef;
  @ViewChild('childFichierModeReglement')
  childFichierModeReglement: ElementRef;
  @ViewChild('childFichierCV')
  childFichierCV: ElementRef;
  @ViewChild('childFichierDroitImage')
  childFichierDroitImage: ElementRef;
  @ViewChild('childFichierRIB')
  childFichierRIB: ElementRef;
  @ViewChild('childFichierFranceTravail')
  childFichierFranceTravail: ElementRef;
  @ViewChild('childFichierCharteConfidentialite')
  childFichierCharteConfidentialite: ElementRef;
 
  //public fichierFicheEngagementHonneur : File = null;
  public fichierAttestationReussiteExamen : File = null;
  public fichierReleveNotesExamen:File = null;
  public fichierAttestationAssurance:File = null;
  public fichierModeReglement:File = null;
  public fichierRIB:File = null;
  public fichierCV:File = null;
  public fichierDroitImage:File = null;
  public fichierCharteConfidentialite:File = null;
  public fichierFranceTravail:File = null;
  public isDocteurDroit:boolean = false;
  constructor(fb: FormBuilder, public paiementService:PaiementService, public formulaireService: FormulaireService, public authService: AuthService, public router: Router) { 


    this.etape6form = fb.group({
      
      'q1ChoixPaiement': [null, Validators.required],
      'q2cocheEngagement': [null, Validators.required],
      'q3cocheCharteInformatique': [null, Validators.required],
      //'nomFichierFicheEngagementHonneur': [null, Validators.required],
      'nomFichierAttestationReussiteExamen': [null, Validators.required],
      'nomFichierReleveNotesExamen': [null, Validators.required],
      'nomFichierAttestationAssurance': [null, Validators.required],
      'nomFichierCV': [null, Validators.required],
      'nomFichierDroitImage': [null, Validators.required],
      'nomFichierCharteConfidentialite': [null, Validators.required],
      'nomFichierRIB': [null, Validators.required],
      'nomFichierFranceTravail': [],
      'q5protectionDonnees': [null, Validators.required], 
      'q6CocheCharteCourtoisie': [null, Validators.required], 
      'q5droitImage': [null, Validators.required],
      //'q8cocheReglementV2': [null, Validators.required],
    //  'q9charteConfidentialite': [null, Validators.required],
      'q10condamnation': [null, Validators.required],
      'q11CocheCharteIxad': [null, Validators.required],
      

      'q7cocheCnb': [null, Validators.required]
    });


  }

  markFormTouched(group: FormGroup | FormArray) {
    
    Object.keys(group.controls).forEach((key: string) => {
      const control = group.controls[key];
      if (control instanceof FormGroup || control instanceof FormArray) { 
        control.markAsTouched(); 
        this.markFormTouched(control); 
      }
      else { control.markAsTouched(); };
    });
  };

  reset(element:ElementRef) {
    
    element.nativeElement.value = "";
    
}
  
public loadFiles(){
  this.applyMandatory();
}

public applyMandatory(){


  if((this.etape6.nomFichierAttestationReussiteExamen != null || this.etape6.nomFichierReleveNotesExamen!= null) && (this.etape6.nomFichierAttestationReussiteExamen != "" || this.etape6.nomFichierReleveNotesExamen!= ""))
  {
    if(this.etape6.nomFichierAttestationReussiteExamen != null && this.etape6.nomFichierAttestationReussiteExamen != "")
    {
      this.etape6form.get('nomFichierReleveNotesExamen').clearValidators();
      this.etape6form.get('nomFichierReleveNotesExamen').updateValueAndValidity();

      
    }
    if(this.etape6.nomFichierReleveNotesExamen != null && this.etape6.nomFichierReleveNotesExamen != "")
    {
      this.etape6form.get('nomFichierAttestationReussiteExamen').clearValidators();
      this.etape6form.get('nomFichierAttestationReussiteExamen').updateValueAndValidity();
    }
  }
  if((this.etape6.nomFichierAttestationReussiteExamen == null && this.etape6.nomFichierReleveNotesExamen == null) || (this.etape6.nomFichierAttestationReussiteExamen == "" && this.etape6.nomFichierReleveNotesExamen == ""))
  {
    this.etape6form.get('nomFichierAttestationReussiteExamen').setValidators([Validators.required]);
    this.etape6form.get('nomFichierReleveNotesExamen').setValidators([Validators.required]);
    this.etape6form.get('nomFichierAttestationReussiteExamen').updateValueAndValidity();
    this.etape6form.get('nomFichierReleveNotesExamen').updateValueAndValidity();
  }
  if(this.etape6.q1ChoixPaiement == 'paiement_aide')
  {
    this.etape6form.get('nomFichierRIB').setValidators([Validators.required]);
    this.etape6form.get('nomFichierRIB').updateValueAndValidity();
  }
  else{
    this.etape6form.get('nomFichierRIB').clearValidators();
      this.etape6form.get('nomFichierRIB').updateValueAndValidity();
  }

}



  ngOnInit(): void {
  
  

    if(this.authService.isLoggedIn) {

      const user  =  JSON.parse(localStorage.getItem('user'));
      this.formulaireService.defineInscriptionValideIxad(user.uid).subscribe((data :any)=>{
        this.hasPreInscriptionValide = data.hasPreInscriptionValideIxad;  
        this.loadedService2 = true;    
        if(!this.hasPreInscriptionValide)
        {
          this.router.navigate(['formulaire/etape1']);
        }
        else{
          this.loadDataEtape2();
        }
      },
      error => {
      });
      
    }


  }

  public loadDataEtape2()
  {
    const user  =  JSON.parse(localStorage.getItem('user'));
    this.formulaireService.loadEtape2(user.uid).subscribe((data:any)=>{
      
      if(data.etape2 != null)
      {
        Formulaire.convertJsonToObject(this.formulaireService.formulaire ,data);
        this.etape2 = Object.assign(this.etape2, this.formulaireService.formulaire.etape2);
        if(this.etape2.q1souhait == 'option3' ||  this.etape2.q2admission == 'Auditeur_libre_etranger'){
          this.router.navigate(['formulaire/etape2']);
        }
        else{
         
            this.defineIsInscrit(user);
          
              
        }
      }
      else{
          this.etape2.uid =  user.uid;
      }
    },
    error => {
    });
  }

  public defineIsInscrit(user){
    this.formulaireService.defineIsInscrit(user.uid).subscribe((data :any)=>{

      this.isInscrit = data.isInscrit;   
      if(this.isInscrit)
      {
        this.isInscrit = true;
        this.router.navigate(['formulaire/valid']);
        //this.loadEtapes();
      }
      else{
        this.loadEtapes();
      }
    },
    error => {

    });
  }

  public loadEtapes(){

    const user  =  JSON.parse(localStorage.getItem('user'));

    this.formulaireService.loadEtape6(user.uid).subscribe((data:any)=>{
        
      if(data.etape6 != null)
      {
        Formulaire.convertJsonToObject(this.formulaireService.formulaire ,data);
        this.etape6 = Object.assign(this.etape6, this.formulaireService.formulaire.etape6);
      }
      else{
          this.etape6.uid =  user.uid;
      }
      this.loadedService1 = true;

    },
    error => {
    
    });
    
    this.formulaireService.defineIsDocteurDroit(user.uid).subscribe((data :any)=>{
      this.isDocteurDroit = data.isDocteurDroit; 
      this.loadedService3 = true; 
    },
    error => {
    
    });

  }

  /*
  public onFileChangeFichierFicheEngagementHonneur(event){
    let reader = new FileReader();
    if(event.target.files && event.target.files.length > 0) {
      if(FileUtils.controleExtension(event.target.files[0]) && FileUtils.controleFileSize(event.target.files[0]))
      {
        this.fichierFicheEngagementHonneur = event.target.files[0];
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = () => {
          this.etape6.nomFichierFicheEngagementHonneur = event.target.files[0].name;
        };
      }
      else
      {
        this.reset(this.childFichierFicheEngagementHonneur);
      }
    }
    else{
      this.fichierFicheEngagementHonneur = null;
      this.etape6.nomFichierFicheEngagementHonneur = "";
    }
  
  }
  */

  public onFileChangeFichierAttestationReussiteExamen(event){
    let reader = new FileReader();
    if(event.target.files && event.target.files.length > 0) {
      if(FileUtils.controleExtension(event.target.files[0]) && FileUtils.controleFileSize(event.target.files[0]))
      {
        this.fichierAttestationReussiteExamen = event.target.files[0];
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = () => {
          this.etape6.nomFichierAttestationReussiteExamen = event.target.files[0].name;
          this.applyMandatory();
        };
      }
      else
      {
        this.reset(this.childFichierAttestationReussiteExamen);
        this.applyMandatory();
      }
    }
    else{
      this.fichierAttestationReussiteExamen = null;
      this.etape6.nomFichierAttestationReussiteExamen = "";
      this.applyMandatory();
    }
  }

  public onFileChangeFichierReleveNotesExamen(event){
    let reader = new FileReader();
    if(event.target.files && event.target.files.length > 0) {
      if(FileUtils.controleExtension(event.target.files[0]) && FileUtils.controleFileSize(event.target.files[0]))
      {
        this.fichierReleveNotesExamen = event.target.files[0];
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = () => {
          this.etape6.nomFichierReleveNotesExamen = event.target.files[0].name;
          this.applyMandatory();
        };
      }
      else
      {
        this.reset(this.childFichierReleveNotesExamen);
        this.applyMandatory();
      }
    }
    else{
      this.fichierReleveNotesExamen = null;
      this.etape6.nomFichierReleveNotesExamen = "";
      this.applyMandatory();
    }
  }

  public onFileChangeFichierAttestationAssurance(event){
    let reader = new FileReader();
    if(event.target.files && event.target.files.length > 0) {
      if(FileUtils.controleExtension(event.target.files[0]) && FileUtils.controleFileSize(event.target.files[0]))
      {
        this.fichierAttestationAssurance = event.target.files[0];
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = () => {
          this.etape6.nomFichierAttestationAssurance = event.target.files[0].name;
        };
      }
      else
      {
        this.reset(this.childFichierAttestationAssurance);
      }
    }
    else{
      this.fichierAttestationAssurance = null;
      this.etape6.nomFichierAttestationAssurance = "";
    }
  }

  public onFileChangeFichierRIB(event){
    let reader = new FileReader();
    if(event.target.files && event.target.files.length > 0) {
      if(FileUtils.controleExtension(event.target.files[0]) && FileUtils.controleFileSize(event.target.files[0]))
      {
        this.fichierRIB = event.target.files[0];
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = () => {
          this.etape6.nomFichierRIB = event.target.files[0].name;
          this.applyMandatory();
        };
      }
      else
      {
        this.reset(this.childFichierRIB);
        this.applyMandatory();
      }
    }
    else{
      this.fichierRIB = null;
      this.etape6.nomFichierRIB = "";
      this.applyMandatory();
    }
  }

  public onFileChangeFichierFranceTravail(event){
    let reader = new FileReader();
    if(event.target.files && event.target.files.length > 0) {
      if(FileUtils.controleExtension(event.target.files[0]) && FileUtils.controleFileSize(event.target.files[0]))
      {
        this.fichierFranceTravail = event.target.files[0];
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = () => {
          this.etape6.nomFichierFranceTravail = event.target.files[0].name;
         
        };
      }
      else
      {
        this.reset(this.childFichierFranceTravail);
       
      }
    }
    else{
      this.fichierFranceTravail = null;
      this.etape6.nomFichierFranceTravail = "";
     
    }
  }

  public onFileChangeFichierCV(event){
    let reader = new FileReader();
    if(event.target.files && event.target.files.length > 0) {
      if(FileUtils.controleExtension(event.target.files[0]) && FileUtils.controleFileSize(event.target.files[0]))
      {
        this.fichierCV = event.target.files[0];
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = () => {
          this.etape6.nomFichierCV = event.target.files[0].name;
        };
      }
      else
      {
        this.reset(this.childFichierCV);
      }
    }
    else{
      this.fichierCV = null;
      this.etape6.nomFichierCV = "";
    }
  }

  public onFileChangeFichierDroitImage(event){
    let reader = new FileReader();
    if(event.target.files && event.target.files.length > 0) {
      if(FileUtils.controleExtension(event.target.files[0]) && FileUtils.controleFileSize(event.target.files[0]))
      {
        this.fichierDroitImage = event.target.files[0];
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = () => {
          this.etape6.nomFichierDroitImage = event.target.files[0].name;
        };
      }
      else
      {
        this.reset(this.childFichierDroitImage);
      }
    }
    else{
      this.fichierDroitImage = null;
      this.etape6.nomFichierDroitImage = "";
    }
  }

  public onFileChangeFichierCharteConfidentialite(event){
    let reader = new FileReader();
    if(event.target.files && event.target.files.length > 0) {
      if(FileUtils.controleExtension(event.target.files[0]) && FileUtils.controleFileSize(event.target.files[0]))
      {
        this.fichierCharteConfidentialite = event.target.files[0];
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = () => {
          this.etape6.nomFichierCharteConfidentialite = event.target.files[0].name;
        };
      }
      else
      {
        this.reset(this.childFichierCharteConfidentialite);
      }
    }
    else{
      this.fichierCharteConfidentialite = null;
      this.etape6.nomFichierCharteConfidentialite = "";
    }
  }

  



  public next()
  {
    if(!this.isInscrit){
   // this.markFormTouched(this.etape6form);
    this.loading2 = true;
    Object.assign(this.formulaireService.formulaire.etape6, this.etape6);
      var files : File[] = new Array(7);
      files[0] = null;
      files[1] = null;
      files[2] = null;
      files[3] = null;
      files[4] = null;
      files[5] = null;
      files[6] = null;
      files[7] = null;
      files[8] = null;
      


    
      /*if(this.fichierFicheEngagementHonneur !=null)
      {
        files[0] = this.fichierFicheEngagementHonneur;
      }*/
      if(this.fichierAttestationReussiteExamen != null)
      {
        files[0] = this.fichierAttestationReussiteExamen;
      }
      if(this.fichierReleveNotesExamen !=null)
      {
        files[1] = this.fichierReleveNotesExamen;
      }
      if(this.fichierAttestationAssurance !=null)
      {
        files[2] = this.fichierAttestationAssurance;
      }
      if(this.fichierCV !=null)
      {
        files[3] = this.fichierCV;
      }
      if(this.fichierDroitImage !=null)
      {
        files[4] = this.fichierDroitImage;
      }
      if(this.fichierRIB !=null)
      {
        files[5] = this.fichierRIB;
      }
      if(this.fichierCharteConfidentialite !=null)
      {
      files[6] = this.fichierCharteConfidentialite;
      }
      if(this.fichierFranceTravail !=null)
      {
        files[7] = this.fichierFranceTravail;
      }
     
     

      this.formulaireService.setDataEtape6(this.etape6, files).subscribe((result:any) => {
         
        if(result.retour)
        {
          if(result.retour == "true")
          {
            this.loading2 = false;
            this.router.navigate(['formulaire/valid']);
          }
          else{
            this.loading2 = false;
            alert("Une erreur s'est produite. Veuillez recommencer");
          }
        }
        else{
          this.loading2 = false;
          alert("Une erreur s'est produite. Veuillez recommencer")
        }

        this.loadFiles()

 
      },
      err => console.log('HTTP Error', err),
      );
    }
    else{
      this.router.navigate(['formulaire/valid']);
    }
    
  }


}
