import { Component, OnInit } from '@angular/core';
import { FormulaireService } from 'src/app/services/formulaire/formulaire.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Etape2 } from 'src/app/model/formulaire/etape2';
import { Formulaire } from 'src/app/model/formulaire';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Retour } from 'src/app/model/retour';
import { SelectItem } from 'primeng/api';
import { DatePipe } from '@angular/common';
import {CalendarModule, Calendar} from 'primeng/calendar';
import { ControlValueAccessor } from '@angular/forms';


@Component({
  selector: 'app-etape2',
  templateUrl: './etape2.component.html',
  styleUrls: ['./etape2.component.css']
})
export class Etape2Component implements OnInit {

  public isPreInscrit:boolean = false;
  public loadedService1 = false;
  
  public loading2 = false;

  public displayMotif1:boolean = false;

  public displayMotif2:boolean = false;

  public etape2 : Etape2 = new Etape2();

  public showPrepaPriveOui : boolean = false;

  public etape2form:FormGroup;

  public listeEcoles: SelectItem[];

  public fr: any;

  public calendarTheseDeploy:Boolean = false;

  public calendarExamenDeploy:Boolean = false;
  
  public hasPreInscriptionValide:boolean=false;
  
  public isInscrit:boolean = false;

  constructor(public datepipe: DatePipe, fb: FormBuilder, public formulaireService: FormulaireService, public authService: AuthService, public router: Router) {

    this.listeEcoles = [
    
      {label:'EDA PARIS (EFB)', value:'EDA PARIS (EFB)'},
      {label:'EDA VERSAILLES (HEDAC)', value:'EDA VERSAILLES (HEDAC)'},
      {label:'EDA GRAND EST (ERAGE)', value:'EDA GRAND EST (ERAGE)'},
      {label:'EDA RHONE- ALPES (EDARA)', value:'EDA RHONE- ALPES (EDARA)'},
      {label:'EDA SUD-EST (CFBSE)', value:'EDA SUD-EST (CFBSE)'},
      {label:'EDA CENTRE SUD (EFACS)', value:'EDA CENTRE SUD (EFACS)'},
      {label:'EDA SUD-OUEST PYRENEES', value:'EDA SUD-OUEST PYRENEES'},
      {label:'EDA ALIENOR', value:'EDA ALIENOR'},
      {label:'EDA CENTRE OUEST (ECOA)', value:'EDA CENTRE OUEST (ECOA)'},
      {label:'EDA GRAND OUEST (EDAGO)', value:'EDA GRAND OUEST (EDAGO)'}
     
      
  ];

  let dateTheseControl = fb.control({value:'', disabled: false}, Validators.required);
  let dateExamenControl = fb.control({value:'', disabled: false}, Validators.required);
    this.etape2form = fb.group({
      
      'q1souhait': [null, Validators.required],
      'q1motif2': [null, Validators.required],
      'q2admission': [null, Validators.required],
      'q2autre1': [null, Validators.required], 
      'q2docteurDroitPrecision':[], 
      'q3prepaPrive': [null, Validators.required],
      'q3prepaAnnuelleAutre': [null, Validators.required],
      'q3prepaAnnuelleDistance': [null, Validators.required],
      'q3prepaAnnuellePresentielle': [null, Validators.required],
      'q3prepaAnnuellePresentielleOrganisme': [null, Validators.required],
      'q3prepaAnnuellePresentielleLieu': [null, Validators.required],
      'q3prepaAnnuelleDistanceOrganisme': [null, Validators.required],
      'q3prepaAnnuelleDistanceLieu': [null, Validators.required],
      'q3prepaAnnuelleAutreOrganisme': [null, Validators.required],
      'q3prepaAnnuelleAutreLieu': [null, Validators.required],
      'q4InscriptionIxad': [null, Validators.required],
      'q4premiereInscriptionPrecision': [null, Validators.required],
      'q5inscriptionAutreEtablissement': [null, Validators.required], 
      'q5inscriptionAutreEtablissementAutrePrecision': [null, Validators.required], 
      'q5secondChoix': [null, Validators.required], 
      'q5secondChoixAutrePrecision': [null, Validators.required], 
      'q5diplomePrecision': [null, Validators.required], 
      'q6activiteExterieur': [null, Validators.required], 
      //'q7langueEtrangereChoisie': [null, Validators.required],
      'q8inscriptionExamenCapa': [null, Validators.required],
      'q8inscriptionExamenCapaPrecision': [null, Validators.required],
      'q6cocheEngagement': [],
      'q9dateThese': dateTheseControl,
      'q10dateExamen': dateExamenControl,
      'q11AnglaisInterrogation': [null, Validators.required],
      'q12master2' : [null, Validators.required],
      'q13Ordinateur' : [null, Validators.required],
      'q14IA' : [null, Validators.required]



      
    });

   }

   markFormTouched(group: FormGroup | FormArray) {
    
   Object.keys(group.controls).forEach((key: string) => {
     const control = group.controls[key];
     if (control instanceof FormGroup || control instanceof FormArray) { 
       control.markAsTouched(); 
       this.markFormTouched(control); 
     }
     else { control.markAsTouched(); };
   });
 };

 calendarTheseDeployTrue()
 {
   this.calendarTheseDeploy = true;
 }
 calendarTheseDeployFalse()
 {
   this.calendarTheseDeploy = false;
 }

 calendarExamenDeployTrue()
 {
   this.calendarExamenDeploy = true;
 }
 calendarExamenDeployFalse()
 {
   this.calendarExamenDeploy = false;
 }

  ngOnInit(): void {

    this.fr = {
      firstDayOfWeek: 1,
      dayNames: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
      dayNamesShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
      dayNamesMin: ["Di","Lu","Ma","Me","Je","Ve","Sa"],
      monthNames: [ "Janvier","Février","Mars","Avril","Mai","Juin","Juillet","Aout","Septembre","Octobre","Novembre","Décembre" ],
      monthNamesShort: [ "Jan", "Fev", "Mar", "Avr", "Mai", "Ju","Jui", "Aou", "Sep", "Oct", "Nov", "Dec" ],
      today: 'Aujourd\'hui',
      clear: 'Reset',
      dateFormat: 'dd/mm/yyyy'
      };

    this.loadedService1 = false;

    this.loading2 = false;

    if(this.authService.isLoggedIn) {
          const user  =  JSON.parse(localStorage.getItem('user'));
          this.formulaireService.defineInscriptionValideIxad(user.uid).subscribe((data :any)=>{
              this.hasPreInscriptionValide = data.hasPreInscriptionValideIxad;  
              if(this.hasPreInscriptionValide)
                this.router.navigate(['formulaire/etape6']);
              else
                this.defineIsPreInscrit(user);

             
          },
          error => {
          });
    }
  }

  public defineIsPreInscrit(user){
    this.formulaireService.defineIsPreInscrit(user.uid).subscribe((data :any)=>{

      this.isPreInscrit = data.isPreInscrit;   
      if(this.isPreInscrit)
      {
        //this.loadedService1 = true;

        this.isPreInscrit = true;
        this.loadDataEtape2();
        //this.router.navigate(['formulaire/prevalid']);
      }
      else{
        this.loadDataEtape2();
      }
    },
    error => {

    });
  }

  public loadDataEtape2()
  {
    const user  =  JSON.parse(localStorage.getItem('user'));
    this.formulaireService.loadEtape2(user.uid).subscribe((data:any)=>{
      
      if(data.etape2 != null)
      {
        Formulaire.convertJsonToObject(this.formulaireService.formulaire ,data);
        this.etape2 = Object.assign(this.etape2, this.formulaireService.formulaire.etape2);
      }
      else{
      
          this.etape2.uid =  user.uid;
        
      }
      this.loadedService1 = true;
   
    
      this.rdgControlForm();
      

    },
    error => {
     
    });
  }

  public resetAuditeurLibre()
  {
    this.etape2.q1choixEda1 = null;
    this.etape2.q1autre1 = null;
    this.etape2.q1autre2= null;
    this.etape2.q1motif2 = null;
    this.etape2.q2autre1 = null;
    this.etape2.q2docteurDroitPrecision = null;
    this.etape2.q3prepaAnnuelleAutre = null;
    this.etape2.q3prepaAnnuelleAutreLieu = null;
    this.etape2.q3prepaAnnuelleAutreOrganisme = null;
    this.etape2.q3prepaAnnuelleDistance = null;
    this.etape2.q3prepaAnnuelleDistanceLieu = null;
    this.etape2.q3prepaAnnuelleDistanceOrganisme = null;
    this.etape2.q3prepaAnnuellePresentielle = null;
    this.etape2.q3prepaAnnuellePresentielleLieu = null;
    this.etape2.q3prepaAnnuellePresentielleOrganisme = null;
    this.etape2.q3prepaPrive = null;
    this.etape2.q4InscriptionIxad = null;
    this.etape2.q4premiereInscriptionPrecision = null;
    if(this.etape2.q1souhait != 'option2')
    {
      this.etape2.q5diplomePrecision = null;
      this.etape2.q5inscriptionAutreEtablissement = null;
      this.etape2.q5inscriptionAutreEtablissementAutrePrecision = null;
      this.etape2.q5secondChoix = null;
      this.etape2.q5secondChoixAutrePrecision = null;
    }
    this.etape2.q6activiteExterieur = null
    this.etape2.q6cocheEngagement = null;
    this.etape2.q6fichier = null;
    this.etape2.q6nomFichier = null;
   // this.etape2.q7langueEtrangereChoisie = null;
    this.etape2.q11AnglaisInterrogation = null;
    this.etape2.q12master2 = null;
    this.etape2.q13Ordinateur = null;
    this.etape2.q14IA = null;
    this.etape2.q8inscriptionExamenCapa = null;
    this.etape2.q8inscriptionExamenCapaPrecision = null;
    this.etape2.q9dateThese = null;
    this.etape2.q10dateExamen = null;
   
  }

  public resetReportInscription(){
  
    this.etape2.q1choixEda1 = null;
    this.etape2.q1autre1 = null;
    this.etape2.q1autre2= null;
    if(this.etape2.q2admission != "Docteur_Droit_Organise")
    {
      this.etape2.q2docteurDroitPrecision = null;
      this.etape2.q9dateThese = null;
    }
    else{
      this.etape2.q10dateExamen = null;
    }

    if(this.etape2.q2admission != "Autre")
      this.etape2.q2autre1 = null;

    this.etape2.q3prepaAnnuelleAutre = null;
    this.etape2.q3prepaAnnuelleAutreLieu = null;
    this.etape2.q3prepaAnnuelleAutreOrganisme = null;
    this.etape2.q3prepaAnnuelleDistance = null; 
    this.etape2.q3prepaAnnuelleDistanceLieu = null;
    this.etape2.q3prepaAnnuelleDistanceOrganisme = null;
    this.etape2.q3prepaAnnuellePresentielle = null;
    this.etape2.q3prepaAnnuellePresentielleLieu = null;
    this.etape2.q3prepaAnnuellePresentielleOrganisme = null;
    this.etape2.q3prepaPrive = null;
    this.etape2.q4InscriptionIxad = null;
    this.etape2.q4premiereInscriptionPrecision = null;
    this.etape2.q5diplomePrecision = null;
    this.etape2.q5inscriptionAutreEtablissement = null;
    this.etape2.q5inscriptionAutreEtablissementAutrePrecision = null;
    this.etape2.q5secondChoix = null;
    this.etape2.q5secondChoixAutrePrecision = null;

    this.etape2.q6activiteExterieur = null
    this.etape2.q6cocheEngagement = null;
    this.etape2.q6fichier = null;
    this.etape2.q6nomFichier = null;
   // this.etape2.q7langueEtrangereChoisie = null;
    this.etape2.q11AnglaisInterrogation = null;
    this.etape2.q12master2 = null;
    this.etape2.q13Ordinateur = null;
    this.etape2.q14IA = null;
    this.etape2.q8inscriptionExamenCapa = null;
    this.etape2.q8inscriptionExamenCapaPrecision = null;
  }

  public resetDocteurDroits(){
    this.etape2.q1motif2 = null;
    this.etape2.q1choixEda1 = null;
    this.etape2.q1autre1 = null;
    this.etape2.q1autre2= null;
    this.etape2.q2autre1 = null;
    this.etape2.q3prepaAnnuelleAutre = null;
    this.etape2.q3prepaAnnuelleAutreLieu = null;
    this.etape2.q3prepaAnnuelleAutreOrganisme = null;
    this.etape2.q3prepaAnnuelleDistance = null;
    this.etape2.q3prepaAnnuelleDistanceLieu = null;
    this.etape2.q3prepaAnnuelleDistanceOrganisme = null;
    this.etape2.q3prepaAnnuellePresentielle = null;
    this.etape2.q3prepaAnnuellePresentielleLieu = null;
    this.etape2.q3prepaAnnuellePresentielleOrganisme = null;
    this.etape2.q3prepaPrive = null;
   // this.etape2.q4InscriptionIxad = null;
  //  this.etape2.q4premiereInscriptionPrecision = null;
    if(this.etape2.q1souhait != 'option2')
    {
      this.etape2.q5diplomePrecision = null;
      this.etape2.q5inscriptionAutreEtablissement = null;
      this.etape2.q5inscriptionAutreEtablissementAutrePrecision = null;
      this.etape2.q5secondChoix = null;
      this.etape2.q5secondChoixAutrePrecision = null;
    }

   
    this.etape2.q10dateExamen = null;
   // this.etape2.q8inscriptionExamenCapaPrecision = null;
    this.etape2.q6fichier = null;
    this.etape2.q6nomFichier = null;
  }

  public resetAutre(){

    this.etape2.q1motif2 = null;
    this.etape2.q1choixEda1 = null;
    this.etape2.q1autre1 = null;
    this.etape2.q1autre2= null;

    if(this.etape2.q1souhait != 'option2'){
      this.etape2.q5diplomePrecision = null;
      this.etape2.q5inscriptionAutreEtablissement = null;
      this.etape2.q5inscriptionAutreEtablissementAutrePrecision = null;
      this.etape2.q5secondChoix = null;
      this.etape2.q5secondChoixAutrePrecision = null;
    }

    if(this.etape2.q5diplomePrecision != "autre")
      this.etape2.q5inscriptionAutreEtablissementAutrePrecision = null;

    if(this.etape2.q5secondChoix != "autre")
      this.etape2.q5secondChoixAutrePrecision = null;

    

    if(this.etape2.q2admission != "Autre")
      this.etape2.q2autre1 = null;
    
    this.etape2.q9dateThese = null;
    this.etape2.q2docteurDroitPrecision = null;

    if(this.etape2.q3prepaPrive == 'Non')
    {
      this.etape2.q3prepaAnnuelleAutre = null;
      this.etape2.q3prepaAnnuelleAutreLieu = null;
      this.etape2.q3prepaAnnuelleAutreOrganisme = null;
      this.etape2.q3prepaAnnuelleDistance = null;
      this.etape2.q3prepaAnnuelleDistanceLieu = null;
      this.etape2.q3prepaAnnuelleDistanceOrganisme = null;
      this.etape2.q3prepaAnnuellePresentielle = null;
      this.etape2.q3prepaAnnuellePresentielleLieu = null;
      this.etape2.q3prepaAnnuellePresentielleOrganisme = null;
    }
    else{
      if(!this.etape2.q3prepaAnnuelleAutre)
      {
        this.etape2.q3prepaAnnuelleAutreLieu = null;
        this.etape2.q3prepaAnnuelleAutreOrganisme = null;
      }

      if(!this.etape2.q3prepaAnnuelleDistance)
      {
        this.etape2.q3prepaAnnuelleDistanceLieu = null;
        this.etape2.q3prepaAnnuelleDistanceOrganisme = null;
      }

      if(!this.etape2.q3prepaAnnuellePresentielle)
      {
        this.etape2.q3prepaAnnuellePresentielleLieu = null;
        this.etape2.q3prepaAnnuellePresentielleOrganisme = null;
      }

    }

    if(this.etape2.q8inscriptionExamenCapa == 'premiere'){
      this.etape2.q8inscriptionExamenCapaPrecision = null;
    }

    this.etape2.q6fichier = null;
    this.etape2.q6nomFichier = null;


  }

  public launchResetField()
  {
    if(this.etape2.q1souhait == 'option3')
    {
      this.resetReportInscription();
    }
    else if(this.etape2.q2admission == 'Docteur_Droit_Organise')
    {
      this.resetDocteurDroits();
    }
    else if(this.etape2.q2admission == "Auditeur_libre_etranger")
    {
      this.resetAuditeurLibre();
    }
    else
    {
      this.resetAutre();
    }

    



  }


  public next(){
    this.loading2 = true;
    
    if(this.etape2 == null || this.etape2.q1souhait == null || this.etape2.q1souhait == '' || this.etape2.q2admission == '')
    {
      alert("Veuillez renseigner à minima la question 1 et 2 pour continuer")
      this.loading2 = false;
    }
    else if(!this.isPreInscrit)
    {
     // this.rdgControlForm();
     // this.markFormTouched(this.etape2form);
    // if (this.etape2form.valid) {

    if(this.etape2.q2admission == 'Docteur_Droit_Organise')
    {
      if(this.etape2.q9dateThese != null && this.etape2.q9dateThese != ""){
        if(this.etape2.q9dateThese.length == 10)
        {
          var from = this.etape2.q9dateThese.split("/")
          let dateObject:Date = new Date(from[2], from[1] - 1, from[0])
          this.etape2.q9dateThese = this.datepipe.transform(dateObject, 'dd/MM/yyyy');
        }
        else{
          this.etape2.q9dateThese = this.datepipe.transform(this.etape2.q9dateThese, 'dd/MM/yyyy');
        }
      } 
    }
    if(this.etape2.q2admission != 'Docteur_Droit_Organise')
    {
      if(this.etape2.q10dateExamen != null && this.etape2.q10dateExamen != ""){
        if(this.etape2.q10dateExamen.length == 10)
        {
          var from = this.etape2.q10dateExamen.split("/")
          let dateObject:Date = new Date(from[2], from[1] - 1, from[0])
          this.etape2.q10dateExamen = this.datepipe.transform(dateObject, 'dd/MM/yyyy');
        }
        else{
          this.etape2.q10dateExamen = this.datepipe.transform(this.etape2.q10dateExamen, 'dd/MM/yyyy');
        }
      } 
    }
    this.launchResetField();
    Object.assign(this.formulaireService.formulaire.etape2, this.etape2);

    this.formulaireService.setDateEtape2(this.formulaireService.formulaire.etape2).subscribe((result:any) => {
      if(result.retour)
      {

        this.loading2 = false;
        if(this.etape2.q1souhait == 'option3' || this.etape2.q2admission == "Auditeur_libre_etranger")
        {
          this.router.navigate(['formulaire/etape5']);
        }
        else{
          this.router.navigate(['formulaire/etape3']);
        }
      }
    },
     err => console.log('HTTP Error', err),
    )
        

     // } else {
     //   this.loading2 = false;
     // }
    }
    else{
      this.loading2 = false;
      if(this.etape2.q1souhait == 'option3' || this.etape2.q2admission == "Auditeur_libre_etranger")
      {
        this.router.navigate(['formulaire/etape5']);
      }
      else{
        this.router.navigate(['formulaire/etape3']);
      }
 
    }
  
  }

  public previous(){

    Object.assign(this.formulaireService.formulaire.etape2, this.etape2);
  
    this.router.navigate(['formulaire/etape1']);
  
  }

  // Permet de lancer le contrôle complet du formulaire
  public rdgControlForm()
  {



    /**** Question 1 */
    if(this.etape2.q1souhait == 'option1')
    {
      this.etape2.q1motif2 = "";
      this.displayMotif1 = false;
      this.displayMotif2 = false;
      
      this.etape2form.get('q1motif2').disable({ emitEvent: false });
      this.etape2.q5inscriptionAutreEtablissement = null;
      this.etape2.q5diplomePrecision = "";
      this.etape2.q5inscriptionAutreEtablissementAutrePrecision = "";
      this.etape2.q5secondChoix = "";
      this.etape2.q5secondChoixAutrePrecision = "";
      this.etape2form.get('q5diplomePrecision').disable({ emitEvent: false });
      this.etape2form.get('q5inscriptionAutreEtablissementAutrePrecision').disable({ emitEvent: false });
      this.etape2form.get('q5inscriptionAutreEtablissement').disable({ emitEvent: false });
      this.etape2form.get('q5secondChoix').disable({ emitEvent: false });
      this.etape2form.get('q5secondChoixAutrePrecision').disable({ emitEvent: false });
    }
    else if(this.etape2.q1souhait == 'option2')
    {
      this.displayMotif1 = true;
      this.etape2.q1motif2 = "";
      this.etape2form.get('q1motif2').disable({ emitEvent: false });
      this.etape2form.get('q5diplomePrecision').enable({ emitEvent: false });
      this.etape2form.get('q5inscriptionAutreEtablissement').enable({ emitEvent: false });
      this.etape2form.get('q5secondChoix').enable({ emitEvent: false });
      this.etape2.q5inscriptionAutreEtablissement = "Oui";


      if (this.etape2.q5inscriptionAutreEtablissement == 'Non')
      {
        this.etape2form.get('q5diplomePrecision').disable({ emitEvent: false });
        this.etape2form.get('q5inscriptionAutreEtablissementAutrePrecision').disable({ emitEvent: false });
        this.etape2form.get('q5secondChoix').disable({ emitEvent: false });
        this.etape2form.get('q5secondChoixAutrePrecision').disable({ emitEvent: false });
      }
      else{
        if(this.etape2.q5diplomePrecision == 'autre' )
        {
          this.etape2form.get('q5inscriptionAutreEtablissementAutrePrecision').enable({ emitEvent: false });
        }
        else{
          this.etape2form.get('q5inscriptionAutreEtablissementAutrePrecision').disable({ emitEvent: false });
          this.etape2.q5inscriptionAutreEtablissementAutrePrecision = "";
        }

        if(this.etape2.q5secondChoix == 'autre' )
        {
          this.etape2form.get('q5secondChoixAutrePrecision').enable({ emitEvent: false });
        }
        else{
          this.etape2form.get('q5secondChoixAutrePrecision').disable({ emitEvent: false });
          this.etape2.q5secondChoixAutrePrecision = "";
        }

        this.etape2form.get('q5diplomePrecision').enable({ emitEvent: false });
        this.etape2form.get('q5secondChoix').enable({ emitEvent: false });
        
       


      }
    }
    else if(this.etape2.q1souhait == 'option3')
    {
      this.displayMotif2 = true;
     
      this.etape2form.get('q1motif2').enable({ emitEvent: false });

      this.etape2.q5inscriptionAutreEtablissement = null;
      this.etape2.q5diplomePrecision = "";
      this.etape2.q5secondChoix = "";
      this.etape2form.get('q5diplomePrecision').disable({ emitEvent: false });
      this.etape2form.get('q5secondChoix').disable({ emitEvent: false });
      this.etape2form.get('q5inscriptionAutreEtablissement').disable({ emitEvent: false });
      this.etape2form.get('q5inscriptionAutreEtablissementAutrePrecision').disable({ emitEvent: false });
      this.etape2form.get('q5secondChoixAutrePrecision').disable({ emitEvent: false });
    }
    else{
      this.etape2form.get('q1motif2').disable({ emitEvent: false });
      this.etape2.q1motif2 = "";
      this.displayMotif1 = false;
      this.displayMotif2 = false;
      this.etape2.q5inscriptionAutreEtablissement = null;
      this.etape2.q5diplomePrecision = "";
      this.etape2.q5secondChoix = "";
      this.etape2form.get('q5diplomePrecision').disable({ emitEvent: false });
      this.etape2form.get('q5secondChoix').disable({ emitEvent: false });
      this.etape2form.get('q5inscriptionAutreEtablissement').disable({ emitEvent: false });
      this.etape2form.get('q5inscriptionAutreEtablissementAutrePrecision').disable({ emitEvent: false });
      this.etape2form.get('q5secondChoixAutrePrecision').disable({ emitEvent: false });
    }

    /**** Question 2 */
    if(this.etape2.q2admission != 'Autre')
    {
      this.etape2form.get('q2autre1').disable({ emitEvent: false });
      this.etape2.q2autre1 = "";
    }
    else{
      
      this.etape2form.get('q2autre1').enable({ emitEvent: false });
    }


    if(this.etape2.q2admission == "Docteur_Droit_Organise")
    {

      // Si on est un docteur en droit, on désactive tous les champs hors docteur
      this.formulaireService.docteurDroit = true;
      this.showPrepaPriveOui=false;
      // question 3
      this.etape2.q3prepaAnnuellePresentielleLieu = "";
      this.etape2.q3prepaAnnuellePresentielleOrganisme = "";
      this.etape2.q3prepaAnnuelleDistanceLieu = "";
      this.etape2.q3prepaAnnuelleDistanceOrganisme = "";
      this.etape2.q3prepaAnnuelleAutreLieu = "";
      this.etape2.q3prepaAnnuelleAutreOrganisme = "";
      this.etape2.q3prepaAnnuelleAutre = null;
      this.etape2.q3prepaAnnuellePresentielle = null;
      this.etape2.q3prepaAnnuelleDistance = null;
      this.etape2.q3prepaPrive = null;
      this.etape2.q10dateExamen = "";
      this.etape2form.get('q3prepaPrive').disable({ emitEvent: false });
      this.etape2form.get('q3prepaAnnuelleAutre').disable({ emitEvent: false });
      this.etape2form.get('q3prepaAnnuelleDistance').disable({ emitEvent: false });
      this.etape2form.get('q3prepaAnnuellePresentielle').disable({ emitEvent: false });
      this.etape2form.get('q3prepaAnnuellePresentielleOrganisme').disable({ emitEvent: false });
      this.etape2form.get('q3prepaAnnuellePresentielleLieu').disable({ emitEvent: false });
      this.etape2form.get('q3prepaAnnuelleDistanceOrganisme').disable({ emitEvent: false });
      this.etape2form.get('q3prepaAnnuelleDistanceLieu').disable({ emitEvent: false });
      this.etape2form.get('q3prepaAnnuelleAutreOrganisme').disable({ emitEvent: false });
      this.etape2form.get('q3prepaAnnuelleAutreLieu').disable({ emitEvent: false });
      this.etape2form.get('q10dateExamen').disable({ emitEvent: false });
      this.etape2form.get('q9dateThese').enable({ emitEvent: false });


      this.etape2form.get('q6cocheEngagement').enable();
      this.etape2form.get('q6activiteExterieur').enable();
     // this.etape2form.get('q7langueEtrangereChoisie').enable();
      this.etape2form.get('q11AnglaisInterrogation').enable();
      this.etape2form.get('q12master2').enable();
      this.etape2form.get('q13Ordinateur').enable();
      this.etape2form.get('q14IA').enable();

      this.etape2form.get('q4InscriptionIxad').enable();
      this.etape2form.get('q4premiereInscriptionPrecision').enable();
      this.etape2form.get('q8inscriptionExamenCapa').enable();
      this.etape2form.get('q8inscriptionExamenCapaPrecision').enable();

      // question 4
      //this.etape2.q4InscriptionIxad = null;
      //this.etape2.q4premiereInscriptionPrecision = "";
    //  this.etape2form.get('q4InscriptionIxad').enable();
   //   this.etape2form.get('q4premiereInscriptionPrecision').enable();


      // question 8
     // this.etape2.q8inscriptionExamenCapa = null;
    //  this.etape2.q8inscriptionExamenCapaPrecision = "";
   //   this.etape2form.get('q8inscriptionExamenCapa').enable();
    //  this.etape2form.get('q8inscriptionExamenCapaPrecision').enable();

          /**** Question 4 */
          if(this.etape2.q4InscriptionIxad != 'premiere' && this.etape2.q4InscriptionIxad != '')
          {
            this.etape2form.get('q4premiereInscriptionPrecision').enable({ emitEvent: false });
          }
          else{
            this.etape2form.get('q4premiereInscriptionPrecision').disable({ emitEvent: false });
          }
    
          /**** Question 8 */
          if(this.etape2.q8inscriptionExamenCapa != 'premiere' && this.etape2.q8inscriptionExamenCapa != '')
          {
            this.etape2form.get('q8inscriptionExamenCapaPrecision').enable({ emitEvent: false });
          }
          else{
            this.etape2.q8inscriptionExamenCapaPrecision = "";
            this.etape2form.get('q8inscriptionExamenCapaPrecision').disable({ emitEvent: false });
            
          }
      
    }
    else if(this.etape2.q2admission == "Auditeur_libre_etranger")
    {
      this.formulaireService.docteurDroit = false;
      this.etape2form.get('q3prepaPrive').disable({ emitEvent: false });
      this.etape2form.get('q3prepaAnnuelleAutre').disable({ emitEvent: false });
      this.etape2form.get('q3prepaAnnuelleDistance').disable({ emitEvent: false });
      this.etape2form.get('q3prepaAnnuellePresentielle').disable({ emitEvent: false });
      this.etape2form.get('q3prepaAnnuellePresentielleOrganisme').disable({ emitEvent: false });
      this.etape2form.get('q3prepaAnnuellePresentielleLieu').disable({ emitEvent: false });
      this.etape2form.get('q3prepaAnnuelleDistanceOrganisme').disable({ emitEvent: false });
      this.etape2form.get('q3prepaAnnuelleDistanceLieu').disable({ emitEvent: false });
      this.etape2form.get('q3prepaAnnuelleAutreOrganisme').disable({ emitEvent: false });
      this.etape2form.get('q3prepaAnnuelleAutreLieu').disable({ emitEvent: false });


      this.etape2form.get('q4InscriptionIxad').disable({ emitEvent: false });
      this.etape2form.get('q4premiereInscriptionPrecision').disable({ emitEvent: false });
     // this.etape2form.get('q5inscriptionAutreEtablissement').disable({ emitEvent: false });
     // this.etape2form.get('q5inscriptionAutreEtablissementAutrePrecision').disable({ emitEvent: false });
     // this.etape2form.get('q5diplomePrecision').disable({ emitEvent: false });

      this.etape2form.get('q8inscriptionExamenCapa').disable({ emitEvent: false });
      this.etape2form.get('q8inscriptionExamenCapaPrecision').disable({ emitEvent: false });
      this.etape2form.get('q6cocheEngagement').disable({ emitEvent: false });
      this.etape2form.get('q6activiteExterieur').disable({ emitEvent: false });
      //this.etape2form.get('q7langueEtrangereChoisie').disable({ emitEvent: false });

      this.etape2form.get('q11AnglaisInterrogation').disable({ emitEvent: false });
      this.etape2form.get('q12master2').disable({ emitEvent: false });
      this.etape2form.get('q13Ordinateur').disable({ emitEvent: false });
      this.etape2form.get('q14IA').disable({ emitEvent: false });

      this.etape2form.get('q9dateThese').disable({ emitEvent: false });
      
      if(this.etape2.q1souhait != 'option3')
      {
        this.etape2form.get('q10dateExamen').disable({ emitEvent: false });
      }
      else{
        this.etape2form.get('q10dateExamen').enable();
      }
      
     







    }
    else if(this.etape2.q2admission != "Docteur_Droit_Organise" && this.etape2.q2admission != "Auditeur_libre_etranger")
    {
      this.formulaireService.docteurDroit = false;
          /**** Question 3 et 4 en fonction question 2 */
      this.etape2form.get('q3prepaPrive').enable();
      this.etape2form.get('q4InscriptionIxad').enable();
      this.etape2form.get('q4premiereInscriptionPrecision').enable();
      this.etape2form.get('q8inscriptionExamenCapa').enable();
      this.etape2form.get('q8inscriptionExamenCapaPrecision').enable();
      this.etape2form.get('q9dateThese').disable();
      this.etape2form.get('q10dateExamen').enable();
      this.etape2form.get('q6cocheEngagement').enable();
      this.etape2form.get('q6activiteExterieur').enable();
   //   this.etape2form.get('q7langueEtrangereChoisie').enable();
      this.etape2form.get('q11AnglaisInterrogation').enable();
      this.etape2form.get('q12master2').enable();
      this.etape2form.get('q13Ordinateur').enable();
      this.etape2form.get('q14IA').enable();

      this.etape2.q9dateThese = "";

      
      // Set de la variable permettant de jouer sur le filtre des questions

      /**** Question 3 */
      if(this.etape2.q3prepaPrive == 'Oui')
      {
        // fix pour si on coche/décoche avec le passage null => true => false
        this.showPrepaPriveOui=true;
       
        if(!this.etape2.q3prepaAnnuelleAutre)
          this.etape2.q3prepaAnnuelleAutre = "";
        if(!this.etape2.q3prepaAnnuelleDistance)
          this.etape2.q3prepaAnnuelleDistance = "";
        if(!this.etape2.q3prepaAnnuellePresentielle)
          this.etape2.q3prepaAnnuellePresentielle = "";
         
       
        // test pour savoir si au moins un champs de coché ou non
        if(this.etape2.q3prepaAnnuelleAutre == "" && this.etape2.q3prepaAnnuelleDistance == "" && this.etape2.q3prepaAnnuellePresentielle == "")
        {
          // Si aucun champs de coché, rendre tous les champs obligatoires à minima.
        
          this.etape2form.get('q3prepaAnnuelleAutre').setValidators([Validators.required]);
          this.etape2form.get('q3prepaAnnuelleAutreOrganisme').setValidators([Validators.required]);
          this.etape2form.get('q3prepaAnnuelleAutreLieu').setValidators([Validators.required]);
          this.etape2form.get('q3prepaAnnuelleDistance').setValidators([Validators.required]);
          this.etape2form.get('q3prepaAnnuelleDistanceOrganisme').setValidators([Validators.required]);
          this.etape2form.get('q3prepaAnnuelleDistanceLieu').setValidators([Validators.required]);
          this.etape2form.get('q3prepaAnnuellePresentielle').setValidators([Validators.required]);
          this.etape2form.get('q3prepaAnnuellePresentielleOrganisme').setValidators([Validators.required]);
          this.etape2form.get('q3prepaAnnuellePresentielleLieu').setValidators([Validators.required]);
          
          this.etape2form.get('q3prepaAnnuelleAutre').enable();
          this.etape2form.get('q3prepaAnnuelleAutreOrganisme').enable();
          this.etape2form.get('q3prepaAnnuelleAutreLieu').enable();
          this.etape2form.get('q3prepaAnnuelleDistance').enable();
          this.etape2form.get('q3prepaAnnuelleDistanceOrganisme').enable();
          this.etape2form.get('q3prepaAnnuelleDistanceLieu').enable();
          this.etape2form.get('q3prepaAnnuellePresentielle').enable();
          this.etape2form.get('q3prepaAnnuellePresentielleOrganisme').enable();
          this.etape2form.get('q3prepaAnnuellePresentielleLieu').enable();


        }
        else
        {
              // Si à minima un champs de coché, rendre les autres non obligatoire

              this.etape2form.get('q3prepaAnnuelleAutre').clearValidators();
              this.etape2form.get('q3prepaAnnuelleDistance').clearValidators();
              this.etape2form.get('q3prepaAnnuellePresentielle').clearValidators();
              this.etape2form.get('q3prepaAnnuelleAutreOrganisme').clearValidators();
              this.etape2form.get('q3prepaAnnuelleAutreLieu').clearValidators();
              this.etape2form.get('q3prepaAnnuelleDistanceOrganisme').clearValidators();
              this.etape2form.get('q3prepaAnnuelleDistanceLieu').clearValidators();
              this.etape2form.get('q3prepaAnnuellePresentielleOrganisme').clearValidators();
              this.etape2form.get('q3prepaAnnuellePresentielleLieu').clearValidators();
              
              this.etape2form.get('q3prepaAnnuelleAutre').updateValueAndValidity();
              this.etape2form.get('q3prepaAnnuelleDistance').updateValueAndValidity();
              this.etape2form.get('q3prepaAnnuellePresentielle').updateValueAndValidity();
              this.etape2form.get('q3prepaAnnuelleAutreOrganisme').updateValueAndValidity();
              this.etape2form.get('q3prepaAnnuelleAutreLieu').updateValueAndValidity();
              this.etape2form.get('q3prepaAnnuelleDistanceOrganisme').updateValueAndValidity();
              this.etape2form.get('q3prepaAnnuelleDistanceLieu').updateValueAndValidity();
              this.etape2form.get('q3prepaAnnuellePresentielleOrganisme').updateValueAndValidity();
              this.etape2form.get('q3prepaAnnuellePresentielleLieu').updateValueAndValidity();
             
         

              // on reactive les champs obligatoires en fonction de ce qui est coché
              if(this.etape2.q3prepaAnnuelleAutre)
              {
                //this.etape2form.get('q3prepaAnnuelleAutre').clearValidators();
                //this.etape2form.get('q3prepaAnnuelleAutre').setValidators([Validators.required]);
                this.etape2form.get('q3prepaAnnuelleAutreOrganisme').setValidators([Validators.required]);
                this.etape2form.get('q3prepaAnnuelleAutreLieu').setValidators([Validators.required]);
                
                //this.etape2form.get('q3prepaAnnuelleAutre').enable({ emitEvent: false });
                this.etape2form.get('q3prepaAnnuelleAutreOrganisme').enable({ emitEvent: false });
                this.etape2form.get('q3prepaAnnuelleAutreLieu').enable({ emitEvent: false });
              }
              else
              {
               // this.etape2form.get('q3prepaAnnuelleAutre').disable({ emitEvent: false });
                this.etape2form.get('q3prepaAnnuelleAutreOrganisme').disable({ emitEvent: false });
                this.etape2form.get('q3prepaAnnuelleAutreLieu').disable({ emitEvent: false });
                this.etape2.q3prepaAnnuelleAutreLieu = "";
                this.etape2.q3prepaAnnuelleAutreOrganisme = "";
              }
               
              if(this.etape2.q3prepaAnnuelleDistance){
                //this.etape2form.get('q3prepaAnnuelleDistance').setValidators([Validators.required]);
                this.etape2form.get('q3prepaAnnuelleDistanceOrganisme').setValidators([Validators.required]);
                this.etape2form.get('q3prepaAnnuelleDistanceLieu').setValidators([Validators.required]);
               // this.etape2form.get('q3prepaAnnuelleDistance').enable({ emitEvent: false });
                this.etape2form.get('q3prepaAnnuelleDistanceOrganisme').enable({ emitEvent: false });
                this.etape2form.get('q3prepaAnnuelleDistanceLieu').enable({ emitEvent: false });
              }
              else{
                //this.etape2form.get('q3prepaAnnuelleDistance').disable({ emitEvent: false });
                this.etape2form.get('q3prepaAnnuelleDistanceOrganisme').disable({ emitEvent: false });
                this.etape2form.get('q3prepaAnnuelleDistanceLieu').disable({ emitEvent: false });
                this.etape2.q3prepaAnnuelleDistanceLieu = "";
                this.etape2.q3prepaAnnuelleDistanceOrganisme = "";
              }

            
              if(this.etape2.q3prepaAnnuellePresentielle)
              {
               // this.etape2form.get('q3prepaAnnuellePresentielle').setValidators([Validators.required]);
                this.etape2form.get('q3prepaAnnuellePresentielleOrganisme').setValidators([Validators.required]);
                this.etape2form.get('q3prepaAnnuellePresentielleLieu').setValidators([Validators.required]);
                //this.etape2form.get('q3prepaAnnuellePresentielle').enable({ emitEvent: false });
                this.etape2form.get('q3prepaAnnuellePresentielleOrganisme').enable({ emitEvent: false });
                this.etape2form.get('q3prepaAnnuellePresentielleLieu').enable({ emitEvent: false });
               
              }
              else{
               // this.etape2form.get('q3prepaAnnuellePresentielle').disable({ emitEvent: false });
                this.etape2form.get('q3prepaAnnuellePresentielleOrganisme').disable({ emitEvent: false });
                this.etape2form.get('q3prepaAnnuellePresentielleLieu').disable({ emitEvent: false });
                this.etape2.q3prepaAnnuellePresentielleLieu = "";
                this.etape2.q3prepaAnnuellePresentielleOrganisme = "";

              }
          }

      }

      /**** Question 4 */
      if(this.etape2.q4InscriptionIxad != 'premiere' && this.etape2.q4InscriptionIxad != '')
      {
        this.etape2form.get('q4premiereInscriptionPrecision').enable({ emitEvent: false });
      }
      else{
        this.etape2form.get('q4premiereInscriptionPrecision').disable({ emitEvent: false });
      }

      /**** Question 8 */
      if(this.etape2.q8inscriptionExamenCapa != 'premiere' && this.etape2.q8inscriptionExamenCapa != '')
      {
        this.etape2form.get('q8inscriptionExamenCapaPrecision').enable({ emitEvent: false });
      }
      else{
        this.etape2.q8inscriptionExamenCapaPrecision = "";
        this.etape2form.get('q8inscriptionExamenCapaPrecision').disable({ emitEvent: false });
        
      }
    }
  

    


    /**** Question 6 */
 

    if (this.etape2.q6activiteExterieur == 'Oui')
    {
      
      // Fix pour rendre obligatoire, sinon la valeur vaut "false"
      if(!this.etape2.q6cocheEngagement)
      {
        this.etape2.q6cocheEngagement = null;
      }
     
    }
    else if (this.etape2.q6activiteExterieur == 'Non'){
      this.etape2.q6cocheEngagement = null;
     
    }

    /**** Question 7 */
    // RAS

  }

}
