import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import KRGlue from "@lyracom/embedded-form-glue";
import { Observable } from 'rxjs';
import { InformationPaiementRequest } from 'src/app/model/InformationPaiementRequest';
import { PaiementRequest } from 'src/app/model/PaiementRequest';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaiementService {

  constructor(private httpClient: HttpClient, public  afAuth:  AngularFireAuth, public  router:  Router,  public ngZone: NgZone) { 
  
  
  }
   
  getToken() : Promise<string>{
    return new Promise((resolve, reject) => {
      this.afAuth.onAuthStateChanged(function(user) {
      if (user) {
        user.getIdToken().then(function(data) {
          resolve(data);
        }).catch(reject);
      } else {
        reject('Error while executing onAuthStateChanged');
      }
      });
    });
  }

  createPaiement(informationPaiementRequest:InformationPaiementRequest){
    return Observable.fromPromise(this.getToken()).mergeMap(token => {
     
      const httpOptions = {
        headers: new HttpHeaders({
          'X-Firebase-Auth':  token
        })
      };
      const user  =  JSON.parse(localStorage.getItem('user'));
      return this.httpClient.post(environment.createPayRequest, informationPaiementRequest, httpOptions);
      
    });
  }
}
