<p style="color:white; padding:20px">
    Le Conseil national des barreaux (« CNB »), 180, boulevard Haussmann, 75008 
Paris, est responsable d’un traitement de données à caractère personnel vous 
concernant. <br/>
Ce traitement portant sur la centralisation des demandes d’inscription à la 
formation initiale auprès des CRFPA a pour finalité de gérer, à l’échelon national, 
les demandes d’inscription auprès des CRFPA afin d’identifier les anomalies 
éventuelles (demandes d’inscription multiple, demandes d’inscription non 
satisfaites etc.) et d’en informer les CRFPA pour leur permettre d’en tenir 
compte.<br/>
Ce traitement porte sur vos données suivantes : votre nom, votre prénom, le 
CRFPA sollicité pour votre (pré)inscription, le statut de votre demande 
d’inscription et, s’il y a lieu, l’université de rattachement de l’institut d’études 
judiciaires dans lequel vous avez passé l’examen d’entrée au CRFPA. Ces 
données sont communiquées au CNB par le CRFPA auprès duquel vous avez 
demandé à être inscrit.<br/>
Ce traitement est nécessaire à l’intérêt légitime poursuivi par le CNB et les 
CRFPA visant à optimiser, à l’échelon national, les demandes d’inscription aux 
CRFPA, dans l’intérêt des personnes éligibles à cette inscription dont les 
conditions sont définies par l’article 51 du décret n° 91-1197 du 27 novembre 
1991 organisant la profession d'avocat.<br/>
Ces données sont destinées à la direction juridique (pôle formation, voire 
d’autres pôles si nécessaire) et la direction générale du CNB, ainsi qu’aux 
personnes en charge de la gestion des inscriptions au sein des CRFPA. Ces 
données ne sont pas transférées en dehors de l’Union européenne. 
Vos données seront conservées au plus tard jusqu’au 31 mars 2022, date à 
laquelle elles seront détruites.<br/>
En application des articles 15 à 21 du RGPD et dans les conditions précisées par 
ceux-ci, vous disposez d’un droit d’opposition et de limitation du traitement ainsi 
qu’un droit d’accès, de rectification, d’effacement et de portabilité de vos 
données.<br/>
En application de l’article 85 de la loi Informatique et Libertés, vous disposez 
également d’un droit de formuler des directives spécifiques et générales 
concernant la conservation, l’effacement et la communication de vos données 
post-mortem. En ce qui concerne les directives générales, elles devront être 
adressées à un tiers de confiance numérique certifié par la Commission nationale 
informatique et libertés.<br/>
Vous pouvez exercer ces droits en écrivant à notre siège social par courrier 
postal à l’adresse précisée ci-dessus ou par email à l’adresse 
donneespersonnelles@cnb.avocat.fr.<br/>
Vous disposez également du droit d’introduire une réclamation auprès de la 
Commission nationale informatique et libertés (www.cnil.fr)<br/>
    </p>