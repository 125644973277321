export class FileUtils
{
    static controleExtension(file:File)
    {
      let retour = false;
      let ext = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase();
      if(ext == 'pdf' || ext == 'doc' || ext == 'docx')
      {
        retour = true;
      }
      else{
        alert("Cette extension de fichier n'est pas autorisée. Formats accéptés : PDF, DOC, DOCX");
      }
      return retour;
    }

    static controleFileSize(file:File)
    {
      let retour = true;
      if((file.size/1042) > 8192)
      {
        retour = false;
        alert("Fichier trop volumineux");
      }
      return retour;
    }

    
}

