import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormulaireService } from 'src/app/services/formulaire/formulaire.service'
import { Etape1 } from 'src/app/model/formulaire/etape1';
import { Retour } from 'src/app/model/retour';
import { Formulaire } from 'src/app/model/formulaire';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ignoreElements } from 'rxjs/operators';
import {CalendarModule, Calendar} from 'primeng/calendar';
import { AnimationEvent } from '@angular/animations';
import { ControlValueAccessor } from '@angular/forms';
import { ElementRef, OnDestroy, EventEmitter, Renderer2, ChangeDetectorRef, TemplateRef, QueryList, NgZone } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DOCUMENT } from '@angular/common'; 
import * as firebase from 'firebase/app';
import { ReferentielService } from 'src/app/services/referentiel.service';
import { KeyFilter } from 'primeng/keyfilter';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Etape2 } from 'src/app/model/formulaire/etape2';


@Component({
  selector: 'app-etape1',
  templateUrl: './etape1.component.html',
  styleUrls: ['./etape1.component.css']
})
export class Etape1Component implements OnInit {

  public isPreInscrit:boolean = false;
  public loadedService1 = false;
  public loadedService2 = false;
  public loading2 = false;


  public etape1 : Etape1 = new Etape1();
  public etape2 : Etape2 = null;
  public etape1form:FormGroup;
  public phoneObject;
  public phonePays;
  public hasPreInscriptionValide:boolean=false;
  public calendarDeploy:Boolean = false;

  // Autorisation droit image
  //public marked2 = true;
  public markedCnb = true;

  public prefCountry = ['fr','be'];


  public mask = [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/ ];
 
  fr: any;

  public doc = null;
  @ViewChild('myname')inputTxt:any; /*create a view child*/

  constructor( @Inject(DOCUMENT) document, public datepipe: DatePipe, public  fb: FormBuilder, public referentielService:ReferentielService, public formulaireService: FormulaireService, public authService: AuthService, public router: Router) {
    this.doc = document;
    let val2Control = fb.control({value:'', disabled: false}, Validators.required);
    this.etape1form = fb.group({
      
      'mail': [null, Validators.compose([Validators.required,  Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")])],
      'civilite': [null, Validators.required],
      'nom': [null, Validators.required],
      'nomUsage': [null, Validators.required],
      'prenom': [null, Validators.required],
      'sexe': [null, Validators.required],
      'dateNaissance': val2Control,
      'lieuNaissance': [null, Validators.required],
      'departementNaissance': [null, Validators.required],
      'adresse': [null, Validators.required],
      'cpVille': [null, Validators.required],
      'pays': [null, Validators.required],
      'telephone': [null, Validators.required],
      'situation': [null, Validators.required],
     // 'autorisationCommunicationMail': [],
      
      //'autorisationDroitsImage': [null, Validators.required],
      
      'cocheCnb': [null, Validators.required],
     
    });

   }

   markFormTouched(group: FormGroup | FormArray) {
   
    Object.keys(group.controls).forEach((key: string) => {
      const control = group.controls[key];

      
      if (control instanceof FormGroup || control instanceof FormArray) { 
        control.markAsTouched(); 
        this.markFormTouched(control); 
      }
      else { control.markAsTouched(); };
    });
  };


  public defineIsPreInscrit(user){
    this.formulaireService.defineIsPreInscrit(user.uid).subscribe((data :any)=>{

      this.isPreInscrit = data.isPreInscrit;   
      if(this.isPreInscrit)
      {
        //this.loadedService1 = true;
        //this.loadedService2 = true;
        this.isPreInscrit = true;
        this.loadDataEtape1();
        //this.router.navigate(['formulaire/prevalid']);
      }
      else{
        this.loadDataEtape1();
      }
    },
    error => {

    });
  }

  public loadDataEtape1(){
    const user  =  JSON.parse(localStorage.getItem('user'));
    this.formulaireService.loadEtape2(user.uid).subscribe((data:any)=>{
      if(data.etape2 != null)
      {
        this.etape2 = new Etape2();
        Formulaire.convertJsonToObject(this.formulaireService.formulaire, data);
        Object.assign(this.etape2, this.formulaireService.formulaire.etape2);
       
      }
      this.loadedService1 = true;
      
    });
    this.formulaireService.loadEtape1(user.uid).subscribe((data:any)=>{
      if(data.etape1 != null)
      {
        Formulaire.convertJsonToObject(this.formulaireService.formulaire, data);
        Object.assign(this.etape1, this.formulaireService.formulaire.etape1);
        this.phoneObject = this.etape1.telephone;
        this.phonePays = this.etape1.telephonePays;

        this.etape1.autorisationDroitsImage = null;
        //if(this.etape1.autorisationDroitsImage != true)
        //{
          //this.marked2 = false;
        //}

        if(this.etape1.cocheCnb != true)
        {
        
          this.markedCnb = false;
        }
      }
      else{
      
        this.etape1.uid =  user.uid;
        this.etape1.autorisationDroitsImage = null;
      
 
        //this.marked2 = true;
        this.markedCnb = false;
        
      }


      if(this.etape1.mail == "")
      {
        this.etape1.mail = user.email;

      }
      
      this.loadedService2 = true; 

    
   
      
    });
    /*
    this.formulaireService.defineIsPreInscrit(user.uid).subscribe((data :any)=>{
        
      this.isPreInscrit = data.isPreInscrit;  
      this.loadedService2 = true;
    
    },
    error => {
      
    });*/

  }
  ngOnInit(): void {

    this.loadedService1 = false;
    this.loadedService2 = false;
    this.loading2 = false;
    this.fr = {
      firstDayOfWeek: 1,
      dayNames: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
      dayNamesShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
      dayNamesMin: ["Di","Lu","Ma","Me","Je","Ve","Sa"],
      monthNames: [ "Janvier","Février","Mars","Avril","Mai","Juin","Juillet","Aout","Septembre","Octobre","Novembre","Décembre" ],
      monthNamesShort: [ "Jan", "Fev", "Mar", "Avr", "Mai", "Ju","Jui", "Aou", "Sep", "Oct", "Nov", "Dec" ],
      today: 'Aujourd\'hui',
      clear: 'Reset',
      dateFormat: 'dd/mm/yyyy'
      };
      const user  =  JSON.parse(localStorage.getItem('user'));
  
  
      this.formulaireService.defineInscriptionValideIxad(user.uid).subscribe((data :any)=>{
      
        this.hasPreInscriptionValide = data.hasPreInscriptionValideIxad;  
        if(this.hasPreInscriptionValide)
        {
          this.router.navigate(['formulaire/etape6']);
        }
        else
        {
          this.defineIsPreInscrit(user);
        }
           
      },
      error => {
      });

  }


  public next(){

      this.loading2 = true;
     // this.markFormTouched(this.etape1form);

     if(this.etape1 != null && this.etape1.nom != null)
     {
      this.etape1.nom = this.etape1.nom.trim();
     }

     if(this.etape1 != null && this.etape1.nomUsage != null)
     {
      this.etape1.nomUsage = this.etape1.nomUsage.trim();
     }

     if(this.etape1 != null && this.etape1.prenom != null)
     {
      this.etape1.prenom = this.etape1.prenom.trim();
     }
      
     
      if(!this.isPreInscrit)
      {

        
         // this.markFormTouched(this.etape1form);
        //if (this.etape1form.valid) {

            if(this.etape1.dateNaissance != null && this.etape1.dateNaissance != ""){
              if(this.etape1.dateNaissance.length == 10)
              {
              var from = this.etape1.dateNaissance.split("/")
              let dateObject:Date = new Date(from[2], from[1] - 1, from[0])
             
              this.etape1.dateNaissance = this.datepipe.transform(dateObject, 'dd/MM/yyyy');
              }
              else{
                this.etape1.dateNaissance = this.datepipe.transform(this.etape1.dateNaissance, 'dd/MM/yyyy');
              }
            } 

            Object.assign(this.formulaireService.formulaire.etape1, this.etape1);
            
            
            this.formulaireService.setDateEtape1(this.formulaireService.formulaire.etape1).subscribe((result:any) => {
            
              if(result.retour)
              {
                this.loading2 = false;
                this.router.navigate(['formulaire/etape2']);
              }
              else{
                this.loading2 = false;
              }
            },
            err => { this.loading2 = false;console.log('HTTP Error', err);}
            );


        //}
        //else{
        //  this.loading2 = false;
        //  alert("Erreur dans le formulaire, vérifier les champs")
        //}
    }
    else{
      this.loading2 = false;
      this.router.navigate(['formulaire/etape2']);
    }
  }




  public test(objet)
  {
    if( objet != null && objet.iso2 == 'fr' )
      this.doc.getElementById("NaN").placeholder = "01 23 45 67 89";
  }
  test1()
  {
    this.calendarDeploy = true;
  }
  test2()
  {
    this.calendarDeploy = false;
  }
  checkNumber(objet){
    let Data:any = objet;
    if(this.doc.getElementById("NaN") != null)
    {
      if(this.inputTxt != null && this.inputTxt.selectedCountry.iso2 == 'fr')
        this.doc.getElementById("NaN").placeholder = "01 23 45 67 89";
      else
        this.doc.getElementById("NaN").placeholder = "";
    }
      if(!this.etape1form.controls['telephone'].invalid)
      {
        if(Data != null)
        {
          this.etape1.telephone = Data.nationalNumber;
          this.etape1.telephoneFormatted = Data.internationalNumber
          this.etape1.telephonePays = Data.countryCode;
          this.etape1.telephoneValide = true;
        }
      }
      else{
        if(Data != null)
        {
         
          this.etape1.telephone = Data.nationalNumber;
          this.etape1.telephoneFormatted = Data.internationalNumber
          this.etape1.telephonePays = Data.countryCode;
          this.etape1.telephoneValide = false;
        }
        else{
          this.etape1.telephone = "";
          this.etape1.telephoneFormatted = "";
          this.etape1.telephonePays = "";
          this.etape1.telephoneValide = false;
        }
      }
   }

  // Autorisation image
  //public checkbox2(e){
    //this.marked2= e.target.checked;
  //}

  public checkboxCnb(e){
    this.markedCnb= e.target.checked;
  }

}
