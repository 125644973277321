<div class="container contact">
  <div class="row">
    <div class="col-md-3 customMD3">
      <div class="contact-info">
        <span class="pi pi-sign-out" style="color:white"></span><a class="linkDisconnect" href="#" (click)="authService.logout()">Se déconnecter</a>
        <a routerLink="/formulaire/etape1"><img src="/assets/img/logo-ixad.png" class="img-fluid" alt="image"/></a>
        <h2 style="color:white" class="anneeStyle">INSCRIPTION {{ authService.anneeInscription1 }} / {{ authService.anneeInscription2 }}</h2>
        <br/><br/><br/><i style="color:white">Pour toute question ou problème technique lors du remplissage du formulaire, merci d'envoyer un mail à <a href="mailto:inscription@ixad.fr">inscription@ixad.fr</a></i>
        <h4></h4>
      </div>
    </div>
    <div class="col-md-9" *ngIf="!loadedService1 || !loadedService2 || !loadedService3 || !loadedService4 "><img src="/assets/img/loader-orange.gif" style="width:100px"/></div>
    <div class="col-md-9" *ngIf="loadedService1 && loadedService2 && loadedService3 && loadedService4 ">
      
      <h4 *ngIf="etape2 != null && (etape2.q1souhait == 'option3' || etape2.q2admission == 'Auditeur_libre_etranger')">Récapitulatif</h4>
      <h4 *ngIf="etape2 != null && (etape2.q1souhait != 'option3' && etape2.q2admission != 'Auditeur_libre_etranger')">Etape 5 sur 5 - récapitulatif</h4>
      <h4></h4>
      <br/><br/>
      <p *ngIf="etape2 != null && etape2.q2admission == 'Auditeur_libre_etranger'">Vérifiez les informations saisies avant de valider votre demande</p>
      <p *ngIf="etape2 == null || (etape2 != null && etape2.q2admission != 'Auditeur_libre_etranger')">Vérifiez les informations saisies avant de valider votre pré-inscription</p>
      <br/>
      <div class="contact-form">

        <p-accordion>
          <p-accordionTab header="Etape 1 : informations personnelles">
            <span style="color:red" *ngIf="etape1 == null">Veuillez compléter l'étape 1</span>
            <div *ngIf="etape1 != null">

              Civilité : <span *ngIf="!etape1.civilite" style="color:red;font-style: italic;">Manquant</span>{{etape1.civilite}} <br/>
              Nom de naissance : <span *ngIf="!etape1.nom" style="color:red;font-style: italic;">Manquant</span>{{etape1.nom}}
              <br/> Nom d'usage : <span *ngIf="!etape1.nomUsage" style="color:red;font-style: italic;">Manquant</span>{{etape1.nomUsage}} 
              <br/>Prénom : <span *ngIf="!etape1.prenom" style="color:red;font-style: italic;">Manquant</span>{{etape1.prenom}}
              <br/>Situation : <span *ngIf="!etape1.situation" style="color:red;font-style: italic;">Manquant</span>{{etape1.situation}}
              <br/>Sexe : <span *ngIf="!etape1.sexe" style="color:red;font-style: italic;">Manquant</span>{{etape1.sexe}}<br/>
              
              Né(e) le <span *ngIf="!etape1.dateNaissance" style="color:red;font-style: italic;">Manquant</span>{{etape1.dateNaissance}}<br/>
              Ville de naissance : <span *ngIf="!etape1.lieuNaissance" style="color:red;font-style: italic;">Manquant</span>{{etape1.lieuNaissance}}
         
             
              <!-- TODO Autorisation -->
            
           
              <hr/>

              Adresse : <span *ngIf="!etape1.adresse" style="color:red;font-style: italic;">Manquant</span>{{etape1.adresse}} <br/>
              Code Postal/ville : <span *ngIf="!etape1.cpVille" style="color:red;font-style: italic;">Manquant</span>{{etape1.cpVille}} <br/>
              Pays : <span *ngIf="!etape1.pays" style="color:red;font-style: italic;">Manquant</span>{{etape1.pays}} <br/> 
           
              <hr/>
              <b>Contact : </b><br/>
              <span></span>
              Téléphone : 
              <span *ngIf="!etape1.telephone" style="color:red;font-style: italic;">Manquant</span>
              <span *ngIf="etape1.telephone && (!etape1.telephoneValide || !etape1.telephonePays)">{{etape1.telephone}}</span> 
              &nbsp; 
              <span *ngIf="etape1.telephone && (!etape1.telephoneValide || !etape1.telephonePays)" style="color:red;font-style: italic;">Invalide</span>
              
              <span *ngIf="etape1.telephoneValide && etape1.telephone" style="">{{etape1.telephone}} </span>
              
             
              
              <br/>
              Mail : <span *ngIf="!etape1.mail" style="color:red;font-style: italic;">Manquant</span>{{etape1.mail}}
              <span *ngIf="etape1.mail && !mailReg.test(etape1.mail)" style="color:red;font-style: italic;">Invalide</span>
              
             <!-- <br/>
              Prise de connaissance de la notice d'information sur le traitement des données : <span *ngIf="!etape1.cocheCnb" style="color:red;font-style: italic;">Manquant</span><span *ngIf="etape1.cocheCnb" style="color:green;font-style: italic;">Validé</span>
             -->
              
              <br/><br/>
              
              <a href routerLink="/formulaire/etape1">Retourner à l'étape 1</a>
            </div>
            <!-- TODO Autorisation -->
          

          </p-accordionTab>
          <p-accordionTab header="Etape 2 : informations complémentaires">
            <span style="color:red" *ngIf="etape2 == null">Veuillez compléter l'étape 2</span>
            <div *ngIf="etape2 != null">

              - Votre souhait : 
              <span *ngIf="!etape2.q1souhait" style="color:red;font-style: italic;">Manquant</span>
              <span *ngIf="etape2.q1souhait == 'option1'">Souhaite intégrer l’EDA Nord-Ouest, IXAD pour la prochaine rentrée</span>
              <span *ngIf="etape2.q1souhait == 'option2'">Souhaite intégrer l’EDA Nord-Ouest, IXAD à titre provisoire; </span>
              <span *ngIf="etape2.q1souhait == 'option3'">Souhaite reporter son inscription à l’EDA Nord-Ouest; </span>
              <span *ngIf="etape2.q1souhait == 'option3' && !etape2.q1motif2" style="color:red"> Motif manquant</span>{{ etape2.q1motif2 }}
              <br/><br/>

              <div *ngIf="etape2.q1souhait == 'option2'">
              <!-- - Etes-vous inscrit dans une autre EDA ? 
              <span *ngIf="!etape2.q5inscriptionAutreEtablissement" style="color:red;font-style: italic;">Manquant</span> {{ etape2.q5inscriptionAutreEtablissement }} 
              -->
              <span *ngIf="etape2.q5inscriptionAutreEtablissement == 'Oui' && etape2.q1souhait == 'option2'" >1er choix : </span>
              <span *ngIf="etape2.q5inscriptionAutreEtablissement == 'Oui' && etape2.q1souhait == 'option2' && !etape2.q5diplomePrecision" style="color:red;font-style: italic;">Manquant</span> {{ etape2.q5diplomePrecision }}
            
              <span *ngIf="etape2.q5diplomePrecision == 'autre' && etape2.q5inscriptionAutreEtablissement == 'Oui'" ><br/>Précision : </span> 
              <span *ngIf="etape2.q5diplomePrecision == 'autre' && etape2.q5inscriptionAutreEtablissement == 'Oui' && !etape2.q5inscriptionAutreEtablissementAutrePrecision" style="color:red;font-style: italic;">Manquant</span>{{ etape2.q5inscriptionAutreEtablissementAutrePrecision }}
              
              <span *ngIf="etape2.q5inscriptionAutreEtablissement == 'Oui' && etape2.q1souhait == 'option2'" ><br/>2nd choix : </span>
              <span *ngIf="etape2.q5inscriptionAutreEtablissement == 'Oui' && etape2.q1souhait == 'option2' && !etape2.q5secondChoix" style="color:red;font-style: italic;">Manquant</span> <span *ngIf="etape2.q5secondChoix != 'pas_second_choix'">{{ etape2.q5secondChoix }}</span> <span *ngIf="etape2.q5secondChoix == 'pas_second_choix'">Pas de second choix</span>
            
              <span *ngIf="etape2.q5secondChoix == 'autre' && etape2.q5inscriptionAutreEtablissement == 'Oui'" ><br/>Précision : </span> 
              <span *ngIf="etape2.q5secondChoix == 'autre' && etape2.q5inscriptionAutreEtablissement == 'Oui' && !etape2.q5secondChoixAutrePrecision" style="color:red;font-style: italic;">Manquant</span> {{ etape2.q5secondChoixAutrePrecision }}
              
           
              <br/><br/>
            </div>

              - Provenance de l'admission : <span *ngIf="!etape2.q2admission" style="color:red;font-style: italic;">Manquant</span>

              <span *ngIf="etape2.q2admission != 'Docteur_Droit_Organise' && etape2.q2admission != 'Auditeur_libre_etranger'">
              {{etape2.q2admission}}  
              </span>

              <span *ngIf="etape2.q2admission == 'Autre'"> <br/>Précision : {{etape2.q2autre1}}</span> 
              <span *ngIf="etape2.q2admission == 'Autre' && !etape2.q2autre1" style="color:red;font-style: italic;">Manquant</span>
             

                
              

              <span *ngIf="etape2.q2admission == 'Docteur_Droit_Organise'">
              en qualité de Docteur en Droit <br/> Organisé par l’Ecole Doctorale de : {{etape2.q2docteurDroitPrecision}}
              
            </span>
              <span *ngIf="etape2.q2admission == 'Docteur_Droit_Organise' && !etape2.q2docteurDroitPrecision" style="color:red;font-style: italic;">Manquant</span>

              <span *ngIf="etape2.q2admission == 'Auditeur_libre_etranger'">
              en qualité d’auditeur libre étranger 
             
              </span>
              <br/><br/>

              <div *ngIf="etape2.q2admission == 'Docteur_Droit_Organise'">
                - Date de soutenance de la thèse : 
                <span *ngIf="!etape2.q9dateThese" style="color:red;font-style: italic;">Manquant</span>{{etape2.q9dateThese}}<br/><br/>
              </div>

              <div *ngIf="(etape2.q2admission != 'Docteur_Droit_Organise' && etape2.q2admission != 'Auditeur_libre_etranger') || (etape2.q2admission == 'Auditeur_libre_etranger' && etape2.q1souhait == 'option3') ">
                - Date d’obtention de l’examen d’entrée : 
                <span *ngIf="!etape2.q10dateExamen" style="color:red;font-style: italic;">Manquant</span>{{etape2.q10dateExamen}}<br/><br/>
              </div>

              <div *ngIf="etape2.q2admission != 'Docteur_Droit_Organise' && etape2.q1souhait != 'option3' && this.etape2.q2admission != 'Auditeur_libre_etranger'">

                    - Avez-vous suivi, hormis l’IEJ, une préparation spécifique à l’examen d’entrée, type « prépa privée » ? 
                    <span *ngIf="!etape2.q3prepaPrive" style="color:red;font-style: italic;">Manquant</span>{{etape2.q3prepaPrive}}
                    

                    <br/>
                    
                    <span *ngIf="etape2.q3prepaPrive == 'Oui'">Précision : <br/></span>
                    <span *ngIf="etape2.q3prepaPrive == 'Oui' && !etape2.q3prepaAnnuellePresentielle && !etape2.q3prepaAnnuelleDistance && !etape2.q3prepaAnnuelleAutre" style="color:red;font-style: italic;">Manquant<br/></span>
                      
                    
                    <span *ngIf="etape2.q3prepaAnnuellePresentielle && this.etape2.q2admission != 'Auditeur_libre_etranger'">
                      - Préparation annuelle en présentielle; 
                      Organisme : <span *ngIf="!etape2.q3prepaAnnuellePresentielleOrganisme" style="color:red;font-style: italic;">Manquant</span> {{etape2.q3prepaAnnuellePresentielleOrganisme}} 
                      Lieu : <span *ngIf="!etape2.q3prepaAnnuellePresentielleLieu" style="color:red;font-style: italic;">Manquant</span> {{etape2.q3prepaAnnuellePresentielleLieu}} 
                      <br/>

                    </span>
                    <span *ngIf="etape2.q3prepaAnnuelleDistance && etape2.q1souhait != 'option3' && this.etape2.q2admission != 'Auditeur_libre_etranger'">
                      - Préparation annuelle à distance; 
                      Organisme : <span *ngIf="!etape2.q3prepaAnnuelleDistanceOrganisme" style="color:red;font-style: italic;">Manquant</span> {{etape2.q3prepaAnnuelleDistanceOrganisme}} 
                      Lieu : <span *ngIf="!etape2.q3prepaAnnuelleDistanceLieu" style="color:red;font-style: italic;">Manquant</span> {{etape2.q3prepaAnnuelleDistanceLieu}} 
                      <br/>
                    </span>
                    <span *ngIf="etape2.q3prepaAnnuelleAutre && etape2.q1souhait != 'option3'">
                    - Autre; 
                    Organisme : <span *ngIf="!etape2.q3prepaAnnuelleAutreOrganisme" style="color:red;font-style: italic;">Manquant</span> {{etape2.q3prepaAnnuelleAutreOrganisme}} 
                    Lieu : <span *ngIf="!etape2.q3prepaAnnuelleAutreLieu" style="color:red;font-style: italic;">Manquant</span> {{etape2.q3prepaAnnuelleAutreLieu}} 
                    <br/>
                    </span>

                    <br/>
                  </div>
                  <div *ngIf="etape2.q1souhait != 'option3' && this.etape2.q2admission != 'Auditeur_libre_etranger'">
                    - Cette inscription à l’EDA Nord-Ouest est 
                    <span *ngIf="!etape2.q4InscriptionIxad" style="color:red;font-style: italic;">Manquant</span>
                    <span *ngIf="etape2.q4InscriptionIxad == 'premiere'">la première</span>
                    <span *ngIf="etape2.q4InscriptionIxad == 'deuxieme'">la deuxième</span>
                    <span *ngIf="etape2.q4InscriptionIxad == 'troisieme'">la troisième</span>
                    <br/><br/> 

                    - Avez-vous déjà passé l'examen du CAPA ? 
                    <span *ngIf="!etape2.q8inscriptionExamenCapa" style="color:red;font-style: italic;">Manquant</span>
                    <span *ngIf="etape2.q8inscriptionExamenCapa == 'premiere'">Non</span>
                    <span *ngIf="etape2.q8inscriptionExamenCapa == 'deuxieme'">Oui, je suis redoublant</span>
                    <span *ngIf="etape2.q8inscriptionExamenCapa == 'troisieme'">Oui, je suis triplant</span>
                    <span *ngIf="(etape2.q8inscriptionExamenCapa == 'deuxieme' || etape2.q8inscriptionExamenCapa == 'troisieme')"><br/>Précisez dans quelle école d'Avocat : </span>
                    <span *ngIf="(etape2.q8inscriptionExamenCapa == 'deuxieme' || etape2.q8inscriptionExamenCapa == 'troisieme') && !etape2.q8inscriptionExamenCapaPrecision" style="color:red;font-style: italic;">Manquant</span> {{etape2.q8inscriptionExamenCapaPrecision}} 
                    <br/><br/> 
                    </div>

         


            <div *ngIf="etape2.q1souhait != 'option3' && this.etape2.q2admission != 'Auditeur_libre_etranger'">
            - Avez-vous une activité extérieure (emploi, TD, cours…) ?
            <span *ngIf="!etape2.q6activiteExterieur" style="color:red;font-style: italic;">Manquant</span>{{etape2.q6activiteExterieur}}
           

            <span *ngIf="etape2.q6cocheEngagement"><br/>Je m’engage à suivre la formation dans son intégralité et aménagerai l’emploi du temps de mes activités extérieures en fonction de celui de l’école.</span>

            <!--
            <br/><br/>
            - Langue étrangère choisie pour la formation et le CAPA :
            <span *ngIf="!etape2.q7langueEtrangereChoisie" style="color:red;font-style: italic;">Manquant</span> {{etape2.q7langueEtrangereChoisie}}
            <br/><br/>
            -->

            <br/><br/>
            - Intéréssé-e par l'enseignement et l'interrogation orale pour le CAPA en anglais ? 
            <span *ngIf="!etape2.q11AnglaisInterrogation" style="color:red;font-style: italic;">Manquant</span> {{etape2.q11AnglaisInterrogation}}
           
            <br/><br/>
            - Avez-vous un Master 2 en Droit ?
            <span *ngIf="!etape2.q12master2" style="color:red;font-style: italic;">Manquant</span> {{etape2.q12master2}}

            <br/><br/>
            - Diposez-vous d'un ordinateur portable ?
            <span *ngIf="!etape2.q13Ordinateur" style="color:red;font-style: italic;">Manquant</span> {{etape2.q13Ordinateur}}
            <br/><br/>

            - A quelle fréquence utilisez-vous l'Intélligence Artificielle ?
            <span *ngIf="!etape2.q14IA" style="color:red;font-style: italic;">Manquant</span> {{etape2.q14IA}}
            <br/><br/>

            

            

            

            







            
            </div>

            <a href routerLink="/formulaire/etape2">Retourner à l'étape 2</a>
          </div>
          </p-accordionTab>
          <p-accordionTab header="Etape 3 : votre parcours" *ngIf="etape2 != null && etape2.q1souhait != 'option3' && this.etape2.q2admission != 'Auditeur_libre_etranger'">
            <span style="color:red" *ngIf="etape3 == null">Veuillez compléter l'étape 3</span>
            <div *ngIf="etape3 != null">
              <b>Bac</b> : 
              Année : <span *ngIf="!etape3.q1BacAnnee" style="color:red;font-style: italic;">Manquant</span> {{etape3.q1BacAnnee}}  
              Série : <span *ngIf="!etape3.q1BacSerie" style="color:red;font-style: italic;">Manquant</span> {{etape3.q1BacSerie}}
              <span *ngIf="etape3.q1BacSerie == 'Autre' && !etape3.q1BacAutre" style="color:red;font-style: italic;">- Manquant</span>{{etape3.q1BacAutre}} <br/><br/>


              <b>Licence</b> : 
              Année : <span *ngIf="!etape3.q2Annee3LicenceAnnee" style="color:red;font-style: italic;">Manquant</span> {{etape3.q2Annee3LicenceAnnee}}  
              Filière : <span *ngIf="!etape3.q2Annee3LicenceSpecialisation" style="color:red;font-style: italic;">Manquant</span> {{etape3.q2Annee3LicenceSpecialisation}}
              <span *ngIf="etape3.q2Annee3LicenceSpecialisation == 'Autre' && !etape3.q2Annee3LicenceAutre" style="color:red;font-style: italic;">- Manquant</span>{{etape3.q2Annee3LicenceAutre}} <br/><br/>


              <b>Master 1</b> : 
              Année : <span *ngIf="!etape3.q3master1Annee" style="color:red;font-style: italic;">Manquant</span> {{etape3.q3master1Annee}}  
              Filière : <span *ngIf="!etape3.q3master1specialisation" style="color:red;font-style: italic;">Manquant</span> {{etape3.q3master1specialisation}}
              <span *ngIf="etape3.q3master1specialisation == 'Autre' && !etape3.q3master1Autre" style="color:red;font-style: italic;">- Manquant</span>{{etape3.q3master1Autre}} <br/><br/>
           
              <div *ngIf="etape2.q2admission == 'Docteur_Droit_Organise' || (etape3.q4master2Annee || etape3.q4master2specialisation || etape3.q4master2Autre)">
              <b>Master 2 </b> : 
              Année : <span *ngIf="!etape3.q4master2Annee" style="color:red;font-style: italic;">Manquant</span> {{etape3.q4master2Annee}}  
              Filière : <span *ngIf="!etape3.q4master2specialisation" style="color:red;font-style: italic;">Manquant</span> {{etape3.q4master2specialisation}}
              <span *ngIf="etape3.q4master2specialisation == 'Autre' && !etape3.q4master2Autre" style="color:red;font-style: italic;"> - Manquant</span>{{etape3.q4master2Autre}} <br/><br/>
              </div>
              <div *ngIf="etape3.q5autreSpecialisation || etape3.q5autreDiplome || etape3.q5autreAnnee">
              <b>Autre</b> : 
              Diplôme : <span *ngIf="(etape3.q5autreSpecialisation || etape3.q5autreAnnee) && !etape3.q5autreDiplome" style="color:red;font-style: italic;">Manquant</span> {{etape3.q5autreDiplome}}
              Année : <span *ngIf="(etape3.q5autreSpecialisation || etape3.q5autreDiplome || etape3.q5autreAnnee) && !etape3.q5autreAnnee" style="color:red;font-style: italic;">Manquant</span> {{etape3.q5autreAnnee}}  
              Filière : <span *ngIf="(etape3.q5autreDiplome || etape3.q5autreAnnee) && !etape3.q5autreSpecialisation" style="color:red;font-style: italic;">Manquant</span> {{etape3.q5autreSpecialisation}} <br/><br/>
              </div>
              <a href routerLink="/formulaire/etape3">Retourner à l'étape 3</a>
              

            </div>
          </p-accordionTab>
          <p-accordionTab header="Etape 4 : vos documents" *ngIf=" etape2 != null && etape2.q1souhait != 'option3' && this.etape2.q2admission != 'Auditeur_libre_etranger'">
            <span style="color:red" *ngIf="etape2 == null">Veuillez compléter l'étape 2<br/><br/></span>
            <span style="color:red" *ngIf="etape4 == null">Veuillez compléter l'étape 4<br/><br/></span>
            <div *ngIf="etape4 != null && etape2 != null">
              <b>Fichiers envoyés :</b> <br/><br/>
              Pièce d'identité ou titre de séjour : <span *ngIf="!etape4.nomFichierDocPieceIdentite" style="color:red;font-style: italic;">Manquant</span> {{etape4.nomFichierDocPieceIdentite}}<br/>
              Photocopie du diplôme de Master 1 ou celui de maîtrise : <span *ngIf="!etape4.nomFichierDiplomeMaster1Maitrise" style="color:red;font-style: italic;">Manquant</span>{{etape4.nomFichierDiplomeMaster1Maitrise}}<br/>
              <span *ngIf="etape2.q6activiteExterieur == 'Oui'">Document d'engagement : <span *ngIf="!etape4.nomFichierEngagementActiviteExt" style="color:red;font-style: italic;">Manquant</span>{{etape4.nomFichierEngagementActiviteExt}} <br/></span>
            <!-- <span *ngIf="etape2.q6activiteExterieur == 'Oui'">Autorisation de cumul : <span *ngIf="!etape4.nomFichierAutorisationCumulActiviteExt" style="color:red;font-style: italic;">Manquant</span>{{etape4.nomFichierAutorisationCumulActiviteExt}} <br/></span>--> 
              

              <div *ngIf="etape2.q2admission == 'Docteur_Droit_Organise' || etape2.q12master2 == 'Oui'">
                Photocopie du diplôme de Master 2 : <span *ngIf="!etape4.nomFichierDiplomeMaster2Maitrise" style="color:red;font-style: italic;">Manquant</span>{{etape4.nomFichierDiplomeMaster2Maitrise}}<br/>
              </div>

              <div *ngIf="etape2.q2admission == 'Docteur_Droit_Organise'">
                Exemplaire de la thèse : <span *ngIf="!etape4.nomFichierThese" style="color:red;font-style: italic;">Manquant</span> {{etape4.nomFichierThese}} 
                <span *ngIf="etape4.nomFichierAttestationSoutenanceEcoleDoctorat || (!etape4.nomFichierDiplomeDocteurDroit && !etape4.nomFichierAttestationSoutenanceEcoleDoctorat)"><br/>Attestation de soutenance de l’Ecole Doctorale : </span><span *ngIf="!etape4.nomFichierAttestationSoutenanceEcoleDoctorat && !etape4.nomFichierDiplomeDocteurDroit" style="color:red;font-style: italic;">Manquant</span>{{etape4.nomFichierAttestationSoutenanceEcoleDoctorat}} 
                <span *ngIf="etape4.nomFichierDiplomeDocteurDroit || (!etape4.nomFichierDiplomeDocteurDroit && !etape4.nomFichierAttestationSoutenanceEcoleDoctorat)"><br/>Diplôme de Docteur en Droit : </span><span *ngIf="!etape4.nomFichierDiplomeDocteurDroit && !etape4.nomFichierAttestationSoutenanceEcoleDoctorat" style="color:red;font-style: italic;">Manquant</span> {{etape4.nomFichierDiplomeDocteurDroit}} <br/>
                
              </div>
              <div *ngIf="etape1.lieuNaissance == 'Hors du territoire national'">
                Document de condition de nationalité : <span *ngIf="!etape4.nomFichierNationalite" style="color:red;font-style: italic;">Manquant</span>{{etape4.nomFichierNationalite}}<br/>
              </div>
             
              <br/>
             
            </div>
            <a href routerLink="/formulaire/etape4">Retourner à l'étape 4</a>

            
            
        </p-accordionTab>
      </p-accordion>
      <br/>
      <span *ngIf="etape2 != null && etape2.q2admission == 'Auditeur_libre_etranger'">
      <i><b>
        Merci d’adresser votre dossier au Président d’IXAD, conformément à l’article 1 de l’arrêté du 10 février 1992 fixant les modalités d’admission des étudiants étrangers dans un centre régional de formation professionnelle d’avocats en qualité d’auditeurs libres.<br/>
        La date butoir du dépôt de dossier est fixée au 06 décembre 2024.<br/>
        Les enseignements sont dispensés du lundi 16 décembre 2024 au lundi 07 juillet 2025.<br/><br/></b></i></span>

      <h6 style="color:red;" *ngIf="isPreInscrit">Votre pré-inscription est envoyée, vos modifications ne seront pas enregistrées</h6>
      <br/><br/>
      <div class="form-row">
        <div class="form-group col-md-5 text-right">
          <img src="/assets/img/loader-orange.gif" style="width:40px;" *ngIf="loading2" />
          <input type="button" class="btn btn-primary" (click)="previous()" value="Précédent">
        </div>
        <div class="form-group col-md-7 text-left">
          <input *ngIf="!isPreInscrit && etape2 != null && etape2.q2admission == 'Auditeur_libre_etranger'" type="button" class="btn btn-success" (click)="valider()"   value="Valider ma demande">
          <input *ngIf="!isPreInscrit  && etape2 != null && etape2.q2admission != 'Auditeur_libre_etranger'" type="button" class="btn btn-success" (click)="valider()"   value="Valider ma pré-inscription">
          <input *ngIf="isPreInscrit && hasPreInscriptionValide" type="button" class="btn btn-primary" (click)="next()"   value="Suivant">
        </div>
      </div>

      </div>
    </div>
  </div>
</div>