<div class="container contact">
    <div class="row">
      <div class="col-md-3 customMD3">
        <div class="contact-info">
          <span class="pi pi-sign-out" style="color:white"></span><a class="linkDisconnect" href="#" (click)="authService.logout()">Se déconnecter</a>
          <a routerLink="/formulaire/etape1"><img src="/assets/img/logo-ixad.png" class="img-fluid" alt="image"/></a>
          <h2 style="color:white" class="anneeStyle">INSCRIPTION {{ authService.anneeInscription1 }} / {{ authService.anneeInscription2 }}</h2>
          <br/><br/><br/><i style="color:white">Pour toute question ou problème technique lors du remplissage du formulaire, merci d'envoyer un mail à <a href="mailto:inscription@ixad.fr">inscription@ixad.fr</a></i>
          <h4></h4>
        </div>
      </div>
      <div class="col-md-9" *ngIf="!loadedService1 || !loadedService2 || !loadedService3 || !loadedService4 || !loadedService5 || !loadedService6"><img src="/assets/img/loader-orange.gif" style="width:100px"/></div>
      <div class="col-md-9" *ngIf="loadedService1 && loadedService2 && loadedService3 && loadedService4 && loadedService5 && loadedService6">
        
        <h4>Finaliser votre inscription</h4><br/><br/>
        <p></p>
        <div class="contact-form">
  
          <p-accordion>
            <p-accordionTab header="Etape 1 : informations personnelles">
              <span style="color:red" *ngIf="etape1 == null">Veuillez compléter l'étape 1</span>
            <div *ngIf="etape1 != null">

              Civilité : <span *ngIf="!etape1.civilite" style="color:red;font-style: italic;">Manquant</span>{{etape1.civilite}} <br/>
              Nom de naissance : <span *ngIf="!etape1.nom" style="color:red;font-style: italic;">Manquant</span>{{etape1.nom}}
              <br/> Nom d'usage : <span *ngIf="!etape1.nomUsage" style="color:red;font-style: italic;">Manquant</span>{{etape1.nomUsage}} 
              <br/>Prénom : <span *ngIf="!etape1.prenom" style="color:red;font-style: italic;">Manquant</span>{{etape1.prenom}}
              <br/>Situation : <span *ngIf="!etape1.situation" style="color:red;font-style: italic;">Manquant</span>{{etape1.situation}}
              <br/>Sexe : <span *ngIf="!etape1.sexe" style="color:red;font-style: italic;">Manquant</span>{{etape1.sexe}}<br/>
              
              Né(e) le <span *ngIf="!etape1.dateNaissance" style="color:red;font-style: italic;">Manquant</span>{{etape1.dateNaissance}}<br/>
              Ville de naissance : <span *ngIf="!etape1.lieuNaissance" style="color:red;font-style: italic;">Manquant</span>{{etape1.lieuNaissance}}
         
             
              <!-- TODO Autorisation -->
            
           
              <hr/>

              Adresse : <span *ngIf="!etape1.adresse" style="color:red;font-style: italic;">Manquant</span>{{etape1.adresse}} <br/>
              Code Postal/ville : <span *ngIf="!etape1.cpVille" style="color:red;font-style: italic;">Manquant</span>{{etape1.cpVille}} <br/>
              Pays : <span *ngIf="!etape1.pays" style="color:red;font-style: italic;">Manquant</span>{{etape1.pays}} <br/> 
           
              <hr/>
              <b>Contact : </b><br/>
              <span></span>
              Téléphone : 
              <span *ngIf="!etape1.telephone" style="color:red;font-style: italic;">Manquant</span>
              <span *ngIf="etape1.telephone && (!etape1.telephoneValide || !etape1.telephonePays)">{{etape1.telephone}}</span> 
              &nbsp; 
              <span *ngIf="etape1.telephone && (!etape1.telephoneValide || !etape1.telephonePays)" style="color:red;font-style: italic;">Invalide</span>
              
              <span *ngIf="etape1.telephoneValide && etape1.telephone" style="">{{etape1.telephone}} </span>
              
             
              
              <br/>
              Mail : <span *ngIf="!etape1.mail" style="color:red;font-style: italic;">Manquant</span>{{etape1.mail}}
              <span *ngIf="etape1.mail && !mailReg.test(etape1.mail)" style="color:red;font-style: italic;">Invalide</span>
             <!-- <br/>
            
              Prise de connaissance de la notice d'information sur le traitement des données : <span *ngIf="!etape1.cocheCnb" style="color:red;font-style: italic;">Manquant</span><span *ngIf="etape1.cocheCnb" style="color:green;font-style: italic;">Validé</span>-->
              
              <br/><br/>
              
             
            </div>
              <!-- TODO Autorisation -->
            
  
            </p-accordionTab>
            <p-accordionTab header="Etape 2 : informations complémentaires">
              <span style="color:red" *ngIf="etape2 == null">Veuillez compléter l'étape 2</span>
            <div *ngIf="etape2 != null">

              - Votre souhait : 
              <span *ngIf="!etape2.q1souhait" style="color:red;font-style: italic;">Manquant</span>
              <span *ngIf="etape2.q1souhait == 'option1'">Souhaite intégrer l’EDA Nord-Ouest, IXAD pour la prochaine rentrée</span>
              <span *ngIf="etape2.q1souhait == 'option2'">Souhaite intégrer l’EDA Nord-Ouest, IXAD à titre provisoire; </span>
              <span *ngIf="etape2.q1souhait == 'option3'">Souhaite reporter son inscription à l’EDA Nord-Ouest; </span>
              <span *ngIf="etape2.q1souhait == 'option3' && !etape2.q1motif2" style="color:red"> Motif manquant</span>{{ etape2.q1motif2 }}
              <br/><br/>

              <div *ngIf="etape2.q1souhait == 'option2'">
              <!-- - Etes-vous inscrit dans une autre EDA ? 
              <span *ngIf="!etape2.q5inscriptionAutreEtablissement" style="color:red;font-style: italic;">Manquant</span> {{ etape2.q5inscriptionAutreEtablissement }} 
              -->
              <span *ngIf="etape2.q5inscriptionAutreEtablissement == 'Oui' && etape2.q1souhait == 'option2'" >1er choix : </span>
              <span *ngIf="etape2.q5inscriptionAutreEtablissement == 'Oui' && etape2.q1souhait == 'option2' && !etape2.q5diplomePrecision" style="color:red;font-style: italic;">Manquant</span> {{ etape2.q5diplomePrecision }}
            
              <span *ngIf="etape2.q5diplomePrecision == 'autre' && etape2.q5inscriptionAutreEtablissement == 'Oui'" ><br/>Précision : </span> 
              <span *ngIf="etape2.q5diplomePrecision == 'autre' && etape2.q5inscriptionAutreEtablissement == 'Oui' && !etape2.q5inscriptionAutreEtablissementAutrePrecision" style="color:red;font-style: italic;">Manquant</span>{{ etape2.q5inscriptionAutreEtablissementAutrePrecision }}
              
              <span *ngIf="etape2.q5inscriptionAutreEtablissement == 'Oui' && etape2.q1souhait == 'option2'" ><br/>2nd choix : </span>
              <span *ngIf="etape2.q5inscriptionAutreEtablissement == 'Oui' && etape2.q1souhait == 'option2' && !etape2.q5secondChoix" style="color:red;font-style: italic;">Manquant</span> <span *ngIf="etape2.q5secondChoix != 'pas_second_choix'">{{ etape2.q5secondChoix }}</span> <span *ngIf="etape2.q5secondChoix == 'pas_second_choix'">Pas de second choix</span>
            
              <span *ngIf="etape2.q5secondChoix == 'autre' && etape2.q5inscriptionAutreEtablissement == 'Oui'" ><br/>Précision : </span> 
              <span *ngIf="etape2.q5secondChoix == 'autre' && etape2.q5inscriptionAutreEtablissement == 'Oui' && !etape2.q5secondChoixAutrePrecision" style="color:red;font-style: italic;">Manquant</span> {{ etape2.q5secondChoixAutrePrecision }}
              
           
              <br/><br/>
            </div>

              - Provenance de l'admission : <span *ngIf="!etape2.q2admission" style="color:red;font-style: italic;">Manquant</span>

              <span *ngIf="etape2.q2admission != 'Docteur_Droit_Organise' && etape2.q2admission != 'Auditeur_libre_etranger'">
              {{etape2.q2admission}}  
              </span>

              <span *ngIf="etape2.q2admission == 'Autre'"> <br/>Précision : {{etape2.q2autre1}}</span> 
              <span *ngIf="etape2.q2admission == 'Autre' && !etape2.q2autre1" style="color:red;font-style: italic;">Manquant</span>
             

                
              

              <span *ngIf="etape2.q2admission == 'Docteur_Droit_Organise'">
              en qualité de Docteur en Droit <br/> Organisé par l’Ecole Doctorale de : {{etape2.q2docteurDroitPrecision}}
              </span>
              <span *ngIf="etape2.q2admission == 'Docteur_Droit_Organise' && !etape2.q2docteurDroitPrecision" style="color:red;font-style: italic;">Manquant</span>

              <span *ngIf="etape2.q2admission == 'Auditeur_libre_etranger'">
              en qualité d’auditeur libre étranger 
              </span>
              <br/><br/>

              <div *ngIf="etape2.q2admission == 'Docteur_Droit_Organise'">
                - Date de soutenance de la thèse : 
                <span *ngIf="!etape2.q9dateThese" style="color:red;font-style: italic;">Manquant</span>{{etape2.q9dateThese}}<br/><br/>
              </div>

              <div *ngIf="etape2.q2admission != 'Docteur_Droit_Organise'">
                - Date d’obtention de l’examen d’entrée : 
                <span *ngIf="!etape2.q10dateExamen" style="color:red;font-style: italic;">Manquant</span>{{etape2.q10dateExamen}}<br/><br/>
              </div>

              <div *ngIf="etape2.q2admission != 'Docteur_Droit_Organise'">

              - Avez-vous suivi, hormis l’IEJ, une préparation spécifique à l’examen d’entrée, type « prépa privée » ? 
              <span *ngIf="!etape2.q3prepaPrive" style="color:red;font-style: italic;">Manquant</span>{{etape2.q3prepaPrive}}
              

              <br/>
              
              <span *ngIf="etape2.q3prepaPrive == 'Oui'">Précision : <br/></span>
              <span *ngIf="etape2.q3prepaPrive == 'Oui' && !etape2.q3prepaAnnuellePresentielle && !etape2.q3prepaAnnuelleDistance && !etape2.q3prepaAnnuelleAutre" style="color:red;font-style: italic;">Manquant<br/></span>
                
              
              <span *ngIf="etape2.q3prepaAnnuellePresentielle">
                - Préparation annuelle en présentielle; 
                Organisme : <span *ngIf="!etape2.q3prepaAnnuellePresentielleOrganisme" style="color:red;font-style: italic;">Manquant</span> {{etape2.q3prepaAnnuellePresentielleOrganisme}} 
                Lieu : <span *ngIf="!etape2.q3prepaAnnuellePresentielleLieu" style="color:red;font-style: italic;">Manquant</span> {{etape2.q3prepaAnnuellePresentielleLieu}} 
                <br/>

              </span>
              <span *ngIf="etape2.q3prepaAnnuelleDistance">
                - Préparation annuelle à distance; 
                Organisme : <span *ngIf="!etape2.q3prepaAnnuelleDistanceOrganisme" style="color:red;font-style: italic;">Manquant</span> {{etape2.q3prepaAnnuelleDistanceOrganisme}} 
                Lieu : <span *ngIf="!etape2.q3prepaAnnuelleDistanceLieu" style="color:red;font-style: italic;">Manquant</span> {{etape2.q3prepaAnnuelleDistanceLieu}} 
                <br/>
              </span>
              <span *ngIf="etape2.q3prepaAnnuelleAutre">
              - Autre; 
              Organisme : <span *ngIf="!etape2.q3prepaAnnuelleAutreOrganisme" style="color:red;font-style: italic;">Manquant</span> {{etape2.q3prepaAnnuelleAutreOrganisme}} 
              Lieu : <span *ngIf="!etape2.q3prepaAnnuelleAutreLieu" style="color:red;font-style: italic;">Manquant</span> {{etape2.q3prepaAnnuelleAutreLieu}} 
              <br/>
              </span>

              <br/>


              - Cette inscription à l’EDA Nord-Ouest est 
              <span *ngIf="!etape2.q4InscriptionIxad" style="color:red;font-style: italic;">Manquant</span>
              <span *ngIf="etape2.q4InscriptionIxad == 'premiere'">la première</span>
              <span *ngIf="etape2.q4InscriptionIxad == 'deuxieme'">la deuxième</span>
              <span *ngIf="etape2.q4InscriptionIxad == 'troisieme'">la troisième</span>
              <br/><br/> 

              - Avez vous déjà passé l'examen du CAPA ? 
              <span *ngIf="!etape2.q8inscriptionExamenCapa" style="color:red;font-style: italic;">Manquant</span>
              <span *ngIf="etape2.q8inscriptionExamenCapa == 'premiere'">Non</span>
              <span *ngIf="etape2.q8inscriptionExamenCapa == 'deuxieme'">Oui, je suis redoublant</span>
              <span *ngIf="etape2.q8inscriptionExamenCapa == 'troisieme'">Oui, je suis triplant</span>
              <span *ngIf="(etape2.q8inscriptionExamenCapa == 'deuxieme' || etape2.q8inscriptionExamenCapa == 'troisieme')"><br/>Précisez dans quelle école d'Avocat : </span>
              <span *ngIf="(etape2.q8inscriptionExamenCapa == 'deuxieme' || etape2.q8inscriptionExamenCapa == 'troisieme') && !etape2.q8inscriptionExamenCapaPrecision" style="color:red;font-style: italic;">Manquant</span> {{etape2.q8inscriptionExamenCapaPrecision}} 
              <br/><br/> 


            </div>



            - Avez-vous une activité extérieure (emploi, TD, cours…) ?
            <span *ngIf="!etape2.q6activiteExterieur" style="color:red;font-style: italic;">Manquant</span>{{etape2.q6activiteExterieur}}
           

            <span *ngIf="etape2.q6cocheEngagement"><br/>Je m’engage à suivre la formation dans son intégralité et aménagerai l’emploi du temps de mes activités extérieures en fonction de celui de l’école.</span>
           <!-- <br/><br/>
            - Langue étrangère choisie pour la formation et le CAPA :
            <span *ngIf="!etape2.q7langueEtrangereChoisie" style="color:red;font-style: italic;">Manquant</span> {{etape2.q7langueEtrangereChoisie}}
            <br/><br/>-->
            <br/><br/>
            - Intéréssé-e par l'enseignement et l'interrogation orale pour le CAPA en anglais ? 
            <span *ngIf="!etape2.q11AnglaisInterrogation" style="color:red;font-style: italic;">Manquant</span> {{etape2.q11AnglaisInterrogation}}
           
            <br/><br/>
            - Avez-vous un Master 2 en Droit ?
            <span *ngIf="!etape2.q12master2" style="color:red;font-style: italic;">Manquant</span> {{etape2.q12master2}}

            <br/><br/>
            - Diposez-vous d'un ordinateur portable ?
            <span *ngIf="!etape2.q13Ordinateur" style="color:red;font-style: italic;">Manquant</span> {{etape2.q13Ordinateur}}
            <br/><br/>

            - A quelle fréquence utilisez-vous l'Intélligence Artificielle ?
            <span *ngIf="!etape2.q14IA" style="color:red;font-style: italic;">Manquant</span> {{etape2.q14IA}}
            <br/><br/>

           
          </div>
            </p-accordionTab>
            <p-accordionTab header="Etape 3 : votre parcours">
              <span style="color:red" *ngIf="etape3 == null">Veuillez compléter l'étape 3</span>
            <div *ngIf="etape3 != null">
              <b>Bac</b> : 
              Année : <span *ngIf="!etape3.q1BacAnnee" style="color:red;font-style: italic;">Manquant</span> {{etape3.q1BacAnnee}}  
              Série : <span *ngIf="!etape3.q1BacSerie" style="color:red;font-style: italic;">Manquant</span> {{etape3.q1BacSerie}}
              <span *ngIf="etape3.q1BacSerie == 'Autre' && !etape3.q1BacAutre" style="color:red;font-style: italic;">- Manquant</span>{{etape3.q1BacAutre}} <br/><br/>


              <b>Licence</b> : 
              Année : <span *ngIf="!etape3.q2Annee3LicenceAnnee" style="color:red;font-style: italic;">Manquant</span> {{etape3.q2Annee3LicenceAnnee}}  
              Filière : <span *ngIf="!etape3.q2Annee3LicenceSpecialisation" style="color:red;font-style: italic;">Manquant</span> {{etape3.q2Annee3LicenceSpecialisation}}
              <span *ngIf="etape3.q2Annee3LicenceSpecialisation == 'Autre' && !etape3.q2Annee3LicenceAutre" style="color:red;font-style: italic;">- Manquant</span>{{etape3.q2Annee3LicenceAutre}} <br/><br/>


              <b>Master 1</b> : 
              Année : <span *ngIf="!etape3.q3master1Annee" style="color:red;font-style: italic;">Manquant</span> {{etape3.q3master1Annee}}  
              Filière : <span *ngIf="!etape3.q3master1specialisation" style="color:red;font-style: italic;">Manquant</span> {{etape3.q3master1specialisation}}
              <span *ngIf="etape3.q3master1specialisation == 'Autre' && !etape3.q3master1Autre" style="color:red;font-style: italic;">- Manquant</span>{{etape3.q3master1Autre}} <br/><br/>
           
              <div *ngIf="etape2.q2admission == 'Docteur_Droit_Organise' || (etape3.q4master2Annee || etape3.q4master2specialisation || etape3.q4master2Autre)">
              <b>Master 2 </b> : 
              Année : <span *ngIf="!etape3.q4master2Annee" style="color:red;font-style: italic;">Manquant</span> {{etape3.q4master2Annee}}  
              Filière : <span *ngIf="!etape3.q4master2specialisation" style="color:red;font-style: italic;">Manquant</span> {{etape3.q4master2specialisation}}
              <span *ngIf="etape3.q4master2specialisation == 'Autre' && !etape3.q4master2Autre" style="color:red;font-style: italic;"> - Manquant</span>{{etape3.q4master2Autre}} <br/><br/>
              </div>
              <div *ngIf="etape3.q5autreSpecialisation || etape3.q5autreDiplome || etape3.q5autreAnnee">
              <b>Autre</b> : 
              Diplôme : <span *ngIf="(etape3.q5autreSpecialisation || etape3.q5autreAnnee) && !etape3.q5autreDiplome" style="color:red;font-style: italic;">Manquant</span> {{etape3.q5autreDiplome}}
              Année : <span *ngIf="(etape3.q5autreSpecialisation || etape3.q5autreDiplome || etape3.q5autreAnnee) && !etape3.q5autreAnnee" style="color:red;font-style: italic;">Manquant</span> {{etape3.q5autreAnnee}}  
              Filière : <span *ngIf="(etape3.q5autreDiplome || etape3.q5autreAnnee) && !etape3.q5autreSpecialisation" style="color:red;font-style: italic;">Manquant</span> {{etape3.q5autreSpecialisation}} <br/><br/>
              </div>
            
              

            </div>
            </p-accordionTab>
            <p-accordionTab header="Etape 4 : vos documents">
              <span style="color:red" *ngIf="etape2 == null">Veuillez compléter l'étape 2<br/><br/></span>
              <span style="color:red" *ngIf="etape4 == null">Veuillez compléter l'étape 4<br/><br/></span>
              <div *ngIf="etape4 != null && etape2 != null">
                <b>Fichiers envoyés :</b> <br/><br/>
                Pièce d'identité ou titre de séjour : <span *ngIf="!etape4.nomFichierDocPieceIdentite" style="color:red;font-style: italic;">Manquant</span> {{etape4.nomFichierDocPieceIdentite}}<br/>

                <!--<span *ngIf="etape2.q6activiteExterieur == 'Oui'">Justificatif d'activité extérieure : <span *ngIf="!etape4.nomFichierJustificatifActiviteExt" style="color:red;font-style: italic;">Manquant</span>{{etape4.nomFichierJustificatifActiviteExt}} <br/></span>-->
                <span *ngIf="etape2.q6activiteExterieur == 'Oui'">Document d'engagement : <span *ngIf="!etape4.nomFichierEngagementActiviteExt" style="color:red;font-style: italic;">Manquant</span>{{etape4.nomFichierEngagementActiviteExt}} <br/></span>
               <!--<span *ngIf="etape2.q6activiteExterieur == 'Oui'">Autorisation de cumul : <span *ngIf="!etape4.nomFichierAutorisationCumulActiviteExt" style="color:red;font-style: italic;">Manquant</span>{{etape4.nomFichierAutorisationCumulActiviteExt}} <br/></span>--> 
               Photocopie du diplôme de Master 1 ou celui de maîtrise : <span *ngIf="!etape4.nomFichierDiplomeMaster1Maitrise" style="color:red;font-style: italic;">Manquant</span>{{etape4.nomFichierDiplomeMaster1Maitrise}}<br/>
               <div *ngIf="etape2.q2admission == 'Docteur_Droit_Organise' || etape2.q12master2 == 'Oui'">
                Photocopie du diplôme de Master 2 : <span *ngIf="!etape4.nomFichierDiplomeMaster2Maitrise" style="color:red;font-style: italic;">Manquant</span>{{etape4.nomFichierDiplomeMaster2Maitrise}}<br/>
              </div>

                <div *ngIf="etape2.q2admission == 'Docteur_Droit_Organise'">
                  Exemplaire de la thèse : <span *ngIf="!etape4.nomFichierThese" style="color:red;font-style: italic;">Manquant</span> {{etape4.nomFichierThese}} 
                  <span *ngIf="etape4.nomFichierAttestationSoutenanceEcoleDoctorat || (!etape4.nomFichierDiplomeDocteurDroit && !etape4.nomFichierAttestationSoutenanceEcoleDoctorat)"><br/>Attestation de soutenance de l’Ecole Doctorale : </span><span *ngIf="!etape4.nomFichierAttestationSoutenanceEcoleDoctorat && !etape4.nomFichierDiplomeDocteurDroit" style="color:red;font-style: italic;">Manquant</span>{{etape4.nomFichierAttestationSoutenanceEcoleDoctorat}} 
                  <span *ngIf="etape4.nomFichierDiplomeDocteurDroit || (!etape4.nomFichierDiplomeDocteurDroit && !etape4.nomFichierAttestationSoutenanceEcoleDoctorat)"><br/>Diplôme de Docteur en Droit : </span><span *ngIf="!etape4.nomFichierDiplomeDocteurDroit && !etape4.nomFichierAttestationSoutenanceEcoleDoctorat" style="color:red;font-style: italic;">Manquant</span> {{etape4.nomFichierDiplomeDocteurDroit}} <br/>
                  
                </div>
                <div *ngIf="etape1.lieuNaissance == 'Hors du territoire national'">
                  Document de condition de nationalité : <span *ngIf="!etape4.nomFichierNationalite" style="color:red;font-style: italic;">Manquant</span>{{etape4.nomFichierNationalite}}<br/>
                 
                </div>
                
                <br/>
               
              </div>
             
  
              
              
          </p-accordionTab>
          <p-accordionTab header="Complément">
            <span style="color:red" *ngIf="etape6 == null">Veuillez compléter l'étape de complément.<br/><br/></span>
            <div *ngIf="etape4 != null && etape2 != null && etape6 != null">

              Règlements intérieurs : <span *ngIf="!etape6.q2cocheEngagement" style="color:red;font-style: italic;">Manquant</span> <span *ngIf="etape6.q2cocheEngagement">Accepté</span><br/>
              <!--Règlement intérieur V2 : <span *ngIf="!etape6.q8cocheReglementV2" style="color:red;font-style: italic;">Manquant</span> <span *ngIf="etape6.q8cocheReglementV2">Accepté</span><br/>-->
              <!--Charte de confidentialité : <span *ngIf="!etape6.q9charteConfidentialite" style="color:red;font-style: italic;">Manquant</span> <span *ngIf="etape6.q9charteConfidentialite">Accepté</span><br/>-->
              Charte informatique : <span *ngIf="!etape6.q3cocheCharteInformatique" style="color:red;font-style: italic;">Manquant</span> <span *ngIf="etape6.q3cocheCharteInformatique">Accepté</span><br/>
              Charte de courtoisie numérique : <span *ngIf="!etape6.q6CocheCharteCourtoisie" style="color:red;font-style: italic;">Manquant</span> <span *ngIf="etape6.q6CocheCharteCourtoisie">Accepté</span><br/>
              Politique de protection des données personnelles : <span *ngIf="!etape6.q5protectionDonnees" style="color:red;font-style: italic;">Manquant</span><span *ngIf="etape6.q5protectionDonnees" style="color:green;font-style: italic;">Validé</span><br/>
              Charte éthique IXAD : <span *ngIf="!etape6.q11CocheCharteIxad" style="color:red;font-style: italic;">Manquant</span> <span *ngIf="etape6.q11CocheCharteIxad">Accepté</span><br/>
              Attestation sur l'honneur : <span *ngIf="!etape6.q10condamnation" style="color:red;font-style: italic;">Manquant</span> <span *ngIf="etape6.q10condamnation">Accepté</span><br/>
              Communication d'adresse e-mail (CNB) : <span *ngIf="!etape6.q7cocheCnb" style="color:red;font-style: italic;">Manquant</span> <span *ngIf="etape6.q7cocheCnb">Accepté</span><br/><br/>
              Règlement des droits de scolarité : <span *ngIf="!etape6.q1ChoixPaiement" style="color:red;font-style: italic;">Manquant</span> 
              <span *ngIf="etape6.q1ChoixPaiement == 'paiement_cb'">Paiement par carte bancaire</span>
              <span *ngIf="etape6.q1ChoixPaiement == 'paiement_comptant'">Paiement comptant par chèque</span>
              <span *ngIf="etape6.q1ChoixPaiement == 'paiement_echelonne'">Paiement échelonné par chèque</span>
              <span *ngIf="etape6.q1ChoixPaiement == 'paiement_aide'">Sollicitation d'une aide sur critères sociaux</span>
              <span *ngIf="etape6.q1ChoixPaiement != 'paiement_cb'">
              <br/><br/>
              Télécharger / Imprimer / Remplir <a target="_blank" href="/assets/pdfs/etape6/Inscrip_Frais_échelonnés_2025.pdf">ce document</a> qui sera à remettre au secrétariat accompagné de votre (ou de vos) chèques le jour de votre entretien d'inscription.</span>
              <br/><br/>
              <b>Fichiers envoyés :</b> <br/>

              <span *ngIf="etape6.nomFichierFranceTravail">Document France Travail : {{etape6.nomFichierFranceTravail}}</span>
             <!-- Fiche d'engagement sur l'honneur : <span *ngIf="!etape6.nomFichierFicheEngagementHonneur" style="color:red;font-style: italic;">Manquant</span> {{etape6.nomFichierFicheEngagementHonneur}} -->
             <span *ngIf="etape2.q2admission != 'Docteur_Droit_Organise'">
                <span *ngIf="etape2.q2admission != 'Docteur_Droit_Organise' && (etape6.nomFichierAttestationReussiteExamen || (!etape6.nomFichierReleveNotesExamen && !etape6.nomFichierAttestationReussiteExamen))"><br/>Photocopie de l'attestation de réussite à l'éxamen d'entrée : </span><span *ngIf="etape2.q2admission != 'Docteur_Droit_Organise' && !etape6.nomFichierAttestationReussiteExamen && !etape6.nomFichierReleveNotesExamen" style="color:red;font-style: italic;">Manquant</span>{{etape6.nomFichierAttestationReussiteExamen}} 
                <span *ngIf="etape2.q2admission != 'Docteur_Droit_Organise' && (etape6.nomFichierReleveNotesExamen || (!etape6.nomFichierReleveNotesExamen && !etape6.nomFichierAttestationReussiteExamen))"><br/> Relevé de notes à l'examen d'entrée : </span><span *ngIf="etape2.q2admission != 'Docteur_Droit_Organise' && !etape6.nomFichierReleveNotesExamen && !etape6.nomFichierAttestationReussiteExamen" style="color:red;font-style: italic;">Manquant</span> {{etape6.nomFichierReleveNotesExamen}} 
              </span>
              <br/>
             <!-- <span *ngIf="!etape6.nomFichierAttestationReussiteExamen" style="color:red;font-style: italic;">Manquant</span> {{etape6.nomFichierAttestationReussiteExamen}}<br/>
             <span *ngIf="!etape6.nomFichierReleveNotesExamen" style="color:red;font-style: italic;">Manquant</span> {{etape6.nomFichierReleveNotesExamen}}<br/>-->
              Attestation de votre assurance de Responsabilité Civile : <span *ngIf="!etape6.nomFichierAttestationAssurance" style="color:red;font-style: italic;">Manquant</span> {{etape6.nomFichierAttestationAssurance}}<br/>
              CV : <span *ngIf="!etape6.nomFichierCV" style="color:red;font-style: italic;">Manquant</span> {{etape6.nomFichierCV}}<br/>
              Droit à l'image : <span *ngIf="!etape6.nomFichierDroitImage" style="color:red;font-style: italic;">Manquant</span> {{etape6.nomFichierDroitImage}}<br/>
              Charte de confidentialite : <span *ngIf="!etape6.nomFichierCharteConfidentialite" style="color:red;font-style: italic;">Manquant</span> {{etape6.nomFichierCharteConfidentialite}}<br/>
              
              <div *ngIf="etape6.q1ChoixPaiement == 'paiement_aide'">
                Relevé d'Identité Bancaire (RIB) : <span *ngIf="!etape6.nomFichierRIB" style="color:red;font-style: italic;">Manquant</span>{{etape6.nomFichierRIB}}
              </div>
              <br/><br/>
              <a href routerLink="/formulaire/etape6" *ngIf="!isInscrit">Retourner à l'étape 6</a><br/><br/>
              <a target="_blank" style="color:blue; cursor:default" onClick="MyWindow=window.open('/formulaire/rgpd','MyWindow','width=600,height=300'); return false;">En savoir plus sur la protection des données personnelles</a>
            </div>

            
            
        </p-accordionTab>
        </p-accordion>
        <br/>

        <div class="form-row">
          <div class="form-group col-md-12 text-center" style="color:green" *ngIf="transacResult == 'AUTHORISED'"> {{msgRetourPaiement}} </div>
          <div class="form-group col-md-12 text-center" style="color:red" *ngIf="transacResult != 'AUTHORISED'"> {{msgRetourPaiement}} </div>
        </div>
       
            <div class="form-row">
              <div class="form-group col-md-5 text-right">
                <img src="/assets/img/loader-orange.gif" style="width:40px;" *ngIf="loading2" />
                <input *ngIf="!isInscrit" type="button" class="btn btn-primary" (click)="previous()" value="Précédent">
              </div>
              <div class="form-group col-md-7 text-left">
                <input *ngIf="(!isInscrit && etape6.q1ChoixPaiement != 'paiement_cb') || (!isInscrit && etape6.q1ChoixPaiement == 'paiement_cb' && checkPaiem)" type="button" class="btn btn-success" (click)="valider()"   value="Finaliser mon inscription">
                <input *ngIf="!isInscrit && etape6.q1ChoixPaiement == 'paiement_cb' && !checkPaiem" type="button" class="btn btn-success" (click)="payer()"   value="Payer et finaliser mon inscription">
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-md-5 text-right">
                <form #form1 id="form1" method="POST" action="https://secure.payzen.eu/vads-payment/">

                  <!-- Configuration paiement -->
                  <input type="hidden" name="vads_action_mode" [(ngModel)]="informationPaiementRequest.vads_action_mode" /> 
                  <input type="hidden" name="vads_ctx_mode" [(ngModel)]="informationPaiementRequest.vads_ctx_mode" /> 
                  <input type="hidden" name="vads_currency" [(ngModel)]="informationPaiementRequest.vads_currency" />
                  <input type="hidden" name="vads_cust_country" [(ngModel)]="informationPaiementRequest.vads_cust_country" />
                  <input type="hidden" name="vads_page_action" [(ngModel)]="informationPaiementRequest.vads_page_action" />
                  <input type="hidden" name="vads_payment_config" [(ngModel)]="informationPaiementRequest.vads_payment_config" /> 
                  <input type="hidden" name="vads_site_id" [(ngModel)]="informationPaiementRequest.vads_site_id" /> 
                  <input type="hidden" name="vads_version" [(ngModel)]="informationPaiementRequest.vads_version" />
                  <input type="hidden" name="vads_shop_name" [(ngModel)]="informationPaiementRequest.vads_shop_name" />
                  <input type="hidden" name="vads_shop_url" [(ngModel)]="informationPaiementRequest.vads_shop_url" />
                  <input type="hidden" name="vads_amount" [(ngModel)]="informationPaiementRequest.vads_amount" /> 

                  <!-- Champs à calculer bo -->
                  <input type="hidden" name="signature" [(ngModel)]="informationPaiementRequest.signature" [value]="informationPaiementRequest.signature" />
                  <input type="hidden" #tdate name="vads_trans_date" [(ngModel)]="informationPaiementRequest.vads_trans_date" /> 
                  <input type="hidden" name="vads_trans_id" [(ngModel)]="informationPaiementRequest.vads_trans_id" /> 
                  <input type="hidden" name="vads_order_id" [(ngModel)]="informationPaiementRequest.vads_order_id" />

                  <!-- Données utilisateur -->
                  <input type="hidden" name="vads_cust_email" [(ngModel)]="informationPaiementRequest.vads_cust_email" />
                  <input type="hidden" name="vads_cust_id" [(ngModel)]="informationPaiementRequest.vads_cust_id" />
                  <input type="hidden" name="vads_cust_last_name" [(ngModel)]="informationPaiementRequest.vads_cust_last_name" />
                  <input type="hidden" name="vads_cust_first_name" [(ngModel)]="informationPaiementRequest.vads_cust_first_name" />
                  
                  <!-- Configuration url retours -->
                  <input type="hidden" name="vads_url_return" [(ngModel)]="informationPaiementRequest.vads_url_return" />
                  <input type="hidden" name="vads_url_error" [(ngModel)]="informationPaiementRequest.vads_url_error" />
                  <input type="hidden" name="vads_url_refused" [(ngModel)]="informationPaiementRequest.vads_url_refused" />
                  <input type="hidden" name="vads_url_success" [(ngModel)]="informationPaiementRequest.vads_url_success" />
                  <input type="hidden" name="vads_url_cancel" [(ngModel)]="informationPaiementRequest.vads_url_cancel" />
                  <input type="hidden" name="vads_url_check" [(ngModel)]="informationPaiementRequest.vads_url_check" />
                 
                  <input type="hidden" name="vads_redirect_error_message" [(ngModel)]="informationPaiementRequest.vads_redirect_error_message" />
                  <input type="hidden" name="vads_redirect_error_timeout" [(ngModel)]="informationPaiementRequest.vads_redirect_error_timeout" />
                  <input type="hidden" name="vads_redirect_success_message" [(ngModel)]="informationPaiementRequest.vads_redirect_success_message" />
                  <input type="hidden" name="vads_redirect_success_timeout" [(ngModel)]="informationPaiementRequest.vads_redirect_success_timeout" />
                  <input type="hidden" name="vads_return_mode" [(ngModel)]="informationPaiementRequest.vads_return_mode" />
              </form>
            </div>
          </div>
       
          <div class="form-row" *ngIf="etape1?.inscriptions?.etatInscriptionCode != 'IR' && ((isInscrit && etape6.q1ChoixPaiement != 'paiement_cb') || (isInscrit && etape6.q1ChoixPaiement == 'paiement_cb' && checkPaiem))">
            <div class="form-group col-md-12 text-center" style="color:red">
              <!--<img src="/assets/img/check.png" style="width:5%" alt="image"/>--> VOTRE DEMANDE D'INSCRIPTION EST MAINTENANT TERMINEE NOUS REVIENDRONS VERS VOUS PAR E-MAIL.
            </div>
  
          </div>

          <div class="form-row" *ngIf="etape1?.inscriptions?.etatInscriptionCode == 'IR'">
            <div class="form-group col-md-12 text-center" style="color:red">
              LES INSCRIPTIONS SONT TERMINÉES. VOTRE DOSSIER EST REFUSÉ OU NON COMPLET.
            </div>
  
          </div>
      
       
  
        </div>
      </div>
    </div>
  </div>