
  <div [formGroup]="etape1form">
    
  <div class="container contact">
    <div class="row">
      <div class="col-md-3 customMD3">
        <div class="contact-info">
          <span class="pi pi-sign-out" style="color:white"></span><a class="linkDisconnect" href="#" (click)="authService.logout()">Se déconnecter</a>
          <a routerLink="/formulaire/etape1"><img src="/assets/img/logo-ixad.png" class="img-fluid" alt="image"/></a>
          <h2 style="color:white" class="anneeStyle">INSCRIPTION {{ authService.anneeInscription1 }} / {{ authService.anneeInscription2 }}</h2>
          <br/><br/><br/><i style="color:white">Pour toute question ou problème technique lors du remplissage du formulaire, merci d'envoyer un mail à <a href="mailto:inscription@ixad.fr">inscription@ixad.fr</a></i>
          <h4></h4>
        </div>
      </div>

      <div class="col-md-9" *ngIf="!loadedService1 || !loadedService2"><img src="/assets/img/loader-orange.gif" style="width:100px"/></div>
      <div class="col-md-9" *ngIf="loadedService1 && loadedService2">
        
        <h4 *ngIf="etape2 != null && (etape2.q1souhait == 'option3' || etape2.q2admission == 'Auditeur_libre_etranger')">Etape 1 sur 2 - informations personnelles</h4>
        <h4 *ngIf="etape2 != null && (etape2.q1souhait != 'option3' && etape2.q2admission != 'Auditeur_libre_etranger')">Etape 1 sur 5 - informations personnelles</h4>
        <br/>
        
  
        <p>Complétez les champs ci-dessous avec vos informations.</p><br/>
        <div class="">

          
          <div class="form-row">
            <div class="form-group col-md-4">
              <label for="exampleFormControlSelect1">Civilité<span style="color:red"> *</span> :</label>
            <select class="form-control" id="exampleFormControlSelect1" formControlName="civilite" [(ngModel)]="etape1.civilite" name="etape1.civilite">
              <option value="">--- Choisir ---</option>
              <option value = "Mr">Mr</option>
              <option value = "Mme" >Mme</option>
            </select>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-md-4">
              <label for="exampleFormControlSelect1">Sexe<span style="color:red"> *</span> :</label>
            <select class="form-control" id="exampleFormControlSelect1" [(ngModel)]="etape1.sexe" formControlName="sexe">
              <option value="">--- Choisir ---</option>
              <option>Masculin</option>
              <option>Féminin</option>
            </select>
            <span class="text-danger" *ngIf="etape1form.controls['sexe'].hasError('required') && (etape1form.controls['sexe'].dirty || etape1form.controls['sexe'].touched)">* Champ obligatoire</span>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-md-6" >
              <label for="inputEmail4">Nom de naissance<span style="color:red"> *</span> : </label>
              <input type="text" class="form-control" id="inputEmail4" (keyup)="noSpace($event)" placeholder="Nom de naissance" oninput="this.value = this.value.toUpperCase()" [(ngModel)]="etape1.nom" formControlName="nom">
            	<span class="text-danger" *ngIf="etape1form.controls['nom'].hasError('required') && (etape1form.controls['nom'].dirty || etape1form.controls['nom'].touched)">* Champ obligatoire</span>
            </div>
            <div class="form-group col-md-6" >
              <label for="inputEmail4">Nom d'usage<span style="color:red"> *</span> : </label>
              <input type="text" class="form-control" id="inputEmail4" (keyup)="noSpace($event)" placeholder="Nom d'usage" oninput="this.value = this.value.toUpperCase()" [(ngModel)]="etape1.nomUsage" formControlName="nomUsage">
            	<span class="text-danger" *ngIf="etape1form.controls['nomUsage'].hasError('required') && (etape1form.controls['nomUsage'].dirty || etape1form.controls['nomUsage'].touched)">* Champ obligatoire</span>
            </div>

          </div>

          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="inputPassword4">Prénom<span style="color:red"> *</span> :</label>
              <input type="text" class="form-control" id="inputPassword4" placeholder="Prénom" oninput="this.value = this.value.charAt(0).toUpperCase() + this.value.slice(1).toLowerCase()"  [(ngModel)]="etape1.prenom" formControlName="prenom">
              <span class="text-danger" *ngIf="etape1form.controls['prenom'].hasError('required') && (etape1form.controls['prenom'].dirty || etape1form.controls['prenom'].touched)">* Champ obligatoire</span>
            </div>
            <div class="form-group col-md-6"></div>
          </div>

          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="exampleFormControlSelect1">Situation familiale<span style="color:red"> *</span> :</label>
            <select class="form-control" id="exampleFormControlSelect1" [(ngModel)]="etape1.situation" formControlName="situation">
              <option value="">--- Choisir ---</option>
              <option>Marié(e)</option>
              <option>Pacsé</option>
              <option>Célibataire</option>
              <option>Vie maritale</option>
              <option>Divorcé(e)</option>
            </select>
            <span class="text-danger" *ngIf="etape1form.controls['situation'].hasError('required') && (etape1form.controls['situation'].dirty || etape1form.controls['situation'].touched)">* Champ obligatoire</span>

            </div>
           
          </div>




          <div class="form-row" id="test">
            <div class="form-group col-md-12">
              <label for="inputEmail4">Date de naissance<span style="color:red"> *</span> : </label><br/>
              
              <p-calendar [yearNavigator]="true" [monthNavigator]="true" yearRange="1900:2030" (onShow)="test1()" (onClose)="test2()" [(ngModel)]="etape1.dateNaissance" formControlName="dateNaissance" inputStyleClass="form-control" dateFormat="dd/mm/yy" [locale]="fr" >
              </p-calendar>
              
              <span class="text-danger" *ngIf="etape1form.controls['dateNaissance'].hasError('required') && !calendarDeploy && (etape1form.controls['dateNaissance'].dirty || etape1form.controls['dateNaissance'].touched)">* Champ obligatoire</span>
            </div>

          </div>

        <!--  <div class="form-row">
            <div class="form-group col-md-6">
              <label for="inputEmail4">Département de naissance<span style="color:red"> *</span> : </label>
              <input type="text" class="form-control" id="inputEmail4" placeholder="Département de naissance" oninput="this.value = this.value.toUpperCase()" [(ngModel)]="etape1.departementNaissance" formControlName="departementNaissance">
              <span class="text-danger" *ngIf="etape1form.controls['departementNaissance'].hasError('required') && (etape1form.controls['departementNaissance'].dirty || etape1form.controls['departementNaissance'].touched)">* Champ obligatoire</span>
            </div>
            <div class="form-group col-md-6">
              <label for="inputPassword4">Lieu de naissance<span style="color:red"> *</span> :</label>
              <input type="text" class="form-control" id="inputPassword4" placeholder="Lieu de naissance" style="text-transform: capitalize;" [(ngModel)]="etape1.lieuNaissance" formControlName="lieuNaissance">
              <span class="text-danger" *ngIf="etape1form.controls['lieuNaissance'].hasError('required') && calendarDeploy && (etape1form.controls['lieuNaissance'].dirty || etape1form.controls['lieuNaissance'].touched)">* Champ obligatoire</span>
            </div>
          </div>-->
         
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="exampleFormControlSelect1">Lieu de naissance<span style="color:red"> *</span> :</label>   <br/>
              
              <p-dropdown *ngIf="referentielService.villesDropDown.length" [options]="referentielService.villesDropDown" placeholder="--- Choisir ---" formControlName="lieuNaissance" [(ngModel)]="etape1.lieuNaissance" [virtualScroll]="true" itemSize="30"  [style]="{'width':'100%'}"  [filter]="true"></p-dropdown>
            
              <span class="text-danger" *ngIf="etape1form.controls['lieuNaissance'].hasError('required') && (etape1form.controls['lieuNaissance'].dirty || etape1form.controls['lieuNaissance'].touched)">* Champ obligatoire</span>
              <!--<select class="form-control" id="exampleFormControlSelect1" formControlName="lieuNaissance" [(ngModel)]="etape1.lieuNaissance">
                <cdk-virtual-scroll-viewport style="height: 500px" itemSize="90">
                <option value="">-- Choisir --</option>
               
                <option *cdkVirtualFor="let ville of referentielService.villes" [ngValue]="ville">{{ville}}</option>
              </cdk-virtual-scroll-viewport>
              </select> -->
            </div>
            <div class="form-group col-md-6"></div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">  <span *ngIf="etape1.lieuNaissance == 'Hors du territoire national'">Document de condition de nationalité à nous renvoyer complété : <a target="_blank" href="/assets/pdfs/Declaration_relative_a_la_nationalite.pdf">à télécharger ici</a></span></div>
          </div>
       
          <hr/>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="inputEmail4">Adresse<span style="color:red"> *</span> : </label>
              <input type="text" class="form-control" id="inputEmail4" placeholder="Adresse" [(ngModel)]="etape1.adresse" formControlName="adresse">
              <span class="text-danger" *ngIf="etape1form.controls['adresse'].hasError('required') && (etape1form.controls['adresse'].dirty || etape1form.controls['adresse'].touched)">* Champ obligatoire</span>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="exampleFormControlSelect1">Code postal/Ville<span style="color:red"> *</span> :</label>   <br/>
              
              <p-dropdown *ngIf="referentielService.villesDropDown.length" [options]="referentielService.villesDropDown" placeholder="--- Choisir ---" formControlName="cpVille" [(ngModel)]="etape1.cpVille" [virtualScroll]="true" itemSize="30"  [style]="{'width':'100%'}"  [filter]="true"></p-dropdown>
            
              <span class="text-danger" *ngIf="etape1form.controls['cpVille'].hasError('required') && (etape1form.controls['cpVille'].dirty || etape1form.controls['cpVille'].touched)">* Champ obligatoire</span>
              <!--<select class="form-control" id="exampleFormControlSelect1" formControlName="lieuNaissance" [(ngModel)]="etape1.lieuNaissance">
                <cdk-virtual-scroll-viewport style="height: 500px" itemSize="90">
                <option value="">-- Choisir --</option>
               
                <option *cdkVirtualFor="let ville of referentielService.villes" [ngValue]="ville">{{ville}}</option>
              </cdk-virtual-scroll-viewport>
              </select> -->
            </div>
            <div class="form-group col-md-6"></div>
          </div>

          <!--<div class="form-row">
            <div class="form-group col-md-6">
              <label for="inputEmail4">Code postal<span style="color:red"> *</span> : </label>
              <input type="text" class="form-control" id="inputEmail4" placeholder="Code postal" [(ngModel)]="etape1.codePostal" formControlName="codePostal" >
              <span class="text-danger" *ngIf="etape1form.controls['codePostal'].hasError('required') && (etape1form.controls['codePostal'].dirty || etape1form.controls['codePostal'].touched)">* Champ obligatoire</span>
            </div>
            <div class="form-group col-md-6">
              <label for="inputPassword4">Ville<span style="color:red"> *</span> :</label>
              <input type="text" class="form-control" id="inputPassword4" placeholder="Ville" oninput="this.value = this.value.toUpperCase()" [(ngModel)]="etape1.ville" formControlName="ville">
              <span class="text-danger" *ngIf="etape1form.controls['ville'].hasError('required') && (etape1form.controls['ville'].dirty || etape1form.controls['ville'].touched)">* Champ obligatoire</span>
            </div>
          </div>-->


          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="exampleFormControlSelect1">Pays<span style="color:red"> *</span> :</label>   
              <select class="form-control" id="exampleFormControlSelect1" formControlName="pays" [(ngModel)]="etape1.pays">
                <option value="">-- Choisir --</option>
                <option *ngFor="let year of referentielService.pays" [ngValue]="year">{{year}}</option>
              </select> 
            </div>
          </div>






          <hr/>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="inputEmail4">Numéro de téléphone<span style="color:red"> *</span> : </label>
            <!--  <input type="text" [textMask]="{mask: mask}" class="form-control" id="inputEmail4" placeholder="Numéro de téléphone" [(ngModel)]="etape1.telephone" formControlName="telephone">-->
            
              <ngx-intl-tel-input
              [preferredCountries]="prefCountry"
              [enableAutoCountrySelect]="false"
              [enablePlaceholder]="false"
              [searchCountryFlag]="true"
              [searchCountryField]=""
              [selectFirstCountry]="true"
              [selectedCountryISO]="phonePays"
              [maxLength]="15"
              [separateDialCode]="true"
              [phoneValidation]="true"
              [inputId]="my-input-id"
              [searchCountryPlaceholder]="'Rechercher un pays'"
              (ngModelChange)="checkNumber($event)"
              [ngModel]="phoneObject"
              formControlName="telephone"
              name="phoneObject"
              #myname

          ></ngx-intl-tel-input>
          <br/><span class="text-danger" *ngIf="etape1form.controls['telephone'].hasError('required') &&  etape1form.controls['telephone'].touched">* Champ obligatoire</span>
            <br/><span class="text-danger" *ngIf="etape1form.controls['telephone'].invalid && ( etape1form.controls['telephone'].touched)">* Format invalide</span>
            </div>
   
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="inputEmail4">Adresse mail<span style="color:red"> *</span> : </label>
              <input type="text" class="form-control" id="inputEmail4" placeholder="Adresse mail" [(ngModel)]="etape1.mail" formControlName="mail" readonly>
              <span class="text-danger" *ngIf="etape1form.controls['mail'].hasError('required') && (etape1form.controls['mail'].dirty || etape1form.controls['mail'].touched)">* Champ obligatoire</span>
              <span class="text-danger" *ngIf="etape1form.controls['mail'].hasError('pattern') && (etape1form.controls['mail'].dirty || etape1form.controls['mail'].touched)">Adresse mail invalide</span>
            </div>
          </div>



        <hr/><br/>

        <!--
          <div class="form-group">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="gridCheck" (change)="checkbox1($event)" [(ngModel)]="etape1.autorisationCommunicationMail" formControlName="autorisationCommunicationMail">
              <label class="form-check-label" for="gridCheck">
                  Autorise l’Ecole à communiquer mon adresse mail afin de bénéficier des accès aux bases juridiques et permettre au Conseil National des Barreaux d’adresser ses lettres électroniques, afin de mieux communiquer l’information professionnelle.
                 <span *ngIf="!marked1" style="color:red"><br/>Cela vous priverait d'accéder aux bases juridiques documentaires</span>
                </label>
            </div>
            <span class="text-danger" *ngIf="etape1form.controls['autorisationCommunicationMail'].hasError('required') && (etape1form.controls['autorisationCommunicationMail'].dirty || etape1form.controls['autorisationCommunicationMail'].touched)">* Champ obligatoire</span>

          </div>

          
          <div class="form-group">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="gridCheck" (change)="checkbox2($event)" [(ngModel)]="etape1.autorisationDroitsImage" formControlName="autorisationDroitsImage">
              <label class="form-check-label" for="gridCheck">
                  Autorise l’Ecole à diffuser sur le portail d’IXAD les éventuelles photos prises lors de la formation en vue de promouvoir les diverses activités proposées.
                  <span *ngIf="!marked2" style="color:red"><br/>Cela vous priverait de participer à la promotion d'IXAD</span>
                </label>
              <span class="text-danger" *ngIf="etape1form.controls['autorisationDroitsImage'].hasError('required') && (etape1form.controls['autorisationDroitsImage'].dirty || etape1form.controls['autorisationDroitsImage'].touched)">* Champ obligatoire</span>
              <br/>
              
            </div>
          </div>
          -->
          <div class="form-group">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="gridCheck2" (change)="checkboxCnb($event)" [(ngModel)]="etape1.cocheCnb" formControlName="cocheCnb">
              <label class="form-check-label" for="gridCheck2">
                J’ai pris connaissance de la politique de protection des données personnelles. <span class="text-danger" *ngIf="!markedCnb">* obligatoire</span>
                </label>
             
                <br/> <a target="_blank" href="/assets/pdfs/etape4/ixad_politique_ext.pdf">Politique de protection des données personnelles</a> 
                <!--<a target="_blank" style="color:blue; cursor:default" onClick="MyWindow=window.open('/formulaire/noticeCnb','MyWindow','width=600,height=300'); return false;">Notice d'information</a>-->
                <!--<br/> <br/><a target="_blank" style="color:blue; cursor:default" onClick="MyWindow=window.open('/formulaire/rgpd','MyWindow','width=600,height=300'); return false;">En savoir plus sur la protection des données personnelles</a>-->
            </div>
          </div>

          
         
          <h6 style="color:red;" *ngIf="isPreInscrit"><br/><br/>Votre pré-inscription est envoyée, vos modifications ne seront pas enregistrées</h6>

          <br/><br/>
          <div class="form-row">
            <div class="form-group col-md-6 text-right"><img src="/assets/img/loader-orange.gif" style="width:40px" *ngIf="loading2" /></div>
            <div class="form-group col-md-6">
              
              <input type="button" class="btn btn-primary" (click)="next()" value="Suivant">
            </div>
            <div class="form-group col-md-4"></div>
            
          </div>
         
        </div>
      </div>
    </div>
  </div>
  </div>
  