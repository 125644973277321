import { Component, OnInit } from '@angular/core';
import {AccordionModule} from 'primeng/accordion';
import { ReferentielService } from 'src/app/services/referentiel.service';
import { FormulaireService } from 'src/app/services/formulaire/formulaire.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router } from '@angular/router';
import { Formulaire } from 'src/app/model/formulaire';
import { Etape1 } from 'src/app/model/formulaire/etape1';
import { Etape2 } from 'src/app/model/formulaire/etape2';
import { Etape3 } from 'src/app/model/formulaire/etape3';
import { Etape4 } from 'src/app/model/formulaire/etape4';
import { Retour } from 'src/app/model/retour';

@Component({
  selector: 'app-etape5',
  templateUrl: './etape5.component.html',
  styleUrls: ['./etape5.component.css']
})
export class Etape5Component implements OnInit {

  public etape1 : Etape1 = null;
  public etape2 : Etape2 = null;
  public etape3 : Etape3 = null;
  public etape4 : Etape4 = null;
  public isPreInscrit:boolean = false;
  public loadedService1 = false;
  public loadedService2 = false;
  public loadedService3 = false;
  public loadedService4 = false;



  public hasPreInscriptionValide:boolean=false;
  public loading2 = false;
  public isInscrit:boolean = false;
  public mailReg = new RegExp("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$");
  
  constructor(public formulaireService: FormulaireService, public authService: AuthService, public router: Router) { }

  ngOnInit(): void {

    this.loadedService1 = false;
    this.loadedService2 = false;
    this.loadedService3 = false;
    this.loadedService4 = false;

  
    if(this.authService.isLoggedIn) {
      this.refreshInscriptionValid();
    }
  }

  public refreshInscriptionValid()
  {
    const user  =  JSON.parse(localStorage.getItem('user'));
     
    this.formulaireService.defineInscriptionValideIxad(user.uid).subscribe((data :any)=>{
      this.hasPreInscriptionValide = data.hasPreInscriptionValideIxad;  
      if(this.hasPreInscriptionValide)
        this.router.navigate(['formulaire/etape6']);
      else
        this.defineIsPreInscrit(user);

  
    },
    error => {
    });
  }

  public defineIsPreInscrit(user){
    this.formulaireService.defineIsPreInscrit(user.uid).subscribe((data :any)=>{

      this.isPreInscrit = data.isPreInscrit;   
      if(this.isPreInscrit)
      {
        this.loadedService1 = true;
        this.loadedService2 = true;
        this.loadedService3 = true;
        this.loadedService4 = true;
      
        this.isPreInscrit = true;
        //this.router.navigate(['formulaire/prevalid']);
        this.loadEtapes();
      }
      else{
        this.loadEtapes();
      }
    },
    error => {

    });
  }

  public loadEtapes()
  {
    const user  =  JSON.parse(localStorage.getItem('user'));
    this.formulaireService.loadEtape1(user.uid).subscribe((data:any)=>{
    if(data.etape1 != null)
    {
      this.etape1 = new Etape1();
      Formulaire.convertJsonToObject(this.formulaireService.formulaire, data);
      Object.assign(this.etape1, this.formulaireService.formulaire.etape1);
     
    }
    this.loadedService1 = true;
  });
    this.formulaireService.loadEtape2(user.uid).subscribe((data:any)=>{
    if(data.etape2 != null)
    {
      this.etape2 = new Etape2();
      Formulaire.convertJsonToObject(this.formulaireService.formulaire, data);
      Object.assign(this.etape2, this.formulaireService.formulaire.etape2);
     
    }
    this.loadedService2 = true;
  });
    this.formulaireService.loadEtape3(user.uid).subscribe((data:any)=>{
    if(data.etape3 != null)
    {
      this.etape3 = new Etape3();
      Formulaire.convertJsonToObject(this.formulaireService.formulaire, data);
      Object.assign(this.etape3, this.formulaireService.formulaire.etape3);
    
    }
    this.loadedService3 = true;
  });
    this.formulaireService.loadEtape4(user.uid).subscribe((data:any)=>{
    if(data.etape4 != null)
    {
      this.etape4 = new Etape4();
      Formulaire.convertJsonToObject(this.formulaireService.formulaire, data);
      Object.assign(this.etape4, this.formulaireService.formulaire.etape4);
     
    }
    this.loadedService4 = true;
  });
  }




  public previous(){
    

    if(this.etape2.q1souhait == 'option3' || this.etape2.q2admission == "Auditeur_libre_etranger")
    {
      this.router.navigate(['formulaire/etape2']);
    }
    else{
      this.router.navigate(['formulaire/etape4']);
    }

   
  }

  public valider(){

    this.loading2 = true;
    this.formulaireService.setDataEtape5().subscribe((result:any) => {
      if(result.retour)
      {
        
        if(result.retour == "true")
        {
          this.loading2 = false;
          this.refreshInscriptionValid();
          this.router.navigate(['formulaire/prevalid']);
         
        }
        else{
          alert(result.retour);
          this.loading2 = false;
         
        }

      }
      else{
        this.loading2 = false;
      }
    },
    err => {this.loading2 = false;  alert("Erreur de service"); console.log('HTTP Error', err)},
    )



  }

  public next(){
    

    if(this.isPreInscrit && this.hasPreInscriptionValide)
    {
    
            this.router.navigate(['formulaire/etape6']);
          

    }
 

  }
}
