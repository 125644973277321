import { Component, ElementRef, OnInit } from '@angular/core';
import {AccordionModule} from 'primeng/accordion';
import { ReferentielService } from 'src/app/services/referentiel.service';
import { FormulaireService } from 'src/app/services/formulaire/formulaire.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Formulaire } from 'src/app/model/formulaire';
import { Etape1 } from 'src/app/model/formulaire/etape1';
import { Etape2 } from 'src/app/model/formulaire/etape2';
import { Etape3 } from 'src/app/model/formulaire/etape3';
import { Etape4 } from 'src/app/model/formulaire/etape4';
import { Retour } from 'src/app/model/retour';
import { Etape6 } from 'src/app/model/formulaire/etape6';
import { InformationPaiementRequest } from 'src/app/model/InformationPaiementRequest';
import { PaiementService } from 'src/app/services/formulaire/paiement.service';
import { ViewChild } from '@angular/core';


@Component({
  selector: 'app-valid',
  templateUrl: './valid.component.html',
  styleUrls: ['./valid.component.css']
})
export class ValidComponent implements OnInit {

  public informationPaiementRequest : InformationPaiementRequest = new InformationPaiementRequest();

  @ViewChild('form1') testFormElement;
  public etape1 : Etape1 = null;
  public etape2 : Etape2 = null;
  public etape3 : Etape3 = null;
  public etape4 : Etape4 = null;
  public etape6 : Etape6 = null;
  public isPreInscrit:boolean = false;
  public loadedService1 = false;
  public loadedService2 = false;
  public loadedService3 = false;
  public loadedService4 = false;
  public loadedService5 = false;
  public loadedService6 = false;
  public hasPreInscriptionValide:boolean=false;
  public loading2 = false;
  public transacResult:string = null;
  public isInscrit:boolean = false;
  public checkPaiem:boolean = true;
  public mailReg = new RegExp("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$");
  public msgRetourPaiement:string = "";
  
  constructor(private route: ActivatedRoute,public paiementService:PaiementService, public formulaireService: FormulaireService, public authService: AuthService, public router: Router) { }

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {

      this.transacResult =  params['vads_trans_status']; 
      if(this.authService.isLoggedIn) {
        this.refreshData();
      }
    });
  
    
  }

  public previous(){
    
    this.router.navigate(['formulaire/etape6']);
  }

  public refreshData()
  {
    this.loadedService1 = false;
    this.loadedService2 = false;
    this.loadedService3 = false;
    this.loadedService4 = false;
    this.loadedService5 = false;
    this.loadedService6 = false;

    if(this.authService.isLoggedIn) {
      const user  =  JSON.parse(localStorage.getItem('user'));
      this.formulaireService.defineInscriptionValideIxad(user.uid).subscribe((data :any)=>{
       
        this.hasPreInscriptionValide = data.hasPreInscriptionValideIxad;  
        this.loadedService5 = true;
        if(!this.hasPreInscriptionValide)
        {
         // this.router.navigate(['formulaire/etape5']);
        }
      },
      error => {
      
      });

     
      this.formulaireService.loadEtape1(user.uid).subscribe((data:any)=>{
      if(data.etape1 != null)
      {
        this.etape1 = new Etape1();
        Formulaire.convertJsonToObject(this.formulaireService.formulaire, data);
        Object.assign(this.etape1, this.formulaireService.formulaire.etape1);
     
      }
      this.loadedService1 = true;
    });
      this.formulaireService.loadEtape2(user.uid).subscribe((data:any)=>{
      if(data.etape2 != null)
      {
        this.etape2 = new Etape2();
        Formulaire.convertJsonToObject(this.formulaireService.formulaire, data);
        Object.assign(this.etape2, this.formulaireService.formulaire.etape2);
        if(this.etape2.q1souhait == 'option3' ||  this.etape2.q2admission == 'Auditeur_libre_etranger'){
          this.router.navigate(['formulaire/etape2']);
        }
      }
      this.loadedService2 = true;
    });
      this.formulaireService.loadEtape3(user.uid).subscribe((data:any)=>{
      if(data.etape3 != null)
      {
        this.etape3 = new Etape3();
        Formulaire.convertJsonToObject(this.formulaireService.formulaire, data);
        Object.assign(this.etape3, this.formulaireService.formulaire.etape3);
    
      }
      this.loadedService3 = true;
    });
    this.formulaireService.loadEtape4(user.uid).subscribe((data:any)=>{
      if(data.etape4 != null)
      {
        this.etape4 = new Etape4();
        Formulaire.convertJsonToObject(this.formulaireService.formulaire, data);
        Object.assign(this.etape4, this.formulaireService.formulaire.etape4);
      
      }
      this.loadedService4 = true;
    });

    this.formulaireService.loadEtape6(user.uid).subscribe((data:any)=>{
      if(data.etape6 != null)
      {
        this.etape6 = new Etape6();
        Formulaire.convertJsonToObject(this.formulaireService.formulaire, data);
        Object.assign(this.etape6, this.formulaireService.formulaire.etape6);
       
      }
      else{
        //this.router.navigate(['formulaire/etape6']);
      }
      this.loadedService6 = true;
    });

  


    this.formulaireService.defineIsInscrit(user.uid).subscribe((data :any)=>{
   
      this.isInscrit = data.isInscrit;   

    },
    error => {

    });
        

    this.formulaireService.checkPaiem(user.uid).subscribe((data :any)=>{
     
      this.checkPaiem = data.checkPaiem;   

      if(this.transacResult == 'AUTHORISED'){
        this.checkPaiem = true;  
        this.msgRetourPaiement = 'Transaction autorisée';
      }
      else if(this.transacResult == 'ABANDONED')
      {
        this.checkPaiem = false;  
        this.msgRetourPaiement = 'Transaction abandonnée';
      } else if(this.transacResult == 'CANCELLED')
      {
        this.checkPaiem = false;  
        this.msgRetourPaiement = 'Transaction annulée';
      } else if(this.transacResult == 'EXPIRED')
      {
        this.checkPaiem = false;  
        this.msgRetourPaiement = 'Transaction expirée';
      } else if(this.transacResult == 'REFUSED')
      {
        this.checkPaiem = false;  
        this.msgRetourPaiement = 'Transaction refusée';
      }

    
    },
    error => {

    });


      }
  }


  public payer(){

    // TODO ajouter contrôle back pour savoir si déjà payé

    const user  =  JSON.parse(localStorage.getItem('user'));
    this.informationPaiementRequest.vads_cust_id = user.uid;
    this.paiementService.createPaiement(this.informationPaiementRequest).subscribe((result:any) => {

      if(result)
      {
        if(result.retour != null)
        {
          this.loading2 = false;
         // this.refreshData();
          this.informationPaiementRequest = result.retour;
          
          setTimeout(()=>{
            this.testFormElement.nativeElement.submit();   // actual form submit from controller
         } , 0);
         
          
         
        }
        else{
          alert("Erreur, vérifiez les champs de l'étape complément et/out rafraichissez cette page.");
          this.loading2 = false;
        }

      }
      else{
        alert("Erreur, Veuillez rafraîchir cette page.");
        this.loading2 = false;
      }
    },
    err => console.log('HTTP Error', err),
    );


  }

  public valider(){

    if(!this.isInscrit){
    this.loading2 = true;
    this.formulaireService.finaliseInscription().subscribe((result:any) => {
      if(result.retour)
      {
        if(result.retour == 'true')
        {
          this.loading2 = false;
          this.refreshData();
          this.router.navigate(['formulaire/valid']);
         
        }
        else{
          alert(result.retour);
          this.loading2 = false;
         
        }

      }
      else{
        this.loading2 = false;
      }
    },
    err => {this.loading2 = false;  alert("Erreur de service"); console.log('HTTP Error', err)},
    )
  }



  }


}
