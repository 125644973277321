import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { Observable, } from 'rxjs';
import 'rxjs/add/observable/fromPromise';
import { map } from 'rxjs/operators';
import 'rxjs/add/operator/mergeMap'
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ReferentielService {

  constructor(private httpClient: HttpClient, public  afAuth:  AngularFireAuth, public  router:  Router,  public ngZone: NgZone) { 

    this.loadAnnee("");
    this.loadBacData("");
    this.loadLicenceData("");
    this.loadMasterData("");
    this.loadPays("");
    this.loadVille("");
    
  }

  public annees;
  public bacFiliere;
  public licenceFiliere;
  public masterFiliere;
  public pays;
  public villes;
  public villesDropDown =[];

  getToken() : Promise<string>{
    return new Promise((resolve, reject) => {
      this.afAuth.onAuthStateChanged(function(user) {
      if (user) {
        user.getIdToken().then(function(data) {
          resolve(data);
        }).catch(reject);
      } else {
        reject('Error while executing onAuthStateChanged');
      }
      });
    });
  }

  loadAnnee(uid:string)
  {

    this.getToken().then(token => {
     
      const httpOptions = {
        headers: new HttpHeaders({
          'X-Firebase-Auth':  token
        })
      };
     
      this.httpClient.get(environment.getReferentielAnneeUrl, httpOptions).subscribe(data => {
        this.annees = data;
      });
    });
  
    
    
    
  }

  loadBacData(uid:string)
  {
    this.getToken().then(token => {
     
      const httpOptions = {
        headers: new HttpHeaders({
          'X-Firebase-Auth':  token
        })
      };
     
      this.httpClient.get(environment.getReferentielBacUrl, httpOptions).subscribe((data)=>{

        this.bacFiliere = data;
           
      });
    });

    
  }

  loadLicenceData(uid:string)
  {
    this.getToken().then(token => {
     
      const httpOptions = {
        headers: new HttpHeaders({
          'X-Firebase-Auth':  token
        })
      };
     
      this.httpClient.get(environment.getReferentielLicenceUrl, httpOptions).subscribe((data)=>{

        this.licenceFiliere = data;
           
      });
    });
    
    
  }

  loadMasterData(uid:string)
  {
    this.getToken().then(token => {
     
      const httpOptions = {
        headers: new HttpHeaders({
          'X-Firebase-Auth':  token
        })
      };
     
      this.httpClient.get(environment.getReferentielMasterUrl, httpOptions).subscribe((data)=>{

        this.masterFiliere = data;
           
      });
    });
  
  }

  loadPays(uid:string)
  {
    this.getToken().then(token => {
     
      const httpOptions = {
        headers: new HttpHeaders({
          'X-Firebase-Auth':  token
        })
      };
     
      this.httpClient.get(environment.getReferentielPaysUrl, httpOptions).subscribe((data)=>{

        this.pays = data;
          
      });
    });
    
  }

  loadVille(uid:string)
  {
    this.getToken().then(token => {
     
      const httpOptions = {
        headers: new HttpHeaders({
          'X-Firebase-Auth':  token
        })
      };
     
      this.httpClient.get(environment.getReferentielVillesUrl, httpOptions).subscribe((data)=>{
        this.villes = data;
        this.villesDropDown.push({label: "Hors du territoire national", value: "Hors du territoire national"});
        for(let key in this.villes)
        { 
          
            this.villesDropDown.push({label: this.villes[key], value: this.villes[key]});
        }
          
      });
    });
    
  }



}
