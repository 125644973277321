<div class="container contact">
    <div class="row">
      <div class="col-md-3">
        <div class="contact-info">
       
          <a routerLink="/login"><img src="/assets/img/logo-ixad.png" class="img-fluid" alt="image"/></a>
          <h2 style="color:white;" class="anneeStyle">INSCRIPTION {{ authService.anneeInscription1 }} / {{ authService.anneeInscription2 }}</h2>
          <br/><br/><br/><i style="color:white">Pour toute question ou problème technique lors du remplissage du formulaire, merci d'envoyer un mail à <a href="mailto:inscription@ixad.fr">inscription@ixad.fr</a></i>
          <h4></h4>
        </div>
      </div>
      <div class="col-md-9">
        <h4>Connexion</h4><br/>
      
        
        
        <p>
          <span style="color:red" *ngIf="authService.params[5]?.valeurParametre">
            <b>IMPORTANT : </b> {{ authService.params[5]?.valeurParametre }}
          </span>

        </p>


        <div class="contact-form" >


          <div class="form-row " *ngIf="authService.params[1]?.valeurParametre == 'true'">

            <div class="form-group col-md-6 ">
              <label for="inputEmail4">Login (adresse e-mail) : </label>
              <input type="text" class="form-control" [(ngModel)]="loginUser" placeholder="utilisateur@mail.com"  required autofocus>
            </div>
         
           
          </div>
          
          <div class="form-row" *ngIf="authService.params[1]?.valeurParametre == 'true'">
  
            <div class="form-group col-md-6">
              <label for="inputPassword4">Password :</label>
              <input type="password" class="form-control"  [(ngModel)]="passwordUser" placeholder="password" required>


            </div>
           
           
          </div>

          <div class="form-row">
  
            <div class="form-group col-md-6">
              <b><i><span style="color:red">{{errorMessage}}</span></i></b>
            </div>
           
           
          </div>

          <span *ngIf="authService.params[1]?.valeurParametre == 'true'">
            <p>Mot de passe oublié ?<a routerLink="/forgot-password"> Cliquez-ici </a> pour le récupérer</p>
            
          </span>
         <span *ngIf="authService.params[0]?.valeurParametre == 'true'">
          <p>Pas encore de compte ?<a routerLink="/register"> Cliquez-ici </a> pour en créer un</p>
          
        </span>
          

         
          <br/>
          <div class="form-row text-center" *ngIf="authService.params[1]?.valeurParametre == 'true'">
          
            <div class="form-group col-md-10 text-center">
                <button type="button" class="btn btn-primary" (click)="login()">Connexion</button>
          
            </div>
      
            
          </div>
          
          
        </div>
      </div>
    </div>
  </div>
