import { Serializable } from '../serializable';

export class Etape6 extends Serializable  {

    constructor()
    {
        super();
        this.idInscription = null;
        this.uid = "";
        this.q1ChoixPaiement="";

        this.nomFichierFicheEngagementHonneur = "";
        this.nomFichierAttestationReussiteExamen = "";
        this.nomFichierReleveNotesExamen = "";
        this.nomFichierAttestationAssurance = "";
        this.nomFichierCharteConfidentialite = "";
       
        this.nomFichierRIB = "";
        this.nomFichierFranceTravail = "";

        this.nomFichierDroitImage = "";
        this.nomFichierCV = "";
        this.q2cocheEngagement="";
        this.q3cocheCharteInformatique = "";

        this.q4dispositionOrdinateur = null;
        this.q4dispositionOrdinateurPrecision = null;

        this.q5protectionDonnees = "";
        this.q6CocheCharteCourtoisie = "";
        this.q7cocheCnb = "";
    
       // this.q9charteConfidentialite = "";
        this.q10condamnation = "";
        this.q11CocheCharteIxad = "";


    }
    idInscription:Number;
    uid:string;
    q1ChoixPaiement:string; // à delete
    nomFichierFicheEngagementHonneur:string;
    nomFichierAttestationReussiteExamen:string;
    nomFichierReleveNotesExamen:string;
    nomFichierAttestationAssurance:string;
    nomFichierCharteConfidentialite:string;

    nomFichierRIB:string;
    nomFichierFranceTravail:string;
   
    nomFichierDroitImage:string;
    nomFichierCV:string;
    q2cocheEngagement:string;
    q3cocheCharteInformatique:string;
    q4dispositionOrdinateur:string;
    q4dispositionOrdinateurPrecision:string;
    q5protectionDonnees:string;
    q6CocheCharteCourtoisie:string;
    q7cocheCnb:string;
    q11CocheCharteIxad:string;

  //  q9charteConfidentialite:string;
    q10condamnation:string;


}
