import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ParametreService {
 

  constructor(private httpClient: HttpClient) { }
  
  public loadParams()
  {
    return this.httpClient.get(environment.getLoadParamUrl);
  }



}
