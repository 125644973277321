import { Component, OnInit, ViewChild, ElementRef  } from '@angular/core';
import { FormulaireService } from 'src/app/services/formulaire/formulaire.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Etape4 } from 'src/app/model/formulaire/etape4';
import { ReferentielService } from 'src/app/services/referentiel.service';
import { Router } from '@angular/router';
import { Formulaire } from 'src/app/model/formulaire';
import { Retour } from 'src/app/model/retour';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Etape1 } from 'src/app/model/formulaire/etape1';
import { Etape2 } from 'src/app/model/formulaire/etape2';

@Component({
  selector: 'app-etape4',
  templateUrl: './etape4.component.html',
  styleUrls: ['./etape4.component.css']
})
export class Etape4Component implements OnInit {


  public isDocteurDroit:boolean = false;
  public isPreInscrit:boolean = false;
  public etape4 : Etape4 = new Etape4();
  public etape1 : Etape1 = new Etape1();
  public etape2 : Etape2 = new Etape2();
  public hasActiviteExt:boolean=false;

  public fichierDocPieceIdentite : File = null;
  public fichierDiplomeMaster1Maitrise : File = null;
  public fichierDiplomeMaster2Maitrise : File = null;
  public fichierThese:File = null;
  public fichierDiplomeDocteurDroit:File = null;
  public fichierAttestationSoutenanceEcoleDoctorat:File = null;

 
  public fichierNationalite:File = null;
  public fichierEngagementActiviteExt:File = null;
  public fichierAutorisationCumulActiviteExt:File = null;
  

  public loadedService1 = false;
  public loadedService2 = false;
  public loadedService4 = false;

  public lieuNaissance = "";
 
  public loading2 = false;
  public etape4form:FormGroup;

  public isInscrit:boolean = false;

  public hasPreInscriptionValide:boolean=false;
  public nomUsageNotPresent:boolean=false;

  @ViewChild('childFichierDocPieceIdentite')
  childFichierDocPieceIdentite: ElementRef;
  @ViewChild('childFichierDiplomeMaster1Maitrise')
  childFichierDiplomeMaster1Maitrise: ElementRef;
  @ViewChild('childFichierDiplomeMaster2Maitrise')
  childFichierDiplomeMaster2Maitrise: ElementRef;
  @ViewChild('childFichierThese')
  childFichierThese: ElementRef;
  @ViewChild('childFichierDiplomeDocteurDroit')
  childFichierDiplomeDocteurDroit: ElementRef;
  @ViewChild('childFichierAttestationSoutenanceEcoleDoctorat')
  childFichierAttestationSoutenanceEcoleDoctorat: ElementRef;
  @ViewChild('childFichierNationalité')
  childFichierNationalité: ElementRef;
  @ViewChild('childFichierAutorisationCumulActiviteExt')
  childFichierAutorisationCumulActiviteExt: ElementRef;
  @ViewChild('childFichierEngagementActiviteExt')
  childFichierEngagementActiviteExt: ElementRef;
  


  constructor(public fb: FormBuilder, public referentielService:ReferentielService, public formulaireService: FormulaireService, public authService: AuthService, public router: Router) {

    this.etape4form = fb.group({
      
      'nomFichierDocPieceIdentite': [null, Validators.required],
      'nomFichierDiplomeMaster1Maitrise': [null, Validators.required],
      'nomFichierDiplomeMaster2Maitrise': [null, Validators.required],
      'nomFichierAttestationSoutenanceEcoleDoctorat': [null, Validators.required],
      'nomFichierDiplomeDocteurDroit': [null, Validators.required],
      'nomFichierThese': [null, Validators.required],
      'nomFichierNationalite': [null, Validators.required],
      'nomFichierEngagementActiviteExt': [null, Validators.required],
      'nomFichierAutorisationCumulActiviteExt': [null, Validators.required],
      
    });
    
   }

  ngOnInit(): void {


    this.loadedService1 = false;
    this.loadedService2 = false;
   
    this.loadedService4 = false;
    this.loadFiles();
   

  }
  
  reset(element:ElementRef) {
    
    element.nativeElement.value = "";
    
}

  loadFiles() {
     if(this.authService.isLoggedIn) {
  
      const user  =  JSON.parse(localStorage.getItem('user'));
      this.formulaireService.defineInscriptionValideIxad(user.uid).subscribe((data :any)=>{
        this.hasPreInscriptionValide = data.hasPreInscriptionValideIxad;  
        if(this.hasPreInscriptionValide)
          this.router.navigate(['formulaire/etape6']);
        else
          this.loadDataEtape2();

      },
      error => {
      });

    } 
  }

  public loadDataEtape2()
  {
    const user  =  JSON.parse(localStorage.getItem('user'));
    this.formulaireService.loadEtape2(user.uid).subscribe((data:any)=>{
      
      if(data.etape2 != null)
      {
        Formulaire.convertJsonToObject(this.formulaireService.formulaire ,data);
        this.etape2 = Object.assign(this.etape2, this.formulaireService.formulaire.etape2);
        if(this.etape2.q1souhait == 'option3' ||  this.etape2.q2admission == 'Auditeur_libre_etranger'){
          this.router.navigate(['formulaire/etape2']);
        }
        else{
          this.defineIsPreInscrit(user);
        }
      }
      else{
          this.etape2.uid =  user.uid;
      }
    },
    error => {
    });
  }

  public defineIsPreInscrit(user){
    this.formulaireService.defineIsPreInscrit(user.uid).subscribe((data :any)=>{

      this.isPreInscrit = data.isPreInscrit;   
      if(this.isPreInscrit)
      {
        this.loadedService1 = true;
        this.loadedService2 = true;
     
        this.loadedService4 = true;
        this.isPreInscrit = true;
        //this.router.navigate(['formulaire/prevalid']);
        this.loadEtapes();
      }
      else{
        this.loadEtapes();
      }
    },
    error => {

    });
  }

  public loadEtapes()
  {
    const user  =  JSON.parse(localStorage.getItem('user'));
    this.formulaireService.loadEtape4(user.uid).subscribe((data :any)=>{
      if(data.etape4 != null)
      {
        Formulaire.convertJsonToObject(this.formulaireService.formulaire ,data);
        this.etape4 = Object.assign(this.etape4, this.formulaireService.formulaire.etape4);
        this.applyMandatory();
      }
      else{
          this.etape4.uid =  user.uid;
      }
      this.loadedService1 = true;
    },
    error => {
    });

    this.formulaireService.loadEtape1(user.uid).subscribe((data:any)=>{
      if(data.etape1 != null)
      {
        Formulaire.convertJsonToObject(this.formulaireService.formulaire, data);
        Object.assign(this.etape1, this.formulaireService.formulaire.etape1);
        this.lieuNaissance = this.etape1.lieuNaissance;
        if(this.etape1.nomUsage != null && this.etape1.nomUsage != '')
        {
          this.nomUsageNotPresent = false;
        }
        else{
          this.nomUsageNotPresent = true;
        }
          
      }
      else{
        this.lieuNaissance = "";
      }
    });

    this.formulaireService.defineIsDocteurDroit(user.uid).subscribe((data :any)=>{
    
      this.isDocteurDroit = data.isDocteurDroit;  
      this.loadedService2 = true;    
    },
    error => {
    
    });

    this.formulaireService.defineHasActiviteExt(user.uid).subscribe((data :any)=>{
       
      this.hasActiviteExt = data.hasActiviteExterieure;      
      this.loadedService4 = true;
    },
    error => {
    });
  

  }

  private applyMandatory(){

    if((this.etape4.nomFichierDiplomeDocteurDroit != null || this.etape4.nomFichierAttestationSoutenanceEcoleDoctorat!= null) && (this.etape4.nomFichierDiplomeDocteurDroit != "" || this.etape4.nomFichierAttestationSoutenanceEcoleDoctorat!= ""))
    {
      if(this.etape4.nomFichierDiplomeDocteurDroit != null && this.etape4.nomFichierDiplomeDocteurDroit != "")
      {
        this.etape4form.get('nomFichierAttestationSoutenanceEcoleDoctorat').clearValidators();
        this.etape4form.get('nomFichierAttestationSoutenanceEcoleDoctorat').updateValueAndValidity();

        
      }
      if(this.etape4.nomFichierAttestationSoutenanceEcoleDoctorat != null && this.etape4.nomFichierAttestationSoutenanceEcoleDoctorat != "")
      {
        this.etape4form.get('nomFichierDiplomeDocteurDroit').clearValidators();
        this.etape4form.get('nomFichierDiplomeDocteurDroit').updateValueAndValidity();
      }
    }
    if((this.etape4.nomFichierDiplomeDocteurDroit == null && this.etape4.nomFichierAttestationSoutenanceEcoleDoctorat == null) || (this.etape4.nomFichierDiplomeDocteurDroit == "" && this.etape4.nomFichierAttestationSoutenanceEcoleDoctorat == ""))
    {
      this.etape4form.get('nomFichierDiplomeDocteurDroit').setValidators([Validators.required]);
      this.etape4form.get('nomFichierAttestationSoutenanceEcoleDoctorat').setValidators([Validators.required]);
      this.etape4form.get('nomFichierDiplomeDocteurDroit').updateValueAndValidity();
      this.etape4form.get('nomFichierAttestationSoutenanceEcoleDoctorat').updateValueAndValidity();
    }

  }
  markFormTouched(group: FormGroup | FormArray) {
    
    Object.keys(group.controls).forEach((key: string) => {
      const control = group.controls[key];
      if (control instanceof FormGroup || control instanceof FormArray) { 
        control.markAsTouched(); 
        this.markFormTouched(control); 
      }
      else { control.markAsTouched(); };
    });
  };

  public previous(){
    Object.assign(this.formulaireService.formulaire.etape4, this.etape4);
    this.router.navigate(['formulaire/etape3']);
  }

  public next(){
    this.loading2 = true;
  
    if(!this.isPreInscrit)
    {
      Object.assign(this.formulaireService.formulaire.etape4, this.etape4);
      var files : File[] = new Array(8);
      files[0] = null;
      files[1] = null;
      files[2] = null;
      files[3] = null;
      files[4] = null;
      files[5] = null;
      files[6] = null;
      files[7] = null;
   
      





    
      if(this.fichierDocPieceIdentite !=null)
      {
        files[0] = this.fichierDocPieceIdentite;
      }
      if(this.fichierDiplomeDocteurDroit != null)
      {
        files[1] = this.fichierDiplomeDocteurDroit;
      }
      if(this.fichierAttestationSoutenanceEcoleDoctorat !=null)
      {
        files[2] = this.fichierAttestationSoutenanceEcoleDoctorat;
      }
      if(this.fichierThese !=null)
      {
        files[3] = this.fichierThese;
      }
      if(this.fichierDiplomeMaster1Maitrise !=null)
      {
        files[4] = this.fichierDiplomeMaster1Maitrise;
      }
      if(this.fichierDiplomeMaster2Maitrise !=null)
      {
        files[5] = this.fichierDiplomeMaster2Maitrise;
      }
      if(this.fichierNationalite != null)
      {
        files[6] = this.fichierNationalite;
      }
      /*
      if(this.fichierAutorisationCumulActiviteExt != null)
      {
        files[7] = this.fichierAutorisationCumulActiviteExt;
      }*/
      if(this.fichierEngagementActiviteExt != null)
      {
        files[7] = this.fichierEngagementActiviteExt;
      }
      
  



      this.formulaireService.setDataEtape4(this.etape4, files).subscribe((result:Retour) => {

        if(result.retour)
        {
          this.loading2 = false;
          this.router.navigate(['formulaire/etape5']);
        }
        else{
          this.loading2 = false;
          alert("Une erreur s'est produite. Veuillez recommencer")
        }

        this.loadFiles()

 
      },
      err => console.log('HTTP Error', err),
      );
     
  }
  else{
    this.loading2 = false;
    this.router.navigate(['formulaire/etape5']);
  }

  }

  public controleFileSize(file:File)
  {
    let retour = true;
    if((file.size == 0) || ((file.size/1042) > 8192) )
    {
      retour = false;
      alert("Taille de fichier incorrect");
    }
    return retour;
  }

  public controleExtension(file:File)
  {
    let retour = false;
    let ext = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase();
    if(ext == 'pdf' || ext == 'doc' || ext == 'docx')
    {
      retour = true;
    }
    else{
      alert("Cette extension de fichier n'est pas autorisée. Formats accéptés : PDF, DOC, DOCX");
    }
    return retour;
  }

  public onFileChangeCarteId(event){
    let reader = new FileReader();
   
    if(event.target.files && event.target.files.length > 0) {
      if(this.controleExtension(event.target.files[0]) && this.controleFileSize(event.target.files[0]))
      {
        this.fichierDocPieceIdentite = event.target.files[0];
      
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = () => {
         
          this.etape4.nomFichierDocPieceIdentite = event.target.files[0].name;
        };
      }
      else
      {
        this.reset(this.childFichierDocPieceIdentite);
      }
    }
    else{
      this.fichierDocPieceIdentite = null;
      this.etape4.nomFichierDocPieceIdentite = "";
    }
  }

  
  public onFileChangeDocEngagementActiviteExt(event){
    let reader = new FileReader();
    if(event.target.files && event.target.files.length > 0) {
      if(this.controleExtension(event.target.files[0]) && this.controleFileSize(event.target.files[0]))
      {
        this.fichierEngagementActiviteExt = event.target.files[0];
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = () => {
          this.etape4.nomFichierEngagementActiviteExt = event.target.files[0].name;
        };
      }
      else
      {
        this.reset(this.childFichierEngagementActiviteExt);
      }
    }
    else{
      this.fichierEngagementActiviteExt = null;
      this.etape4.nomFichierEngagementActiviteExt = "";
    }
  }

  /*
  public onFileChangeDocAutorisationCumulActiviteExt(event){
    let reader = new FileReader();
    if(event.target.files && event.target.files.length > 0) {
      if(this.controleExtension(event.target.files[0]) && this.controleFileSize(event.target.files[0]))
      {
        this.fichierAutorisationCumulActiviteExt = event.target.files[0];
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = () => {
          this.etape4.nomFichierAutorisationCumulActiviteExt = event.target.files[0].name;
        };
      }
      else
      {
        this.reset(this.childFichierAutorisationCumulActiviteExt);
      }
    }
    else{
      this.fichierAutorisationCumulActiviteExt = null;
      this.etape4.nomFichierAutorisationCumulActiviteExt = "";
    }
  }
  */

 

  
  public onFileChangeDocMaster(event){
    let reader = new FileReader();
    if(event.target.files && event.target.files.length > 0) {
      if(this.controleExtension(event.target.files[0]) && this.controleFileSize(event.target.files[0]))
      {
        this.fichierDiplomeMaster1Maitrise = event.target.files[0];
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = () => {
          this.etape4.nomFichierDiplomeMaster1Maitrise = event.target.files[0].name;
        };
      }
      else
      {
        this.reset(this.childFichierDiplomeMaster1Maitrise);
      }
    }
    else{
      this.fichierDiplomeMaster1Maitrise = null;
      this.etape4.nomFichierDiplomeMaster1Maitrise = "";
    }
  }
  public onFileChangeDocMaster2(event){
    let reader = new FileReader();
    if(event.target.files && event.target.files.length > 0) {
      if(this.controleExtension(event.target.files[0]) && this.controleFileSize(event.target.files[0]))
      {
        this.fichierDiplomeMaster2Maitrise = event.target.files[0];
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = () => {
          this.etape4.nomFichierDiplomeMaster2Maitrise = event.target.files[0].name;
        };
      }
      else
      {
        this.reset(this.childFichierDiplomeMaster2Maitrise);
      }
    }
    else{
      this.fichierDiplomeMaster2Maitrise = null;
      this.etape4.nomFichierDiplomeMaster2Maitrise = "";
    }
  }

  public onFileChangeDocThese(event){
    let reader = new FileReader();
    if(event.target.files && event.target.files.length > 0) {
      if(this.controleExtension(event.target.files[0]) && this.controleFileSize(event.target.files[0]))
      {
        this.fichierThese = event.target.files[0];
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = () => {
          this.etape4.nomFichierThese = event.target.files[0].name;
        };
      }
      else
      {
        this.reset(this.childFichierThese);
      }
    }
    else{
      this.fichierThese = null;
      this.etape4.nomFichierThese = "";
    }
  }

  public onFileChangeDocDiplome(event){
    let reader = new FileReader();
    if(event.target.files && event.target.files.length > 0) {
      if(this.controleExtension(event.target.files[0]) && this.controleFileSize(event.target.files[0]))
      {
        this.fichierDiplomeDocteurDroit = event.target.files[0];
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = () => {
          this.etape4.nomFichierDiplomeDocteurDroit = event.target.files[0].name;
          this.applyMandatory();
        };
      }
      else
      {
        this.reset(this.childFichierDiplomeDocteurDroit);
        this.applyMandatory();
      }
    }
    else{
      this.fichierDiplomeDocteurDroit = null;
      this.etape4.nomFichierDiplomeDocteurDroit = "";
      this.applyMandatory();

    }
    
  }
  public onFileChangeDocAttestation(event){
    let reader = new FileReader();
    if(event.target.files && event.target.files.length > 0) {
      if(this.controleExtension(event.target.files[0]) && this.controleFileSize(event.target.files[0]))
      {
        this.fichierAttestationSoutenanceEcoleDoctorat = event.target.files[0];
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = () => {
          this.etape4.nomFichierAttestationSoutenanceEcoleDoctorat = event.target.files[0].name;
          this.applyMandatory();
        };
      }
      else
      {
        this.reset(this.childFichierAttestationSoutenanceEcoleDoctorat);
        this.applyMandatory();
      }
    }
    else{
      this.fichierAttestationSoutenanceEcoleDoctorat = null;
      this.etape4.nomFichierAttestationSoutenanceEcoleDoctorat = "";
      this.applyMandatory();
    }
    
   
  }

  public onFileChangeDocNationalite(event){
    let reader = new FileReader();
    if(event.target.files && event.target.files.length > 0) {
      if(this.controleExtension(event.target.files[0]) && this.controleFileSize(event.target.files[0]))
      {
        this.fichierNationalite = event.target.files[0];
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = () => {
          this.etape4.nomFichierNationalite = event.target.files[0].name;
        };
      }
      else
      {
        this.reset(this.childFichierNationalité);
      }
    }
    else{
      this.fichierNationalite = null;
      this.etape4.nomFichierNationalite = "";
    }
  }

}
